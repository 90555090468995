import React, { useState, useEffect } from "react";
import Filters from "./filter";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import UserCreationForm from "./usercreationform";
import "./usercreation.css";
import UserEditForm from "./usereditform";
import AddNewImg from "./add.svg";
import ViewCard from "./viewcard";
import { deleteData, getData } from "../../../services/data";
import { setUserAdmin } from "../../../store/slices/user-admin";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import OrderDetails from "../order/OrderDetails";

const Usercreation = () => {
  const users = useSelector((state) => state.userAdmin.data);

  const [showOptions, setShowOptions] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [confirmationStatus, setConfirmationStatus] = useState(
    new Array(users.length).fill(false)
  );

  const [orderDetail, setorderDetail] = useState(null);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [editedUserData, setEditedUserData] = useState(null);

  const [isCardAndTableVisible, setIsCardAndTableVisible] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const [isCreationFormVisible, setIsCreationFormVisible] = useState(false);

  const [dateFilter, setDateFilter] = useState();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [maxPage, setMaxPage] = useState(1);

  const location = useLocation();

  const toggleOptions = (index) => {
    setShowOptions(!showOptions);
    setSelectedRowIndex(index);
  };

  const getAdminUsers = () => {
    let route = "users/get_admin_users";
    if (search && search != "") route = route + `?search=${search}`;
    getData(route, setUserAdmin, { page, pageSize }).then((res) =>
      setMaxPage(res.data.totalPages)
    );
    if (location.state?.id) {
      openCardAndTable(location.state);
    }
  };

  useEffect(() => {
    getAdminUsers();
  }, [page, search]);

  const openUserCreationTab = () => {
    setIsCreationFormVisible(true);
  };

  const closeUserCreationForm = () => {
    setIsCreationFormVisible(false);
  };

  const openEditForm = (userData) => {
    setIsEditFormVisible(true);
    setEditedUserData(userData);
  };

  const closeEditForm = () => {
    setIsEditFormVisible(false);
    setEditedUserData(null);
    setShowOptions(false);
    getAdminUsers();
  };

  // const handleAddUser = (formData) => {
  //   setData((prevData) => [...prevData, formData]);
  // };

  const orderDetailHandler = (order) => {
    const obj = {
      ...order,
      cash_vendor: `${selectedUserData?.first_name ?? ""} ${
        selectedUserData?.last_name ?? ""
      }`,
      customerName: `${order?.creator?.first_name ?? ""} ${
        order?.creator?.last_name ?? ""
      }`,
      customerPhone: order?.creator ? `+${order?.creator?.phone}` : "",
      eventName: order?.Event?.name,
    };
    setorderDetail(obj);
  };

  const handleCancel = () => {
    window.close();
  };

  const confirmDelete = (index) => {
    setConfirmationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };

  const cancelDelete = (index) => {
    setConfirmationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = false;
      return newStatus;
    });
  };

  const handleDeleteUser = (userid, index) => {
    setConfirmationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = false;
      return newStatus;
    });
    deleteData(`users/${userid}`).then((res) => {
      if (res.status === 200) {
        setSelectedRowIndex(null);
        setShowOptions(false);
        getAdminUsers();
      }
    });
  };

  const openCardAndTable = (userData) => {
    setSelectedUserData(userData);
    setIsCardAndTableVisible(true);
  };

  const closeCardAndTable = () => {
    setSelectedUserData(null);
    setIsCardAndTableVisible(false);
  };
  return (
    <div>
      <Sidebar />
      <div className="">
        <Header saerch={search} setSearch={setSearch} />
        <div className="page__container_wrapper userCreations__page">
          {!isCardAndTableVisible &&
            !isEditFormVisible &&
            !orderDetail &&
            !isCreationFormVisible && (
              <div className="userCreations__page_header">
                <h2>
                  User List{" "}
                  <span
                    className="addnew__newsUser_btn"
                    onClick={openUserCreationTab}
                  >
                    <img src={AddNewImg} alt="-" /> Add New
                  </span>
                </h2>
                <Filters
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                />
              </div>
            )}

          <div className="userCreation__main_table_container">
            {orderDetail ? (
              <OrderDetails
                order={orderDetail}
                onClose={() => setorderDetail(null)}
              />
            ) : isCardAndTableVisible ? (
              <div className="userCreation__main_edit_block">
                <div
                  onClick={closeCardAndTable}
                  className="userCreation__back_button"
                >
                  <i className="fa fa-long-arrow-left"></i>
                  User Details
                </div>
                <ViewCard
                  userData={selectedUserData}
                  orderDetailHandler={orderDetailHandler}
                />
              </div>
            ) : isEditFormVisible ? (
              <UserEditForm userData={editedUserData} onClose={closeEditForm} />
            ) : isCreationFormVisible ? (
              <UserCreationForm
                // onAddUser={handleAddUser}
                onCancel={closeUserCreationForm}
              />
            ) : (
              <>
                <table className="userCreation__main_table_box">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Location</th>
                      <th>Status</th>
                      {/* <th>Events</th> */}
                      <th>Created At</th>
                      <th>Created By</th>
                      <th>Deleted At</th>
                      <th>Deleted By</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.users
                      ?.filter(
                        (u) =>
                          !dateFilter ||
                          new Date(u.created_at).setHours(0, 0, 0, 0) ===
                            new Date(dateFilter).setHours(0, 0, 0, 0)
                      )
                      .map((item, index) => (
                        <React.Fragment key={index}>
                          {confirmationStatus[index] ? (
                            <tr>
                              <td colSpan="9" className="confirmation-message">
                                <div className="delete-container">
                                  Are you sure to delete?
                                  <button
                                    onClick={() =>
                                      handleDeleteUser(item.id, index)
                                    }
                                  >
                                    Yes
                                  </button>
                                  <button onClick={() => cancelDelete(index)}>
                                    No
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>
                                {(page == 1 ? 0 : (page - 1) * 10) +
                                  (index + 1)}
                              </td>
                              <td>{item.first_name + " " + item.last_name}</td>
                              <td>{item.phone}</td>
                              <td>{item.email}</td>
                              <td>{item.address}</td>
                              <td>{item.active ? "Active" : "Inactive"}</td>
                              {/* <td>{item.events}</td> */}
                              <td>
                                {new Date(item.created_at).toLocaleDateString(
                                  "en-US"
                                )}
                              </td>
                              <td>
                                {item?.createdByUser?.first_name}{" "}
                                {item?.createdByUser?.last_name}
                              </td>
                              <td>
                                {item?.deleted_at
                                  ? new Date(
                                      item?.deleted_at
                                    ).toLocaleDateString("en-US")
                                  : ""}
                              </td>
                              <td>
                                {item?.deletedByUser?.first_name}{" "}
                                {item?.deletedByUser?.last_name}
                              </td>
                              <td>
                                <div className="userCreation__main_table_options">
                                  <div
                                    className="userCreation__main_table_options__button"
                                    onClick={() => toggleOptions(index)}
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </div>
                                  {showOptions &&
                                    selectedRowIndex === index && (
                                      <div className="userCreation__main_table_menu_dropdown">
                                        <ul className="userCreation__main_table_menu_options">
                                          <li
                                            onClick={() =>
                                              openCardAndTable(item)
                                            }
                                          >
                                            View
                                          </li>
                                          <li
                                            onClick={() => openEditForm(item)}
                                          >
                                            Edit
                                          </li>
                                          {item.active && (
                                            <li
                                              onClick={() =>
                                                confirmDelete(index)
                                              }
                                            >
                                              Delete
                                            </li>
                                          )}
                                          <li
                                            onClick={() =>
                                              openCardAndTable(item)
                                            }
                                          >
                                            Transaction
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
                <ReactPaginate
                  breakLabel="..."
                  className="paginationBot"
                  nextLabel=">"
                  onPageChange={(e) => setPage(e.selected + 1)}
                  pageRangeDisplayed={15}
                  pageCount={maxPage}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usercreation;
