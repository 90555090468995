import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { getData, postData, putData } from "../../../services/data";
import { setSpeakers } from "../../../store/slices/speaker";
import { setSeating } from "../../../store/slices/seating";
import { setDiscounts } from "../../../store/slices/discount";
import { setEvents } from "../../../store/slices/event";
import { showError, showSuccess } from "../../../helpers/toast";
import { appendImagesToFormData, jsonToFormData } from "../../../helpers/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";

const EventEditScreen = ({ event, onClose }) => {
  const [eventStyle, setEventStyle] = useState(
    event?.is_junkano ? "junkano" : event.style || "no seating"
  ); // Example default value
  const [isJunkanoEvent, setJunkanoEvent] = useState(
    event?.is_junkano ?? false
  );
  const [seatingOptions, setSeatingOptions] = useState([]);
  const [canvase, setCanvase] = useState(event.canvase || "multiple"); // Example default value
  const [eventName, setEventName] = useState(event.name || "");
  const [isActive, setisActive] = useState(event.is_active || false);
  const [eventType, setEventType] = useState(event.type || "");
  const [ticketStyle, setTicketStyle] = useState(event.ticket_style || "PDF"); // Example default value
  const [dateFrom, setDateFrom] = useState(
    event.date_from
      ? format(new Date(event.date_from), "yyyy-MM-dd HH:mm:ss")
      : ""
  );
  const [dateTo, setDateTo] = useState(
    event.date_to ? format(new Date(event.date_to), "yyyy-MM-dd HH:mm:ss") : ""
  );
  const [checkInsStart, setCheckInsStart] = useState(
    event.checkin_from
      ? format(new Date(event.checkin_from), "yyyy-MM-dd HH:mm:ss")
      : ""
  ); // Example default value
  const [checkInsEnd, setCheckInsEnd] = useState(
    event.checkin_to
      ? format(new Date(event.checkin_to), "yyyy-MM-dd HH:mm:ss")
      : ""
  ); // Example default value
  const [vat, setVat] = useState(event.vat || "");
  const [currency, setCurrency] = useState(event.currency || ""); // Example default value
  const [discountStartDate, setDiscountStartDate] = useState(
    event.discount_from
      ? format(new Date(event.discount_from), "yyyy-MM-dd")
      : ""
  );
  const [discountEndDate, setDiscountEndDate] = useState(
    event.discount_to ? format(new Date(event.discount_to), "yyyy-MM-dd") : ""
  );
  const [showAdditionalFields, setShowAdditionalFields] = useState(
    !event.is_public ?? false
  ); // Checkbox state
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);
  const [minPrice, setMinPrice] = useState(event.min_price || "");
  const [maxPrice, setMaxPrice] = useState(event.max_price || "");

  // Dependent fields
  const [minTicketOrder, setMinTicketOrder] = useState(event.min_ticket || ""); // Example default value
  const [maxTicketOrder, setMaxTicketOrder] = useState(event.max_ticket || ""); // Example default value
  const [maxCheckIns, setMaxCheckIns] = useState(event.max_checkin || ""); // Example default value
  const [ticketAvailableFrom, setTicketAvailableFrom] = useState(
    event.ticket_available_from
      ? format(new Date(event.ticket_available_from), "yyyy-MM-dd")
      : ""
  );
  const [ticketAvailableTo, setTicketAvailableTo] = useState(
    event.ticket_available_to
      ? format(new Date(event.ticket_available_to), "yyyy-MM-dd")
      : ""
  );
  const [maxTimeTransfer, setMaxTimeTransfer] = useState(
    event.max_time_transfer || ""
  ); // Example default value
  const [selectedSpeakers, setSelectedSpeakers] = useState(
    event.speakers ?? []
  );
  const [selectedSeating, setSelectedSeating] = useState();
  const [aditionalTermsCondContent, setAditionalTermsCondContent] = useState(
    event.add_terms ?? ""
  );
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [venue, setVenue] = useState(event.venue || "");
  const [descriptionContent, setDescriptionContent] = useState(
    event.description ?? ""
  );
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedSponserImages, setUploadedSponserImages] = useState([]);
  const [oldSponsorImages, setOldSponsorImages] = useState(
    event.sponsor_images?.map((image) => image.file_name) ?? []
  );

  const [uploadedImage, setUploadedImage] = useState();
  const [oldUploadedImage, setOldUploadedImage] = useState(event.event_logo);

  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState(
    event.event_images?.map((image) => image.file_name) ?? []
  );
  const [isDisable, setIsDisable] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getData("speakers", setSpeakers);
    getData("seating_template", setSeating);
    getData("discount_code", setDiscounts);
    if (event.id) {
      getData("event_seating_map/byEvent/" + event.id).then((res) => {
        setSelectedSeating(res.data);
      });
    }
  }, []);

  const handleChangeDiscount = (selectedOptions) => {
    setSelectedDiscounts(selectedOptions);
  };

  const handleChangeSpeaker = (selectedSpeaker) => {
    setSelectedSpeakers(selectedSpeaker);
  };

  const handleChangeSeating = (selectedSeating) => {
    // setEventStyle(selectedSeating?.event_style);
    setCanvase(selectedSeating?.canvase);
    setSelectedSeating(selectedSeating);
  };

  const speakerOptions = useSelector((state) => state.speaker.data);
  const seatingTemplates = useSelector((state) => state.seating.data);
  const discountOptions = useSelector((state) => state.discount.data);

  const handlePolicyChange = (content) => {
    setDescriptionContent(content);
  };

  const handleAdditionalPolicyChange = (content) => {
    setAditionalTermsCondContent(content);
  };

  const onponserDrop = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const updatedImages = [...uploadedSponserImages];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        updatedImages.push(e.target.result);
        setUploadedSponserImages(updatedImages);
      };
      reader.readAsDataURL(file);
    });
  };

  const onDrop = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const image = e.target.result;
      setUploadedImage(image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (indexToRemove) => {
    const updatedImages = uploadedImages.filter(
      (_, index) => index !== indexToRemove
    );
    setUploadedImages(updatedImages);
  };

  const handleImageUpload = (event) => {
    // const files = Array.from(event.target.files); // Convert FileList to an array
    const updatedImages = [...images, ...event.target.files];

    // event.target.files.forEach((file) => {
    // updatedImages.push(file);
    setImages(updatedImages);
    // const reader = new FileReader();
    // reader.onload = (e) => {
    //   updatedImages.push(e.target.result);
    //   setImages(updatedImages);
    // };
    // reader.readAsDataURL(file);
    // });
  };

  const handleImageRemove = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    setImages(updatedImages);
  };

  const handleOldImagesRemove = () => {
    setOldImages(null);
  };

  const removeSponserImage = (indexToRemove) => {
    const updatedSponserImages = uploadedSponserImages.filter(
      (_, index) => index !== indexToRemove
    );
    setUploadedSponserImages(updatedSponserImages);
  };

  const handleOldSponsorImagesRemove = () => {
    setOldSponsorImages(null);
  };

  const onPublish = async () => {
    setIsDisable(true);
    if (!selectedSeating) {
      setIsDisable(false);
      return showError("Seating not selected");
    }
    const payload = {
      ...event,
      style: eventStyle === "junkano" ? "seating" : eventStyle,
      is_junkano: isJunkanoEvent,
      canvase: canvase,
      name: eventName,
      type: eventType,
      description: descriptionContent,
      add_terms: aditionalTermsCondContent,
      speakers: selectedSpeakers.map((s) => s.id).join(","),
      discounts: selectedDiscounts.map((d) => d.code).join(","),
      ticket_style: ticketStyle,
      date_from: dateFrom,
      date_to: dateTo,
      checkin_from: checkInsStart,
      checkin_to: checkInsEnd,
      vat: vat,
      currency: selectedCurrencies,
      discount_from: discountStartDate,
      discount_to: discountEndDate,
      min_ticket: minTicketOrder,
      max_ticket: maxTicketOrder,
      max_checkin: maxCheckIns,
      ticket_available_from: ticketAvailableFrom,
      ticket_available_to: ticketAvailableTo,
      max_time_transfer: maxTimeTransfer,
      event_logo: uploadedImage,
      venue: venue,
      is_active: isActive,
      is_public: !showAdditionalFields,
      min_price: minPrice,
      max_price: maxPrice,
      template_id: selectedSeating.id,
    };
    let formData = jsonToFormData(payload);
    formData = appendImagesToFormData(formData, "event_images", images);
    formData = appendImagesToFormData(
      formData,
      "sponsor_images",
      uploadedSponserImages
    );
    if (event?.id) {
      await putData("events/" + event?.id, undefined, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          showSuccess(res.data?.message);
          navigate("/event");
          // putData("event_seating_map", undefined, {
          //   event_id: res.data.event.id,
          //   ...selectedSeating,
          // }).then(() => {
          //   getData("events", setEvents);
          //   onClose();
          // });
        })
        .catch((err) => {
          showError(err.response?.data?.error ?? err.message);
          setIsDisable(false);
        });
    } else {
      await postData("events", undefined, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          showSuccess(res.data?.message);
          // postData("event_seating_map", undefined, {
          //   event_id: res.data.event.id,
          //   ...selectedSeating,
          // })
          // .then(() => {
          getData("events/admin", setEvents);
          navigate("/event");
          // });
        })
        .catch((err) => {
          showError(err.response?.data?.error ?? err.message);
          setIsDisable(false);
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedImage(acceptedFiles[0]);
    },
  });

  const imageDropzone = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setImages([...images, ...acceptedFiles]);
    },
  });
  const sponsorLogoDropzone = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUploadedSponserImages([...uploadedSponserImages, ...acceptedFiles]);
    },
  });

  useEffect(() => {
    const event = eventStyle === "junkano" ? "seating" : eventStyle;
    setSelectedSeating(null);
    setSeatingOptions(seatingTemplates.filter((s) => s.event_style === event));
  }, [seatingTemplates, eventStyle]);
  const eventStyleHandler = (e) => {
    const val = e.target.value;
    setJunkanoEvent(val === "junkano");
    setEventStyle(val);
    setSelectedSeating();
  };
  return (
    <div className="event-edit-screen">
      <div className="eventPage__header">
        <h2>Events Creation</h2>
        {/* Seating dropdown */}

        {
          <label>
            <span>Select Seating:</span>
            <Select
              // options={seatingOptions?.filter(
              //   (s) =>
              //     s.event_style === eventStyle &&
              //     (!s.canvase || s.canvase === canvase)
              // )}
              options={seatingOptions}
              isDisabled={!!event.id}
              isClearable={true}
              getOptionLabel={(s) => s.name}
              getOptionValue={(s) => s.name}
              value={selectedSeating}
              onChange={handleChangeSeating}
            />
          </label>
        }
      </div>

      <div className="event__creation_main_container">
        <div className="event__creation_left_col">
          <div className="event__creation_field_wrap event__style_field">
            {/* Event Style Dropdown */}
            <label>
              <span>Event Style:</span>
              <select
                value={eventStyle}
                onChange={eventStyleHandler}
                className="form-select"
              >
                <option value="no seating">No Seating </option>
                <option value="seating">Seating</option>
                <option value="junkano">Junkano</option>
                {/* Add more options as needed */}
              </select>
            </label>
          </div>

          {/* Canvase Dropdown 
          <div className="event__creation_field_wrap canvas__field">
            <label>
              <span>Canvase:</span>
              <select
                value={canvase}
                onChange={(e) => {
                  setCanvase(e.target.value);
                  setSelectedSeating();
                }}
                className="form-select"
              >
                <option value="multiple">Multiple</option>
                <option value="single">Single</option>
              </select>
            </label>
          </div>*/}

          {/* Event Name Text Field */}
          <div className="event__creation_field_wrap event__name_field">
            <label>
              <span>Event Name:</span>
              <input
                type="text"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </label>
          </div>

          {/* Ticket Style Dropdown */}
          <div className="event__creation_field_wrap ticket__style_field">
            <label>
              <span>Ticket Style:</span>
              <select
                value={ticketStyle}
                onChange={(e) => setTicketStyle(e.target.value)}
                className="form-select"
              >
                <option value="PDF">PDF</option>
                <option value="digital">Digital</option>
                {/* Add more options as needed */}
              </select>
            </label>
          </div>

          {/* Currency Multi-select */}
          <div className="event__creation_field_wrap currency__field">
            <label>
              <span>Currency:</span>
              <select
                value={selectedCurrencies}
                onChange={(e) => setSelectedCurrencies(e.target.value)}
                className="form-select"
              >
                <option value="$">USD</option>
                <option value="B$">BSD</option>
                {/* <option value="£">GBP</option>
                <option value="€">EUR</option> */}
                {/* Add more currency options as needed */}
              </select>
            </label>
          </div>

          {/* VAT Text Field */}
          <div className="event__creation_field_wrap vat_field">
            <label>
              <span>VAT (%) :</span>
              <input
                type="number"
                min="0"
                value={vat}
                onChange={(e) => setVat(e.target.value)}
              />
            </label>
          </div>

          {/* Event Type Text Field */}
          <div className="event__creation_field_wrap event__type_field">
            <label>
              <span>Event Type:</span>
              <input
                type="text"
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
              />
            </label>
          </div>
          {/* Check-ins Start Dropdown */}
          <div className="event__creation_field_wrap checkins__start_field">
            <label>
              <span>Check-ins Start:</span>
              <input
                type="datetime-local"
                onChange={(e) => setCheckInsStart(e.target.value)}
                value={checkInsStart}
              />
            </label>
          </div>

          {/* Check-ins End Dropdown */}
          <div className="event__creation_field_wrap checkins__end_field">
            <label>
              <span>Check-ins End:</span>
              <input
                type="datetime-local"
                onChange={(e) => setCheckInsEnd(e.target.value)}
                value={checkInsEnd}
              />
            </label>
          </div>

          {/* Date From Date Picker */}
          <div className="event__creation_field_wrap date__from_field">
            <label>
              <span>Date From:</span>
              <input
                type="datetime-local"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </label>
          </div>

          {/* Date To Date Picker */}
          <div className="event__creation_field_wrap date__to_field">
            <label>
              <span>Date To:</span>
              <input
                type="datetime-local"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </label>
          </div>

          {/* Select Discount Multi-select Dropdown 
          <div className="event__creation_field_wrap select_discount_field">
            <label>
              <span>Select Discount:</span>
              <Select
                isMulti
                options={discountOptions}
                value={selectedDiscounts}
                getOptionLabel={(s) => s.code}
                getOptionValue={(s) => s.code}
                onChange={handleChangeDiscount}
                className="select_discount_field_drop form-select"
              />
            </label>
          </div>
*/}
          {/* Discount Start Date Datepicker 
          <div className="event__creation_field_wrap dis_start_date_field">
            <label>
              <span>Discount Start Date:</span>
              <input
                type="date"
                value={discountStartDate}
                onChange={(e) => setDiscountStartDate(e.target.value)}
              />
            </label>
          </div>
*/}
          {/* Discount End Date Datepicker 
          <div className="event__creation_field_wrap dis_end_date_field">
            <label>
              <span>Discount End Date:</span>
              <input
                type="date"
                value={discountEndDate}
                onChange={(e) => setDiscountEndDate(e.target.value)}
              />
            </label>
          </div>
*/}

          {/* Additional Fields (Dependent on Checkbox) */}

          {/* Min Ticket/Order Dropdown */}
          <div
            className={
              showAdditionalFields
                ? "event__creation_field_wrap in_ticket_field hidden_field"
                : "event__creation_field_wrap in_ticket_field "
            }
          >
            <label>
              <span>Min Ticket/Order:</span>
              <input
                type="text"
                value={minTicketOrder}
                onChange={(e) => setMinTicketOrder(e.target.value)}
              />
            </label>
          </div>

          {/* Max Ticket/Order Dropdown */}
          <div
            className={
              showAdditionalFields
                ? "event__creation_field_wrap max_ticket_field hidden_field"
                : "event__creation_field_wrap max_ticket_field "
            }
          >
            <label>
              <span>Max Ticket/Order:</span>
              <input
                type="text"
                value={maxTicketOrder}
                onChange={(e) => setMaxTicketOrder(e.target.value)}
              />
            </label>
          </div>

          {/* Max No. of Checkins Dropdown */}
          <div
            className={
              showAdditionalFields
                ? "event__creation_field_wrap max_checkins_field hidden_field"
                : "event__creation_field_wrap max_checkins_field "
            }
          >
            <label>
              <span>Max No. Check-ins:</span>
              <input
                type="text"
                value={maxCheckIns}
                onChange={(e) => setMaxCheckIns(e.target.value)}
              />
            </label>
          </div>

          {/* Ticket Available From Datepicker */}
          <div
            className={
              showAdditionalFields
                ? "event__creation_field_wrap ticket_avail_from_field hidden_field"
                : "event__creation_field_wrap ticket_avail_from_field "
            }
          >
            <label>
              <span>Ticket Available From:</span>
              <input
                type="date"
                value={ticketAvailableFrom}
                onChange={(e) => setTicketAvailableFrom(e.target.value)}
              />
            </label>
          </div>

          {/* Ticket Available To Datepicker */}
          <div
            className={
              showAdditionalFields
                ? "event__creation_field_wrap ticket_avail_to_field hidden_field"
                : "event__creation_field_wrap ticket_avail_to_field "
            }
          >
            <label>
              <span>Ticket Available To:</span>
              <input
                type="date"
                value={ticketAvailableTo}
                onChange={(e) => setTicketAvailableTo(e.target.value)}
              />
            </label>
          </div>

          {/* Available Time Dropdown
    <div className={showAdditionalFields ? ('event__creation_field_wrap available_time_field hidden_field') : ('event__creation_field_wrap available_time_field ')}>
        <label>
      <span>Available Time:</span>
      <select value={availableTime} onChange={(e) => setAvailableTime(e.target.value)} className='form-select'>
        <option value="AvailableTime1">Available Time 1</option>
        <option value="AvailableTime2">Available Time 2</option>
      </select>
    </label></div>*/}

          {/* Max Time Transfer Dropdown */}
          <div
            className={
              showAdditionalFields
                ? "event__creation_field_wrap max_time_trnsfer_field hidden_field"
                : "event__creation_field_wrap max_time_trnsfer_field "
            }
          >
            <label>
              <span>Max No. Transfer:</span>
              <input
                type="text"
                value={maxTimeTransfer}
                onChange={(e) => setMaxTimeTransfer(e.target.value)}
              />
            </label>
          </div>

          {/* Speaker Multi-select Dropdown */}
          <div className="event__creation_field_wrap select_speaker_field">
            <label>
              <span>Select Speaker:</span>
              <Select
                isMulti
                options={speakerOptions}
                getOptionLabel={(s) => s.name}
                getOptionValue={(s) => s.id}
                value={selectedSpeakers}
                onChange={handleChangeSpeaker}
                className="select_speaker_field_drop form-select"
              />
            </label>
          </div>
          {/* Checkbox to Show Additional Fields */}
          <div className="event__creation_field_wrap show_add__field">
            <label>
              <input
                type="checkbox"
                checked={showAdditionalFields}
                onChange={() => setShowAdditionalFields(!showAdditionalFields)}
              />
              Close for Public
            </label>
          </div>
          {/* Checkbox to Show Active Fields */}
          <div className="event__creation_field_wrap show_add__field">
            <label>
              <input
                type="checkbox"
                checked={isActive}
                onChange={() => setisActive(!isActive)}
              />
              Active
            </label>
          </div>
        </div>
        <div className="event__creation_right_right">
          <div className="event__creation__eventphoto_upload">
            <label>Upload Event Map</label>
            <div className="dropzone" {...getRootProps()}>
              {uploadedImage ? (
                <div className="uploaded-image">
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt="Uploaded"
                  />
                  <button onClick={() => setUploadedImage(null)}>+</button>
                </div>
              ) : oldUploadedImage ? (
                <div className="uploaded-image">
                  <img
                    src={process.env.REACT_APP_API_URL + "/" + oldUploadedImage}
                    alt="Uploaded"
                  />
                  <button onClick={() => setOldUploadedImage(null)}>+</button>
                </div>
              ) : (
                <div className="event__creation__eventphoto_upload_file">
                  <input {...getInputProps()} />
                </div>
              )}
            </div>
          </div>

          <div className="image-upload-container">
            <label>Upload Event Pictures</label>
            <div
              className="image-upload-dropzone"
              {...imageDropzone.getRootProps()}
            >
              <div className="image-preview">
                {oldImages?.length > 0
                  ? oldImages.map((image, index) => (
                      <div key={index} className="uploaded-image">
                        <img
                          src={process.env.REACT_APP_API_URL + "/" + image}
                          alt={`Uploaded ${index}`}
                        />
                        <button onClick={handleOldImagesRemove}>+</button>
                      </div>
                    ))
                  : images.map((image, index) => (
                      <div key={index} className="uploaded-image">
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Uploaded ${index}`}
                        />
                        <button onClick={() => handleImageRemove(index)}>
                          +
                        </button>
                      </div>
                    ))}
              </div>
              <div className="image-upload-container-file">
                <input {...imageDropzone.getInputProps()} multiple />
              </div>
            </div>
          </div>

          <div className="event__creation__sponserlogo_wrap">
            <div className="event__creation__sponserlogoupload">
              <label>Upload Sponsor logos</label>
              <div className="dropzone" {...sponsorLogoDropzone.getRootProps()}>
                <div className="image-preview">
                  {oldSponsorImages?.length > 0
                    ? oldSponsorImages.map((image, index) => (
                        <div key={index} className="uploaded-image">
                          <img
                            src={process.env.REACT_APP_API_URL + "/" + image}
                            alt={`Uploaded ${index}`}
                          />
                          <button onClick={handleOldSponsorImagesRemove}>
                            +
                          </button>
                        </div>
                      ))
                    : uploadedSponserImages.map((image, index) => (
                        <div key={index} className="uploaded-image">
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Uploaded ${index}`}
                          />
                          <button onClick={() => removeSponserImage(index)}>
                            +
                          </button>
                        </div>
                      ))}
                </div>
                <div className="event__creation__sponserlogoupload_file">
                  <input {...sponsorLogoDropzone.getInputProps()} multiple />
                </div>
              </div>
            </div>
          </div>

          {/* Venue Text Field */}
          <div className="event__creation_field_wrap vanue__field">
            <label>
              <span>Venue:</span>
              <input
                type="text"
                value={venue}
                onChange={(e) => setVenue(e.target.value)}
              />
            </label>
          </div>

          <div className="event__creation_field_wrap description__field">
            <span>Description:</span>
            <ReactQuill
              value={descriptionContent}
              onChange={handlePolicyChange}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
            />
          </div>
          <div className="event__creation_field_wrap description__field">
            <span>Additional Terms and Condition:</span>
            <ReactQuill
              value={aditionalTermsCondContent}
              onChange={handleAdditionalPolicyChange}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
            />
          </div>
        </div>
      </div>

      <div className="footer__event_creation_form">
        {/* <div className="event_btn_left">
          <button className="save__bt_draft" onClick={onClose}>
            Save In Draft
          </button>
        </div> */}
        <div className="event_btn_right">
          <button
            className="save__bt_cancle"
            onClick={() => navigate("/event")}
          >
            Cancel
          </button>
          <button
            className={`save__bt_publish ${isDisable ? "disabled" : ""}`}
            onClick={isDisable ? null : onPublish}
            disabled={isDisable}
          >
            {isDisable ? "Publishing..." : "Publish Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventEditScreen;
