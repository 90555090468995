import React, { useEffect, useState } from "react";
import Sidebar from "../leftsidebar/sidebar";
import Header from "../header/headerInner";
import { useSelector } from "react-redux";
import { getData, postData } from "../../../services/data";
import { setNotifications } from "../../../store/slices/marketing";
import { showError, showSuccess } from "../../../helpers/toast";

const Marketing = () => {
  const notification = useSelector((state) => state.marketing.data);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedFaqIndex, setSelectedFaqIndex] = useState(-1);

  useEffect(() => {
    getData("notifications", setNotifications);
  }, []);

  const toggleDropdown = (index) => {
    setSelectedFaqIndex(index === selectedFaqIndex ? -1 : index);
  };

  const handleSubmit = () => {
    if (!subject || !message) {
      showError("Please fill all fields");
      return;
    }
    if (isLoading) return;
    setLoading(true);
    postData("notification/marketing", undefined, {
      subject,
      message,
    }).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setSubject("");
        setMessage("");
        showSuccess("Notification sent successfully");
      }
      setLoading(false);
      //   getData("notification", setNotifications);
    });
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header />
        <div className="page__container_wrapper faqPage">
          <div className="faq__page_main_wrap">
            <div className="faq__page_main_header">
              <h2>Send Marketing Notifications</h2>
            </div>

            <div className="faq__page_body">
              <div className="faq__page_left_col">
                <div className="qs-container market">
                  <input
                    type="text"
                    placeholder="Notification title"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{ paddingLeft: 0 }}
                  />
                </div>
                <div className="qs-container market" style={{ marginTop: 30 }}>
                  <textarea
                    placeholder="Notification Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>

                <div>
                  <button
                    className="add-to-list-button"
                    onClick={handleSubmit}
                    style={{
                      cursor: isLoading ? "default" : "pointer",
                      opacity: isLoading ? 0.5 : 1,
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>

              <div className="faq__page_right_col">
                <div className="faq__page_faq_list">
                  <h4>Previous Notifications:</h4>
                  {notification?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => toggleDropdown(index)}
                      className="faq-item"
                    >
                      <div className="faq-text">
                        <div className="faq-header">{item.subject}</div>
                        {selectedFaqIndex === index ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: item.message }}
                            className="faq-item-ans"
                          ></div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="save-publish-button-container">
                <button className="save-publish-button">
                  Save and Publish
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
