import React, { useEffect, useState } from "react";
import Logo from "../../../assets/media/logo.svg";
import "./sidebar.css";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./images/chair.png";
import Chair from "./images/chair.png";
import Event from "./images/event.png";
import Tickets from "./images/ticket.png";
import Order from "./images/order.png";
import Member from "./images/member.png";
import Users from "./images/users.png";
import Speakers from "./images/mic.png";
import Reports from "./images/report.png";
import NewsUpdates from "./images/feedback.png";
import Discount from "./images/discount.png";
import Feedbacks from "./images/feedback.png";
import FAQs from "./images/faqs.png";
import Terms from "./images/terms.png";
import Privacy from "./images/privacy.png";
import Logout from "./images/privacy.png";
import { logout } from "../../../store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import RoleBasedComponent from "../../utils/rolebasedcomponent";
import { getData } from "../../../services/data";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userWithRoles, setUserWithRoles] = useState({});
  const userDetails = useSelector((state) => state.user.data);
  useEffect(() => {
    getData("users/get_user/" + userDetails?.id).then((res) => {
      setUserWithRoles(res.data);
    });
  }, [userDetails]);

  const handleLogout = () => {
    document.body.classList.remove("view-only");
    dispatch(logout());
  };
  return (
    <header className="leftSidebar__logo_navigation">
      <div className="header__logo_main">
        <LinkContainer to="/home">
          <Nav.Link>
            <img className="logo" src={Logo} alt="" />
          </Nav.Link>
        </LinkContainer>
      </div>
      <nav className="leftSidebarMenu__main_wrap">
        <div className="leftMenu__main_wrap">
          <RoleBasedComponent role={["admin"]} user={userWithRoles}>
            <LinkContainer to="/home">
              <Nav.Link className={location.pathname === "/home" && "selected"}>
                <img src={Dashboard} alt="" />
                <span>Dashboard</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/seating-template">
              <Nav.Link
                className={
                  location.pathname === "/seating-template" && "selected"
                }
              >
                <img src={Chair} alt="" />
                <span>Seating templates</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/event">
              <Nav.Link
                className={location.pathname === "/event" && "selected"}
              >
                <img src={Event} alt="" />
                <span>Events</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/ticket">
              <Nav.Link
                className={location.pathname === "/ticket" && "selected"}
              >
                <img src={Tickets} alt="" />
                <span>Tickets</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/order">
              <Nav.Link
                className={location.pathname === "/order" && "selected"}
              >
                <img src={Order} alt="" />
                <span>Orders</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/userinfo">
              <Nav.Link
                className={location.pathname === "/userinfo" && "selected"}
              >
                <img src={Users} alt="" />
                <span>Users</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/cash">
              <Nav.Link className={location.pathname === "/cash" && "selected"}>
                <img src={Chair} alt="" />
                <span>Cash vendor</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/reserved">
              <Nav.Link
                className={location.pathname === "/reserved" && "selected"}
              >
                <img src={Discount} alt="" />
                <span>Reserved</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/donation">
              <Nav.Link
                className={location.pathname === "/donation" ? "selected" : ""}
              >
                <img src={Discount} alt="" />
                <span>Donation</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/finance-track">
              <Nav.Link
                className={location.pathname === "/finance-track" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Finance Track</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/report">
              <Nav.Link
                className={location.pathname === "/report" && "selected"}
              >
                <img src={Reports} alt="" />
                <span>Reports</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/members">
              <Nav.Link
                className={location.pathname === "/members" && "selected"}
              >
                <img src={Member} alt="" />
                <span>Members</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/marketing">
              <Nav.Link
                className={location.pathname === "/marketing" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Marketing</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/newsupdate">
              <Nav.Link
                className={location.pathname === "/newsupdate" && "selected"}
              >
                <img src={NewsUpdates} alt="" />
                <span>News Updates</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/discountcode">
              <Nav.Link
                className={location.pathname === "/discountcode" && "selected"}
              >
                <img src={Discount} alt="" />
                <span>Discount Codes</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/bannerImage">
              <Nav.Link
                className={location.pathname === "/bannerImage" && "selected"}
              >
                <img src={Privacy} alt="" />
                <span>Homepage Banners</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/feedback">
              <Nav.Link
                className={location.pathname === "/feedback" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Feedbacks</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/adjustment">
              <Nav.Link
                className={location.pathname === "/adjustment" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Adjustment</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/speakers">
              <Nav.Link
                className={location.pathname === "/speakers" && "selected"}
              >
                <img src={Speakers} alt="" />
                <span>Speakers</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/faq">
              <Nav.Link className={location.pathname === "/faq" && "selected"}>
                <img src={FAQs} alt="" />
                <span>FAQs</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/termscondition">
              <Nav.Link
                className={
                  location.pathname === "/termscondition" && "selected"
                }
              >
                <img src={Terms} alt="" />
                <span>Terms & Conditions</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/policyeditor">
              <Nav.Link
                className={location.pathname === "/policyeditor" && "selected"}
              >
                <img src={Privacy} alt="" />
                <span>Privacy Policy</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["viewer"]} user={userWithRoles}>
            <LinkContainer to="/home">
              <Nav.Link className={location.pathname === "/home" && "selected"}>
                <img src={Dashboard} alt="" />
                <span>Dashboard</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/seating-template">
              <Nav.Link
                className={
                  location.pathname === "/seating-template" && "selected"
                }
              >
                <img src={Chair} alt="" />
                <span>Seating templates</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/event">
              <Nav.Link
                className={location.pathname === "/event" && "selected"}
              >
                <img src={Event} alt="" />
                <span>Events</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/ticket">
              <Nav.Link
                className={location.pathname === "/ticket" && "selected"}
              >
                <img src={Tickets} alt="" />
                <span>Tickets</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/order">
              <Nav.Link
                className={location.pathname === "/order" && "selected"}
              >
                <img src={Order} alt="" />
                <span>Orders</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/userinfo">
              <Nav.Link
                className={location.pathname === "/userinfo" && "selected"}
              >
                <img src={Users} alt="" />
                <span>Users</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/reserved">
              <Nav.Link
                className={location.pathname === "/reserved" && "selected"}
              >
                <img src={Discount} alt="" />
                <span>Reserved</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/finance-track">
              <Nav.Link
                className={location.pathname === "/finance-track" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Finance Track</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/members">
              <Nav.Link
                className={location.pathname === "/members" && "selected"}
              >
                <img src={Member} alt="" />
                <span>Members</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/report">
              <Nav.Link
                className={location.pathname === "/report" && "selected"}
              >
                <img src={Reports} alt="" />
                <span>Reports</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/newsupdate">
              <Nav.Link
                className={location.pathname === "/newsupdate" && "selected"}
              >
                <img src={NewsUpdates} alt="" />
                <span>News Updates</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/discountcode">
              <Nav.Link
                className={location.pathname === "/discountcode" && "selected"}
              >
                <img src={Discount} alt="" />
                <span>Discount Codes</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/feedback">
              <Nav.Link
                className={location.pathname === "/feedback" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Feedbacks</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/adjustment">
              <Nav.Link
                className={location.pathname === "/adjustment" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Adjustment</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/faq">
              <Nav.Link className={location.pathname === "/faq" && "selected"}>
                <img src={FAQs} alt="" />
                <span>FAQs</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/termscondition">
              <Nav.Link
                className={
                  location.pathname === "/termscondition" && "selected"
                }
              >
                <img src={Terms} alt="" />
                <span>Terms & Conditions</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/policyeditor">
              <Nav.Link
                className={location.pathname === "/policyeditor" && "selected"}
              >
                <img src={Privacy} alt="" />
                <span>Privacy Policy</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["cash_vendor"]} user={userWithRoles}>
            <LinkContainer to="/ticket">
              <Nav.Link
                className={location.pathname === "/ticket" && "selected"}
              >
                <img src={Tickets} alt="" />
                <span>Tickets</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/order">
              <Nav.Link
                className={location.pathname === "/order" && "selected"}
              >
                <img src={Order} alt="" />
                <span>Orders</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/userinfo">
              <Nav.Link
                className={location.pathname === "/userinfo" && "selected"}
              >
                <img src={Users} alt="" />
                <span>Users</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/cash">
              <Nav.Link className={location.pathname === "/cash" && "selected"}>
                <img src={Chair} alt="" />
                <span>Cash vendor</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["reserve_tickets"]} user={userWithRoles}>
            <LinkContainer to="/cash">
              <Nav.Link className={location.pathname === "/cash" && "selected"}>
                <img src={Chair} alt="" />
                <span>Cash vendor</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/reserved">
              <Nav.Link
                className={location.pathname === "/reserved" && "selected"}
              >
                <img src={Discount} alt="" />
                <span>Reserved</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/ticket">
              <Nav.Link
                className={location.pathname === "/ticket" && "selected"}
              >
                <img src={Tickets} alt="" />
                <span>Tickets</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/order">
              <Nav.Link
                className={location.pathname === "/order" && "selected"}
              >
                <img src={Order} alt="" />
                <span>Orders</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["finance"]} user={userWithRoles}>
            <LinkContainer to="/finance-track">
              <Nav.Link
                className={location.pathname === "/finance-track" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Finance Track</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/adjustment">
              <Nav.Link
                className={location.pathname === "/adjustment" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Adjustment</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/order">
              <Nav.Link
                className={location.pathname === "/order" && "selected"}
              >
                <img src={Order} alt="" />
                <span>Orders</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/ticket">
              <Nav.Link
                className={location.pathname === "/ticket" && "selected"}
              >
                <img src={Tickets} alt="" />
                <span>Tickets</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/report">
              <Nav.Link
                className={location.pathname === "/report" && "selected"}
              >
                <img src={Reports} alt="" />
                <span>Reports</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["create_events"]} user={userWithRoles}>
            <LinkContainer to="/home">
              <Nav.Link className={location.pathname === "/home" && "selected"}>
                <img src={Dashboard} alt="" />
                <span>Dashboard</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/event">
              <Nav.Link
                className={location.pathname === "/event" && "selected"}
              >
                <img src={Event} alt="" />
                <span>Events</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/seating-template">
              <Nav.Link
                className={
                  location.pathname === "/seating-template" && "selected"
                }
              >
                <img src={Chair} alt="" />
                <span>Seating templates</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/ticket">
              <Nav.Link
                className={location.pathname === "/ticket" && "selected"}
              >
                <img src={Tickets} alt="" />
                <span>Tickets</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/order">
              <Nav.Link
                className={location.pathname === "/order" && "selected"}
              >
                <img src={Order} alt="" />
                <span>Orders</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/discountcode">
              <Nav.Link
                className={location.pathname === "/discountcode" && "selected"}
              >
                <img src={Discount} alt="" />
                <span>Discount Codes</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["live_feeds"]} user={userWithRoles}>
            <LinkContainer to="/home">
              <Nav.Link className={location.pathname === "/home" && "selected"}>
                <img src={Dashboard} alt="" />
                <span>Dashboard</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["organizer"]} user={userWithRoles}>
            <LinkContainer to="/home">
              <Nav.Link className={location.pathname === "/home" && "selected"}>
                <img src={Dashboard} alt="" />
                <span>Dashboard</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/newsupdate">
              <Nav.Link
                className={location.pathname === "/newsupdate" && "selected"}
              >
                <img src={NewsUpdates} alt="" />
                <span>News Updates</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/discountcode">
              <Nav.Link
                className={location.pathname === "/discountcode" && "selected"}
              >
                <img src={Discount} alt="" />
                <span>Discount Codes</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/feedback">
              <Nav.Link
                className={location.pathname === "/feedback" && "selected"}
              >
                <img src={Feedbacks} alt="" />
                <span>Feedbacks</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/faq">
              <Nav.Link className={location.pathname === "/faq" && "selected"}>
                <img src={FAQs} alt="" />
                <span>FAQs</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/termscondition">
              <Nav.Link
                className={
                  location.pathname === "/termscondition" && "selected"
                }
              >
                <img src={Terms} alt="" />
                <span>Terms & Conditions</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/policyeditor">
              <Nav.Link
                className={location.pathname === "/policyeditor" && "selected"}
              >
                <img src={Privacy} alt="" />
                <span>Privacy Policy</span>
              </Nav.Link>
            </LinkContainer>
          </RoleBasedComponent>

          {/* <RoleBasedComponent role={["admin", "viewer"]} user={userWithRoles}>
            <LinkContainer to="/home"><Nav.Link className={location.pathname === '/home' && "selected"}><img src={Dashboard} alt="" /><span>Dashboard</span></Nav.Link></LinkContainer>
            <LinkContainer to="/event"><Nav.Link className={location.pathname === '/event' && "selected"}><img src={Event} alt="" /><span>Events</span></Nav.Link></LinkContainer>
            <LinkContainer to="/ticket"><Nav.Link className={location.pathname === '/ticket' && "selected"}><img src={Tickets} alt="" /><span>Tickets</span></Nav.Link></LinkContainer>
            <LinkContainer to="/order"><Nav.Link className={location.pathname === '/order' && "selected"}><img src={Order} alt="" /><span>Orders</span></Nav.Link></LinkContainer>
            <LinkContainer to="/userinfo"><Nav.Link className={location.pathname === '/userinfo' && "selected"}><img src={Users} alt="" /><span>Users</span></Nav.Link></LinkContainer>
            <LinkContainer to="/cash"><Nav.Link className={location.pathname === '/cash' && "selected"}><img src={Chair} alt="" /><span>Cash vendor</span></Nav.Link></LinkContainer>
            <LinkContainer to="/reserved"><Nav.Link className={location.pathname === '/reserved' && "selected"}><img src={Discount} alt="" /><span>Reserved</span></Nav.Link></LinkContainer>
            <LinkContainer to="/finance-track"><Nav.Link className={location.pathname === '/finance-track' && "selected"}><img src={Feedbacks} alt="" /><span>Finance Track</span></Nav.Link></LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["live_feeds"]} user={userWithRoles}>
            <LinkContainer to="/home"><Nav.Link className={location.pathname === '/home' && "selected"}><img src={Dashboard} alt="" /><span>Dashboard</span></Nav.Link></LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role={["admin", "create_events"]} user={userWithRoles}>
            <LinkContainer to="/event"><Nav.Link className={location.pathname === '/event' && "selected"}><img src={Event} alt="" /><span>Events</span></Nav.Link></LinkContainer>
          </RoleBasedComponent>

          <LinkContainer to="/ticket"><Nav.Link className={location.pathname === '/ticket' && "selected"}><img src={Tickets} alt="" /><span>Tickets</span></Nav.Link></LinkContainer>
          <LinkContainer to="/order"><Nav.Link className={location.pathname === '/order' && "selected"}><img src={Order} alt="" /><span>Orders</span></Nav.Link></LinkContainer>
          <LinkContainer to="/seating-template"><Nav.Link className={location.pathname === '/seating-template' && "selected"} ><img src={Chair} alt="" /><span>Seating templates</span></Nav.Link></LinkContainer>
          <LinkContainer to="/members"><Nav.Link className={location.pathname === '/members' && "selected"}><img src={Member} alt="" /><span>Members</span></Nav.Link></LinkContainer>
          <LinkContainer to="/userinfo"><Nav.Link className={location.pathname === '/userinfo' && "selected"}><img src={Users} alt="" /><span>Users</span></Nav.Link></LinkContainer>
          <LinkContainer to="/speakers"><Nav.Link className={location.pathname === '/speakers' && "selected"}><img src={Speakers} alt="" /><span>Speakers</span></Nav.Link></LinkContainer>

          <RoleBasedComponent role={["admin", "cash_vendor"]} user={userWithRoles} >
            <LinkContainer to="/ticket"><Nav.Link className={location.pathname === '/ticket' && "selected"}><img src={Tickets} alt="" /><span>Tickets</span></Nav.Link></LinkContainer>
            <LinkContainer to="/order"><Nav.Link className={location.pathname === '/order' && "selected"}><img src={Order} alt="" /><span>Orders</span></Nav.Link></LinkContainer>
            <LinkContainer to="/userinfo"><Nav.Link className={location.pathname === '/userinfo' && "selected"}><img src={Users} alt="" /><span>Users</span></Nav.Link></LinkContainer>
            <LinkContainer to="/cash"><Nav.Link className={location.pathname === '/cash' && "selected"}><img src={Chair} alt="" /><span>Cash vendor</span></Nav.Link></LinkContainer>
          </RoleBasedComponent>

          <RoleBasedComponent role="reserve_tickets" user={userWithRoles} >
            <LinkContainer to="/cash"><Nav.Link className={location.pathname === '/cash' && "selected"}><img src={Chair} alt="" /><span>Cash vendor</span></Nav.Link></LinkContainer>
            <LinkContainer to="/reserved"><Nav.Link className={location.pathname === '/reserved' && "selected"}><img src={Discount} alt="" /><span>Reserved</span></Nav.Link></LinkContainer>
          </RoleBasedComponent>
          
          <RoleBasedComponent role="finance" user={userWithRoles} >
            <LinkContainer to="/finance-track"><Nav.Link className={location.pathname === '/finance-track' && "selected"}><img src={Feedbacks} alt="" /><span>Finance Track</span></Nav.Link></LinkContainer>
          </RoleBasedComponent>

          <LinkContainer to="/report"><Nav.Link className={location.pathname === '/report' && "selected"}><img src={Reports} alt="" /><span>Reports</span></Nav.Link></LinkContainer>
          <LinkContainer to="/newsupdate"><Nav.Link className={location.pathname === '/newsupdate' && "selected"}><img src={NewsUpdates} alt="" /><span>News Updates</span></Nav.Link></LinkContainer>
          <LinkContainer to="/discountcode"><Nav.Link className={location.pathname === '/discountcode' && "selected"}><img src={Discount} alt="" /><span>Discount Codes</span></Nav.Link></LinkContainer>
          <LinkContainer to="/feedback"><Nav.Link className={location.pathname === '/feedback' && "selected"}><img src={Feedbacks} alt="" /><span>Feedbacks</span></Nav.Link></LinkContainer>

          <LinkContainer to="/adjustment"><Nav.Link className={location.pathname === '/adjustment' && "selected"}><img src={Feedbacks} alt="" /><span>Adjustment</span></Nav.Link></LinkContainer>
          <LinkContainer to="/faq"><Nav.Link className={location.pathname === '/faq' && "selected"}><img src={FAQs} alt="" /><span>FAQs</span></Nav.Link></LinkContainer>
          <LinkContainer to="/termscondition"><Nav.Link className={location.pathname === '/termscondition' && "selected"}><img src={Terms} alt="" /><span>Terms & Conditions</span></Nav.Link></LinkContainer>
          <LinkContainer to="/policyeditor"><Nav.Link className={location.pathname === '/policyeditor' && "selected"}><img src={Privacy} alt="" /><span>Privacy Policy</span></Nav.Link></LinkContainer> */}
          <div onClick={handleLogout}>
            <Nav.Link>
              <img src={Logout} alt="" />
              <span>Logout</span>
            </Nav.Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Sidebar;
