import React, { useState } from 'react';
import './filter.css'; 

const Filters = (props) => {
  // State for filter options
  const [paymentTypeFilter, setPaymentTypeFilter] = useState('All');
  const [bookingStatusFilter, setBookingStatusFilter] = useState('All');
  const [eventNameFilter, setEventNameFilter] = useState('All');

  return (
    
      props?.pageTitle?.length > 0 ?
    
      <>
        <div className="tickets-container">
          <div className="filter-options">
            <h2>{props?.pageTitle}</h2>
          </div>
        </div>
        <div className='seperator'></div>
      </>
    
      :

      <></>
  );
};

export default Filters;
