import React, { useState, useEffect } from "react";
import AdjustmentPopup from "./AdjustmentPopup";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import "./adjustment.css";
import { useSelector } from "react-redux";
import { getData } from "../../../services/data";
import { setAdjustments } from "../../../store/slices/adjustment";
import { useNavigate } from "react-router-dom";

const Adjustment = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [financeUser, setFinanceUser] = useState("All");
  const [showOptions, setShowOptions] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPage, setMaxPage] = useState(1);

  const navigate = useNavigate();

  const adjustments = useSelector((state) => state.adjustment.data);

  useEffect(() => {
    let params = { page, pageSize };
    if (search && search !== "") params["search"] = search;
    getData("users/finance_cash_adjustment", setAdjustments, params).then(
      (res) => {
        setMaxPage(res.data.totalPages);
      }
    );
  }, [page, search, showOrderDetails]);

  const toggleOptions = (index) => {
    setShowOptions(!showOptions);
    setSelectedRowIndex(index);
  };

  const toggleOrderDetails = (order) => {
    setSelectedOrder(order);
    setShowOrderDetails(!showOrderDetails);
    toggleOptions();
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header saerch={search} setSearch={setSearch} />
        <div className="page__container_wrapper ticket__page">
          <div className="tickets-container">
            <div className="filter-options">
              <h2>Adjustments</h2>

              <div className="filter__option_col_wrap">
                <div className="filter__option_col">
                  <h6> User</h6>
                  <select
                    value={financeUser}
                    onChange={(e) => setFinanceUser(e.target.value)}
                    className="form-select"
                  >
                    <option value="All">All </option>
                    <option value="Chad Lucas">Chad Lucas</option>
                    <option value="Chris Porter">Chris Porter</option>
                    <option value="Rose Banks">Rose Banks</option>
                  </select>
                </div>
                <div className="filter__option_col">
                  <h6>Date</h6>
                  <input type="date" />
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Ticket Sales</th>
                  <th>Adjustment</th>
                  <th>Balance</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {adjustments?.users?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.first_name + " " + item.last_name}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.sum_amount}</td>
                    <td>{item.sum_paid}</td>
                    <td>{item.balance}</td>
                    <td>
                      <div className="userCreation__main_table_options">
                        <div
                          className="userCreation__main_table_options__button"
                          onClick={() => toggleOptions(index)}
                        >
                          <i className="fas fa-ellipsis-v"></i>
                        </div>
                        {showOptions && selectedRowIndex === index && (
                          <div className="userCreation__main_table_menu_dropdown">
                            <ul className="userCreation__main_table_menu_options">
                              <li
                                onClick={() =>
                                  navigate(`/userinfo`, {
                                    state: item,
                                  })
                                }
                              >
                                View Profile
                              </li>
                              <li onClick={() => toggleOrderDetails(item)}>
                                Add Payments
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {showOrderDetails && (
            <AdjustmentPopup
              selectedOrder={selectedOrder}
              onClose={() => setShowOrderDetails(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Adjustment;
