
import React, { useState } from 'react';
import './filter.css';

const Filters = ({ verifiedFilter, setVerifiedFilter, dateFilter, setDateFilter }) => {
  // State for filter options

  return (
    <div className="tickets-container">

      <div className="filter-options member_filter">
        <h2>App Members</h2>

        <div className='filter__option_col'>

          {/* <h6>Location</h6> */}

          <select
            value={verifiedFilter}
            onChange={(e) => setVerifiedFilter(e.target.value)}
            className='form-select'
          >
            <option value="All">All </option>
            <option value={true}>Verified</option>
            <option value={false}>Non verified</option>
          </select>

          &nbsp;&nbsp;&nbsp;

          <h6>Date</h6>

          <input value={dateFilter} onChange={(e) => setDateFilter(e.target.value)} type="date" />
        </div>
      </div>
    </div>
  );
};

export default Filters;
