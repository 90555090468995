import React, { useEffect, useState } from 'react';
import './filter.css';
import { useSelector } from 'react-redux';
import { getData } from '../../../services/data';
import { setEvents } from '../../../store/slices/event';
import { getAKeyFromObjArr } from '../../../helpers/utils';

const Filters = ({ paymentTypeFilter, setPaymentTypeFilter, eventNameFilter, setEventNameFilter, date, setDate }) => {
  const events = useSelector((state) => state.event.data);
  useEffect(() => {
    getData('events/admin', setEvents);
  }, []);
  
  return (
    <div className="tickets-container">

      <div className="filter-options">

        <h2>Tickets</h2>

        <div className='filter__option_col'>
          <h6>Payment Type</h6>
          <select
            value={paymentTypeFilter}
            onChange={(e) => setPaymentTypeFilter(e.target.value)}
            className='form-select'
          >
            <option value="All">All </option>
            <option value="cash">Cash</option>
            <option value="app">App</option>
          </select>
        </div>

        <div className='filter__option_col'>
          <h6>Event Names</h6>
          <select
            value={eventNameFilter}
            onChange={(e) => setEventNameFilter(e.target.value)}
            className='form-select'
          >
            <option value="All">All </option>
            {/* {getAKeyFromObjArr(events, 'name').map((event) => ( */}
            {events.map((event) => (
              <option key={event.id} value={event.id}>
                {event.name}
              </option>
            ))}
          </select>
        </div>
        <div className='filter__option_col'>
          <h6>Date</h6>
          <input value={date} onChange={(e) => setDate(e.target.value)} type="date" />
        </div>

      </div>
    </div>
  );
};

export default Filters;
