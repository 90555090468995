import React, { useState, useEffect } from "react";
import Filters from "./filter";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../../../services/data";
import { setTickets } from "../../../store/slices/ticket";
import ReactPaginate from "react-paginate";
import "./filter.css";

const Ticket = () => {
  const dispatch = useDispatch();
  const tickets = useSelector((state) => state.ticket.data);
  const [search, setSearch] = useState("");

  // State for filter options
  const [paymentTypeFilter, setPaymentTypeFilter] = useState(null);
  const [eventNameFilter, setEventNameFilter] = useState(null);
  const [date, setDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPage, setMaxPage] = useState(1);

  const getTicketsHandler = (search) => {
    const payload = {
      page: page,
      pageSize: pageSize,
      payment_type:
        paymentTypeFilter === null || paymentTypeFilter === "All"
          ? null
          : paymentTypeFilter,
      date: date === null ? null : date,
      event_id:
        eventNameFilter === null || eventNameFilter === "All"
          ? null
          : eventNameFilter,
    };
    if (search) {
      payload["search"] = search;
    }
    postData("tickets/tickets_filter", setTickets, payload).then((res) => {
      dispatch(setTickets(res.data));
      setMaxPage(res.data.totalPages);
    });
  };

  useEffect(() => {
    getTicketsHandler();
  }, [page, paymentTypeFilter, eventNameFilter, date]);
  useEffect(() => {
    if (search.length === 0) {
      getTicketsHandler();
      return;
    }
    if (search.length > 4) {
      getTicketsHandler(search);
    }
  }, [search]);

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header search={search} setSearch={setSearch} />
        <div className="page__container_wrapper ticket__page">
          <Filters
            date={date}
            setDate={setDate}
            paymentTypeFilter={paymentTypeFilter}
            setPaymentTypeFilter={setPaymentTypeFilter}
            eventNameFilter={eventNameFilter}
            setEventNameFilter={setEventNameFilter}
          />
          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ticket No</th>
                  <th>Order ID</th>
                  <th>Purchase Date</th>
                  <th>Customer</th>
                  <th>Phone No</th>
                  <th>Event Name</th>
                  <th>Amount Paid</th>
                  <th>Payment Type</th>
                  <th>Status</th>
                  <th>Cash Vendor</th>
                </tr>
              </thead>
              <tbody>
                {tickets?.tickets?.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      No Result Found
                    </td>
                  </tr>
                ) : (
                  tickets?.tickets?.map((item, index) => (
                    <tr key={index}>
                      <td>{(page == 1 ? 0 : (page - 1) * 10) + (index + 1)}</td>
                      <td>{item.number}</td>
                      <td>{item.invoice_id}</td>
                      <td>
                        {new Date(item.created_at).toLocaleDateString("en-US")}
                      </td>
                      <td>
                        {item.creator?.first_name} {item?.creator?.last_name}
                      </td>
                      <td>{item.creator ? `+${item.creator?.phone}` : ""}</td>
                      <td>{item.event_name}</td>
                      <td>{item.ticket_price}</td>
                      <td>{item.payment_type}</td>
                      <td>
                        {item.is_scanned
                          ? "Scanned"
                          : item.is_paid
                          ? "Paid"
                          : item.is_reserved
                          ? "Reserved"
                          : "Pending"}
                      </td>
                      <td>
                        {item.vendor?.first_name} {item?.vendor?.last_name}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <ReactPaginate
              breakLabel="..."
              className="paginationBot"
              nextLabel=">"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={15}
              pageCount={maxPage}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
