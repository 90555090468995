import './App.css';
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { persistor, store } from './store';

import MyRoutes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { useEffect } from 'react';


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <MyRoutes />
      </PersistGate>
    </Provider >
  );
}

export default App;