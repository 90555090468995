import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
};

export const userApp = createSlice({
    name: "user-app",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setUserApp: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setLoading, setUserApp } = userApp.actions;

export default userApp.reducer;
