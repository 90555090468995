import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
};

export const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBanners: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setLoading, setBanners } = bannersSlice.actions;

export default bannersSlice.reducer;
