import React, { useEffect, useState } from "react";
import { getData, postData } from "../../../services/data";
import { showSuccess } from "../../../helpers/toast";
import { setEvents } from "../../../store/slices/event";
import { useSelector } from "react-redux";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const UserCreationForm = ({ onCancel }) => {
  const events = useSelector((state) => state.event.data);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [formData, setFormData] = useState({
    Fname: "",
    Lname: "",
    email: "",
    phone: "",
    orgDepartment: "",
    address: "",
    selectEvent: "",
    checkboxes: {
      admin: false,
      create_events: false,
      cash_vendor: false,
      scan_tickets: false,
      live_feeds: false,
      reserve_tickets: false,
      finance: false,
      organizer: false,
      viewer: false,
    },
    createEvent: false,
    numberOfEvents: "",
    timeLimit: "",
  });
  const [processingAJAX, setProcessingAJAX] = useState(false);

  useEffect(() => {
    getData("events/admin", setEvents);
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? { ...prevData[name], [value]: checked } : value,
    }));
  };

  const handleChangeEvent = (selectedEvent) => {
    setSelectedEvent(selectedEvent);
  };

  const handleFormSubmit = () => {
    setProcessingAJAX(true);
    postData("users/create_user", undefined, {
      first_name: formData.Fname,
      last_name: formData.Lname,
      email: formData.email,
      phone: formData.phone.substring(1),
      department: formData.orgDepartment,
      address: formData.address,
      // events: Array.isArray(formData.selectEvent) ? formData.selectEvent.join(',') : formData.selectEvent,
      events: selectedEvent.map((a) => a.id).toString(),
      roles: Object.keys(formData.checkboxes)
        .filter((k) => formData.checkboxes[k])
        .join(","),
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("User created successfully");
          onCancel();
          setProcessingAJAX(false);
        }
      })
      .catch((error) => {
        setProcessingAJAX(false);
      });
    setFormData({
      Fname: "",
      Lname: "",
      email: "",
      phone: "",
      orgDepartment: "",
      address: "",
      selectEvent: "",
      checkboxes: {
        create_events: false,
        cash_vendor: false,
        scan_tickets: false,
        live_feeds: false,
        reserve_tickets: false,
        finance: false,
      },
      createEvent: false,
      numberOfEvents: "",
      timeLimit: "",
    });
  };

  return (
    <div>
      <div className="add__user_main_wrap">
        <h2 className="add__user_title_heading">Add User</h2>
        <form className="add__user_form_field_form">
          <div className="add__user_form_field">
            <label>First Name</label>
            <input
              type="text"
              name="Fname"
              value={formData.Fname}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <label>Last Name</label>
            <input
              type="text"
              name="Lname"
              value={formData.Lname}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <label>Phone Number</label>
            {/* <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            /> */}
            <PhoneInput
              initialValueFormat="national"
              international
              defaultCountry="BS"
              className="w-100"
              value={formData.phone}
              onChange={(value) =>
                handleInputChange({
                  target: {
                    name: "phone",
                    value,
                  },
                })
              }
            />
          </div>
          <div className="add__user_form_field">
            <label>Department</label>
            <input
              type="text"
              name="orgDepartment"
              value={formData.orgDepartment}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <label>Location</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            {/* <label>Select Event</label>
            <select
              className="event form-select form-select"
              name="selectEvent"
              value={formData.selectEvent}
              onChange={handleInputChange}
            >
              <option value="">Select Event</option>
              {
                events.map((event) => (
                  <option value={event.id}>{event.name}</option>
                ))
              }

              <option value="Event 2">Event 2</option>
            </select> */}
            <div className="event__creation_field_wrap select_speaker_field w-100">
              <label className="mb-4">Select Event </label>
              <Select
                isMulti
                options={events}
                getOptionLabel={(s) => s.name}
                getOptionValue={(s) => s.id}
                value={selectedEvent}
                onChange={handleChangeEvent}
                className="select_speaker_field_drop form-select"
              />
            </div>
          </div>

          <div className="add__user_title_heading">Role Assign</div>
          <div className="add__user_form_field_checkbox">
            {Object.keys(formData.checkboxes).map((checkbox) => (
              <label key={checkbox} className="checkbox-label">
                <input
                  type="checkbox"
                  className={`checkbox-${checkbox}`}
                  name="checkboxes"
                  value={checkbox}
                  checked={formData.checkboxes[checkbox]}
                  onChange={handleInputChange}
                />
                {checkbox.replace("_", " ")}
              </label>
            ))}
          </div>
          {/* Remaining code for createEvent, numberOfEvents, timeLimit */}
          {/* ... */}
          <div className="add__user_form_field_button">
            <button
              type="button"
              onClick={handleFormSubmit}
              className={`add__user_form_field_button_add_user ${
                processingAJAX ? "disbaledButton" : ""
              }`}
            >
              {processingAJAX ? "Adding User" : "Add User"}
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="add__user_form_field_button_cancel"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserCreationForm;
