import React from "react";
import { useNavigate } from "react-router-dom";
import "./headerInner.css";
import profileImage from "./profile.jpg";

const HeaderInner = ({ saerch, setSearch = () => {} }) => {
  const navigate = useNavigate();

  const goToProfilePage = () => {
    navigate("/profilesetting");
  };

  return (
    <div className="headerInner_main_container">
      <div className="headerInner__main_wrap">
        <div className="left">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            value={saerch}
            onChange={(e) => setSearch(e.target.value)}
            className="search-input"
            type="text"
            placeholder="Search Now..."
          />
        </div>
        <div className="right">
          {/* <i className="fa-regular fa-bell notification"></i> */}
          <img
            className="profile-picture"
            src={profileImage}
            alt="pic"
            onClick={goToProfilePage}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderInner;
