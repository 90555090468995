export const LOGIN = 'auth/signin_admin';
export const OTP = 'auth/signin_otp_admin';
export const SENDRESETOTP = 'users/send_forgot_password_otp';
export const VERIFYRESETOTP = 'users/verify_email_otp';

export const EVENTS = 'events';

export const NEWS = 'news';

export const FEEDBACKS = 'feedbacks';

export const SPEAKERS = 'speakers';

export const TICKETS = 'tickets';

export const FAQS = 'faqs';