export const manipulateSeatingList = (seating) => {
  let sections = [];
  let data = [];
  seating.forEach((seat) => {
    // SECTIONS LOGIC
    if (seat?.parent_id) {
      const parentdata = seat.parent_id;
      if (sections.findIndex((x) => x.id === parentdata.id) === -1) {
        const payload = {
          id: parentdata?.id,
          title: parentdata?.title,
          color: parentdata?.color,
          rowReverse: seat?.rowReverse,
        };
        sections.push(payload);
      }
    }
    // ROWS AND COLUMNS LOGIC
    const isRow = data.findIndex(
      (x) =>
        x.row === parseInt(seat?.row) && x.parent_id === seat?.parent_id?.id
    );
    const column = {
      id: seat?.id,
      title: seat?.title,
      column: seat?.column,
      price: seat?.price,
      ticketType: seat?.ticketType,
      isDisabled: seat?.isDisabled || seat?.isBooked || seat?.isReserved,
      symbol: seat?.symbol,
      parent_id: seat?.parent_id?.id,
      background: seat?.isBooked
        ? "#58677d"
        : seat?.isDisabled
        ? "#dfe2e6"
        : seat?.isReserved
        ? "#58677d"
        : seat?.parent_id?.color
        ? seat?.parent_id?.color
        : "#ef6b3b",
      color: seat?.isDisabled ? "#707070" : "#fff",
    };
    if (isRow === -1) {
      const payload = {
        row: parseInt(seat?.row ?? 0),
        parent_id: seat?.parent_id?.id,
        color: seat?.parent_id?.color ?? "#ef6b3b",
        columnReverse: seat?.columnReverse,
        columns: [column],
      };
      data.push(payload);
    } else {
      data[isRow].columns.push(column);
    }
  });
  // SORT ROWS
  data.sort((a, b) => a.row - b.row);
  // SORT COLUMNS
  data.forEach((row) => {
    if (row.columnReverse) {
      row.columns.sort((a, b) => b.column - a.column);
    } else {
      row.columns.sort((a, b) => a.column - b.column);
    }
  });
  // SORT SECTIONS
  sections.sort((a, b) => a.title.localeCompare(b.title));
  return { sections, data };
};

export const manipulateSeatingPayload = (data) => {
  const sections = [];
  const sortedsections = [];
  const response = [];

  data.forEach((row) => {
    const isSectionExsist = sections.findIndex(
      (x) => x.name === row.parentId.title
    );
    if (isSectionExsist === -1) {
      const section = {
        name: row.parentId.title,
        data: [row],
      };
      sections.push(section);
    } else {
      sections[isSectionExsist].data.push(row);
    }
  });
  [...sections].forEach((section) => {
    const payload = { ...section };
    const isRowReverse =
      section.data[0].rowname > section.data[section.data.length - 1].rowname;
    const isColumnReverse =
      parseInt(section.data[0].columnname) >
      parseInt(section.data[section.data.length - 1].columnname);
    payload["rowReverse"] = isRowReverse;
    payload["columnReverse"] = isColumnReverse;
    sortedsections.push(payload);
  });
  data.forEach((row) => {
    const payload = {
      ...row,
      rowReverse: sortedsections.find((x) => x.name === row.parentId.title)
        .rowReverse,
      columnReverse: sortedsections.find((x) => x.name === row.parentId.title)
        .columnReverse,
    };
    response.push(payload);
  });
  return response;
};
