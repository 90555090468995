import React, { useState } from "react";
import Logo from "../../../assets/media/logo.svg";
import ForgotNumber from "./forgot-number";
import ForgotReset from "./forget-reset";
import ForgotOtp from "./forget-otp";

const ForgotPassword = (props) => {
  const [currentstep, setcurrentstep] = useState(1);
  const [inputData, setinputData] = useState({});

  const nextStepHandler = (data) => {
    setinputData({ ...inputData, ...data });
    setcurrentstep(currentstep + 1);
  };

  return (
    <div className="App bodyLogin">
      {/* <div className="container"> */}
      <div className="landingWrapper">
        <div className="landingRow">
          <div className="landingCol">
            <img className="logo" src={Logo} alt="" />
            <h1>Forgot Password</h1>
            <p className="d-none">{props.user ? "logged In" : "logged out"}</p>
            <p>
              {currentstep === 3
                ? "Enter your new password"
                : currentstep === 2
                ? "Enter the OTP sent to your phone"
                : "To reset your password enter your registered phone number"}
            </p>
            {currentstep === 3 ? (
              <ForgotReset inputData={inputData}/>
            ) : currentstep === 2 ? (
              <ForgotOtp
                nextStepHandler={nextStepHandler}
                inputData={inputData}
              />
            ) : (
              <ForgotNumber
                nextStepHandler={nextStepHandler}
                inputData={inputData}
              />
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ForgotPassword;
