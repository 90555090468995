import React, { useEffect, useState } from "react";
import Filters from "./filter";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import "./filter.css";
import "./member.css";
import OrderDetails from "../order/OrderDetails";
import { getData } from "../../../services/data";
import { setUserApp } from "../../../store/slices/user-app";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import arrow from "./arrow.png";
import Profile from "./profile.jpg";
import Phone from "./phone.png";
import Email from "./mail.png";
import Location from "./location.png";
import Calendar from "./calendar.svg";
import { setEvents } from "../../../store/slices/event";

const Member = () => {
  const [search, setSearch] = useState("");
  const users = useSelector((state) => state.userApp.data);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [orderPage, setOrderPage] = useState(1);
  const [maxOrderPage, setMaxOrderPage] = useState(1);
  const [verifiedFilter, setVerifiedFilter] = useState("All");
  const [dateFilter, setDateFilter] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [statusFilter, setStatusFilter] = useState("All");
  const [eventTypeFilter, setEventTypeFilter] = useState(null);
  const [orderdateFilter, setOrderDateFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState("All");
  const [currentUserDetail, setCurrentUserDetail] = useState("All");
  const [orderDetail, setorderDetail] = useState(null);

  const events = useSelector((state) => state.event.data);
  useEffect(() => {
    let params = { page, pageSize };
    if (search && search != "") params["search"] = search;
    getData("users/get_app_users", setUserApp, params).then((res) =>
      setMaxPage(res.data.totalPages)
    );
  }, [page, search]);

  const getUserDetail = (id) => {
    getData(
      `users/get_app_users/${id}?page=${orderPage}&pageSize=${pageSize}&event_id=${
        eventTypeFilter === "All" ? null : eventTypeFilter
      }&date=${orderdateFilter}`
    ).then((res) => {
      setCurrentUserDetail(res.data);
      setMaxOrderPage(res?.data?.pagination?.totalPages);
    });
  };

  const handleUserClick = (userData) => {
    setSelectedUser(userData);
    getUserDetail(userData.id);
    // getData(`users/get_app_users/${userData.id}`).then((res) => {
    //   setCurrentUserDetail(res.data);
    //   setMaxPage(currentUserDetail?.order_count);
    // });
  };

  useEffect(() => {
    if (selectedUser) {
      getUserDetail(selectedUser.id);
    }
  }, [orderPage]);

  const handleBackUserList = () => {
    setSelectedUser(null);
  };
  const orderDetailHandler = (order) => {
    const obj = {
      ...order,
      cash_vendor: "=",
      customerName: `${currentUserDetail?.user?.first_name ?? ""} ${
        currentUserDetail?.user?.last_name ?? ""
      }`,
      customerPhone: currentUserDetail?.user
        ? `+${currentUserDetail?.user?.phone}`
        : "",
      eventName: order?.event_name,
    };
    setorderDetail(obj);
  };

  useEffect(() => {
    getData("events/admin", setEvents);
  }, []);

  useEffect(() => {
    if (selectedUser) {
      getUserDetail(selectedUser.id);
    }
  }, [eventTypeFilter, orderdateFilter]);

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header saerch={search} setSearch={setSearch} />
        {!selectedUser && !orderDetail && (
          <div className="page__container_wrapper ticket__page">
            <Filters
              verifiedFilter={verifiedFilter}
              setVerifiedFilter={setVerifiedFilter}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
            />

            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Members</th>
                    <th>Phone No</th>
                    <th>Email</th>
                    <th>Location</th>
                    {/* <th>Last Event Attend</th> */}
                    <th>Registered date</th>
                    {/* <th>Total Purchase</th> */}
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.users
                    ?.filter(
                      (u) =>
                        (verifiedFilter === "All" ||
                          (verifiedFilter === "true" &&
                            u.email_verified &&
                            u.phone_verified) ||
                          (verifiedFilter === "false" &&
                            (!u.email_verified || !u.phone_verified))) &&
                        (!dateFilter ||
                          new Date(u.created_at).setHours(0, 0, 0, 0) ===
                            new Date(dateFilter).setHours(0, 0, 0, 0))
                    )
                    .map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => handleUserClick(item)}
                        className="member_info_row"
                      >
                        <td>
                          {(page == 1 ? 0 : (page - 1) * 10) + (index + 1)}
                        </td>
                        <td>{item.first_name + " " + item.last_name}</td>
                        <td>{item.phone}</td>
                        <td>{item.email}</td>
                        <td>{item.address}</td>
                        {/* <td>{item.lastEventAttend}</td> */}
                        <td>{item.created_at}</td>
                        {/* <td>{item.totalPurchase}</td> */}
                      </tr>
                    ))}
                </tbody>
              </table>

              <ReactPaginate
                breakLabel="..."
                className="paginationBot"
                nextLabel=">"
                onPageChange={(e) => setPage(e.selected + 1)}
                pageRangeDisplayed={15}
                pageCount={maxPage}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}

        {selectedUser && !orderDetail && (
          <div className="page__container_wrapper ticket__page">
            <div className="user-details__main">
              <div className="user__detail_header">
                <div
                  className="user__header_title"
                  onClick={handleBackUserList}
                >
                  <img src={arrow} alt="-" /> Members Profile
                </div>
                <div className="userDetials__info_wrap">
                  <div className="userDetials__info_left">
                    <div className="img__action_wrap">
                      <img src={Profile} alt="-" />
                      <div className="userDetials__info_left_action">
                        <div className="userDetials__info_left_action_dlt">
                          Delete
                        </div>
                        |
                        <div className="userDetials__info_left_action_InActive">
                          Inactive
                        </div>
                      </div>
                    </div>
                    <div className="userDetials__info_left_content">
                      <div className="userDetials__info_left_content_title">
                        {selectedUser.first_name + " " + selectedUser.last_name}
                      </div>
                      <div className="userDetials__info_left_content_contact_wrap">
                        <div className="userDetials__info_left_content_contact_item">
                          <img src={Email} alt="" />
                          <div className="userDetials__contact_data">
                            {selectedUser.email}
                          </div>
                        </div>
                        <div className="userDetials__info_left_content_contact_item">
                          <img src={Phone} alt="" />
                          <div className="userDetials__contact_data">
                            {selectedUser.phone}
                          </div>
                        </div>
                        <div className="userDetials__info_left_content_contact_item">
                          <img src={Location} alt="" />
                          <div className="userDetials__contact_data">Nassu</div>
                        </div>
                        <div className="userDetials__info_left_content_contact_item">
                          <img src={Calendar} alt="" />
                          <div className="userDetials__contact_data">
                            {new Date(
                              `${selectedUser.created_at}`
                            ).toLocaleDateString("en-US")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="userDetials__info_ticket_details">
                    <div className="userDetials__info_ticket_detailspurchase">
                      <div className="userDetials__info_ticket_detailspurchase_title">
                        Total Tickets Purchased
                      </div>
                      <div className="userDetials__info_ticket_detailspurchase_count">
                        {currentUserDetail?.ticket_count}
                      </div>
                    </div>
                    <div className="userDetials__info_ticket_detailsevent">
                      <div className="userDetials__info_ticket_detailsevent_title">
                        Total Orders
                      </div>
                      <div className="userDetials__info_ticket_detailsevent_count">
                        {currentUserDetail?.order_count}
                      </div>
                    </div>
                    <div className="userDetials__info_ticket_detailsamount">
                      <div className="userDetials__info_ticket_detailsamount_title">
                        Total Amount Spent
                      </div>
                      <div className="userDetials__info_ticket_detailsamount_count">
                        {currentUserDetail?.sum_amount?.totalAmount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-options member_filter_inner_member">
                {/* <div className="filter__option_col">
                  <h6>Status</h6>
                  <select
                    className="form-select"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}>
                    <option value="All">All</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div> */}
                <div className="filter__option_col">
                  <h6>Event Name</h6>
                  <select
                    value={eventTypeFilter}
                    onChange={(e) => setEventTypeFilter(e.target.value)}
                    className="form-select"
                  >
                    <option value="All">All </option>
                    {events?.map((event) => (
                      <option value={event.id}>{event.name}</option>
                    ))}
                  </select>
                </div>
                {/* <div className="filter__option_col">
                  <h6>Location</h6>
                  <select
                    value={locationFilter}
                    onChange={(e) => setLocationFilter(e.target.value)}
                    className="form-select">
                    <option value="All">All</option>
                    <option value="Nassau">Nassau</option>
                    <option value="FreePort">FreePort</option>
                    <option value="LA">LA</option>
                  </select>
                </div> */}

                <div className="filter__option_col">
                  <h6>Date</h6>
                  <input
                    type="date"
                    value={orderdateFilter}
                    onChange={(e) => setOrderDateFilter(e.target.value)}
                  />
                </div>
              </div>
              <div className="user__detail_body"></div>
              <div className="table-container">
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order ID</th>
                      <th>Order Date</th>
                      <th>Status</th>
                      <th>Event Name</th>
                      <th>Cash Vendor</th>
                      <th>Tickets</th>
                      <th>Discount</th>
                      <th>Paid</th>
                      <th>Gateway</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUserDetail?.order_count === 0 ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No Result Found
                        </td>
                      </tr>
                    ) : (
                      currentUserDetail?.invoiceWithDetails?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td>
                              {(orderPage == 1 ? 0 : (orderPage - 1) * 10) +
                                (index + 1)}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => orderDetailHandler(item)}
                            >
                              {item.invoice_no}
                            </td>
                            <td className="date__td">
                              {new Date(
                                `${item.created_at}`
                              ).toLocaleDateString("en-US")}
                            </td>
                            <td>
                              {item.is_paid
                                ? "Paid"
                                : item.is_reserverd
                                ? "Reserved"
                                : "Booked"}
                            </td>
                            <td>{item.event_name}</td>
                            <td>{item.cash_vendor_name}</td>
                            <td>{item.ticket_count}</td>
                            <td>{item.discount_amount}</td>
                            <td>{item.total}</td>
                            <td>{item.payment_type}</td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
                {/* {maxPage > 11 ? (
                  <ReactPaginate
                    breakLabel="..."
                    className="paginationBot"
                    nextLabel=">"
                    onPageChange={(e) => setPage(e.selected + 1)}
                    pageRangeDisplayed={15}
                    pageCount={maxPage}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )} */}
              </div>
              <ReactPaginate
                breakLabel="..."
                className="paginationBot"
                nextLabel=">"
                onPageChange={(e) => setOrderPage(e.selected + 1)}
                pageRangeDisplayed={15}
                pageCount={maxOrderPage}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        )}
        {orderDetail && (
          <div className="page__container_wrapper ticket__page">
            <OrderDetails
              order={orderDetail}
              onClose={() => setorderDetail(null)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Member;
