import React, { useEffect, useState, useRef } from 'react';
import Filters from './filter';
import Header from './headerInner';
import Sidebar from '../leftsidebar/sidebar';
import { Row, Col, FormLabel, FormGroup, Button, FormSelect, Container, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CirclePicker } from 'react-color'
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { getData, postData, putData } from '../../../services/data';
import { showError, showSuccess } from '../../../helpers/toast';
import { setSeating } from '../../../store/slices/seating';
// import 'fabric-with-gestures';
import Hammer from 'hammerjs';
import _ from "lodash";
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';
import { manipulateSeatingPayload } from '../../../data-manipulator/seating';

const MultipleSeating = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    const [stateReplica, setStateReplica] = useState(location.state);
    const { editor, onReady } = useFabricJSEditor();

    const canvasRef = useRef(null);
    // const [editor, setEditor] = useState({ canvas: null });
    const [hammer, setHammer] = useState(null);

    useEffect(() => {
        // canvasMoving();
        
        if(editor?.canvas?.upperCanvasEl) 
        {
            const hammer = new Hammer(editor.canvas.upperCanvasEl);
            setHammer(hammer);
        }
        
        return () => {
            if(editor?.canvas && hammer)
            {
                // canvas.dispose();
                hammer.destroy();
            }
        }

    }, [editor?.canvas]);

    const [canvasZoom, setCanvasZoom] = useState('1');
    const [canvasZoomList, setCanvasZoomList] = useState([
        { name: '10%', value: '0.1' },
        { name: '20%', value: '0.2' },
        { name: '30%', value: '0.3' },
        { name: '40%', value: '0.4' },
        { name: '50%', value: '0.5' },
        { name: '60%', value: '0.6' },
        { name: '70%', value: '0.7' },
        { name: '80%', value: '0.8' },
        { name: '90%', value: '0.9' },
        { name: '100%', value: '1' },
        { name: '110%', value: '1.1' },
        { name: '120%', value: '1.2' },
        { name: '130%', value: '1.3' },
        { name: '140%', value: '1.4' },
        { name: '150%', value: '1.5' },
        { name: '160%', value: '1.6' },
        { name: '170%', value: '1.7' },
        { name: '180%', value: '1.8' },
        { name: '190%', value: '1.9' },
        { name: '200%', value: '2' },
    ]);

    const [title, setTitle] = useState(stateReplica?.templateName ?? 'Title here...');
    const [symbol, setSymbol] = useState('');
    const [ticket, setTicket] = useState([
        { id: 0, type: "", count: "", from: "", to: "", msg: { success: [], error: [] } },
    ]);

    const [entrygate, setEntrygate] = useState('');
    const [ticketsCount, setTicketsCount] = useState(0);
    const [tickettype, setTickettype] = useState(0);
    const [price, setPrice] = useState('');
    const [selectedObject, setSelectedObject] = useState({});
    const [rowname, setRowname] = useState('');
    const [columnname, setColumnname] = useState('');

    const [cropImage, setCropImage] = useState(true);
    const [selectedColor, setSelectedColor] = useState('#000');
    const [elementTitle, setElementTitle] = useState('');
    const [constantWord, setConstantWord] = useState('');
    const [startFrom, setStartFrom] = useState(1);
    const [endOn, setEndOn] = useState(1);
    const [columnStartFrom, setColumnStartFrom] = useState(1);
    const [columnEndOn, setColumnEndOn] = useState(1);
    const [elementQty, setElementQty] = useState(1);
    const [elementPrice, setElementPrice] = useState('');
    const [elementTickettype, setElementTickettype] = useState('');
    const [elementEntrygate, setElementEntrygate] = useState('');

    const [canvasJson, setCanvasJson] = useState({});
    const [json, setJson] = useState(stateReplica?.seating?.template_json ?? []);
    const [chairs, setChairs] = useState(stateReplica?.seating?.chairs ?? []);

    const [fields, setFields] = useState([]);
    const [shapeType, setShapeType] = useState('');
    const [shapeName, setShapeName] = useState('');
    const [isParent, setIsParent] = useState(false);
    const [activeItem, setActiveItem] = useState('')
    const [canvasItem, setCanvasItem] = useState(0)
    const [parentElementId, setParentElementId] = useState(null)
    const [isDisable, setIsDisable] = useState(false);
    const [activeObjType, setActiveObjType] = useState(null)
    const [loading, setLoading] = useState(false);
    const [rowReversed, setRowReversed] = useState(false)
    const [colReversed, setcolReversed] = useState(false)

    const init = () => {
        setTitle('');
        setSymbol('');
        setTicket([{ id: 0, type: "", count: "", from: "", to: "", msg: { success: [], error: [] } }]);
        setEntrygate('');
        setTicketsCount(0);
        setPrice('');
        setTickettype('');
        setSelectedColor('#000');
        setElementTitle('');
        setConstantWord('');

        setStartFrom(1);
        setEndOn(1);
        setColumnStartFrom(1);
        setColumnEndOn(1);
        setElementQty(1);
        setElementPrice('');
        setElementTickettype('');
        setElementEntrygate('');

        setRowname('');
        setColumnname('');
    }

    // Sub screen states
    const [screenTitle, setScreenTitle] = useState('Multiple Seating Chart');
    const [screenDescription, setScreenDescription] = useState('You can move and rotate seating position as per your requirements');
    const [bookingSeats, setBookingSeats] = useState(0);

    const handleDropdownClick = (event) => {
        event.stopPropagation();
        // Your code to handle the dropdown click event goes here
    }

    const handleColorChange = (color) => {
        setSelectedColor(color.hex); // Store the selected color in state
    }

    const handleActiveItem = ([...fields], shapeType = null, shapeName = null, isParent = false) => {
        // console.log(fields, shapeType, shapeName, isParent)

        if (shapeType === 'image') {
            setActiveItem(shapeName);
        }
        else {
            setActiveItem(shapeType);
        }

        // init element settings
        // init();
        setStartFrom(1);
        setEndOn(1);
        setColumnStartFrom(1);
        setColumnEndOn(1);
        setElementQty(1);
        setElementPrice('');
        setElementTickettype('');
        setElementEntrygate('');

        // set shape type i.e image Or rect type
        setFields(fields);
        setShapeType(shapeType);
        setShapeName(shapeName);
        setIsParent(isParent);
    }

    // const generateRandomId = (length) => {
    //     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     let randomId = '';

    //     for (let i = 0; i < length; i++) {
    //         const randomIndex = Math.floor(Math.random() * characters.length);
    //         randomId += characters.charAt(randomIndex);
    //     }

    //     return randomId;
    // }

    const generateRandomId = () => {
        return uuidv4();
    };

    const createCustomObject = async () => {
        const rowNoObjWidth = 20;
        const rowNoObjHeight = 20;
        const objSpacing = 15;

        let _elementQty = elementQty;
        let _rowStartFrom = parseInt(startFrom);
        let _rowEndOn = parseInt(endOn);
        let _columnStartFrom = parseInt(columnStartFrom);
        let _columnEndOn = parseInt(columnEndOn);
        let _index = 0;
        let _index2 = 0;
        let loop = true;
        let loop2 = true;
        let columnQty = 0;
        let randomid = generateRandomId(8);

        setLoading(true);

        if (_rowStartFrom > 0 && _rowEndOn > 0 && _columnStartFrom > 0 && _columnEndOn > 0) {
            _index = _rowStartFrom;

            // calculation for row
            if (_rowStartFrom === _rowEndOn) {
                _elementQty = 1;
            }
            if (_rowEndOn > _rowStartFrom) {
                _elementQty = (_rowEndOn + 1) - _rowStartFrom;
            }
            if (_rowStartFrom > _rowEndOn) {
                _elementQty = (_rowStartFrom + 1) - _rowEndOn;
                loop = false;
            }

            // calculation for column
            if (_columnStartFrom === _columnEndOn) {
                columnQty = 1;
            }
            if (_columnEndOn > _columnStartFrom) {
                columnQty = ((_columnEndOn + 1) - _columnStartFrom);
            }
            if (_columnStartFrom > _columnEndOn) {
                columnQty = ((_columnStartFrom + 1) - _columnEndOn);
                loop2 = false;
            }

            if (_rowStartFrom > _rowEndOn) {
            }
        }

        if (editor && activeItem?.length > 0 && _elementQty > 0 && columnQty > 0) {

            _index2 = _columnStartFrom;

            // if (_columnEndOn > _columnStartFrom) {
            //     _index2 = _columnStartFrom;
            // }

            for (let index2 = 1; index2 < parseInt(columnQty) + 1; index2++) {

                let rowObj = {};

                // Create a row number object
                if (shapeType === 'chair') {

                    const rowRect = await new window.fabric.Rect({  // Use window.fabric.Rect
                        left: 0,
                        top: 0,
                        width: rowNoObjWidth,
                        height: rowNoObjHeight,
                        fill: 'transparent'
                    });
                    
                    const rowText = await new window.fabric.Text(""+_index2+"", {
                        left: rowRect.width / 2, // Adjust the position as needed
                        top: rowRect.height / 2, // Adjust the position as needed
                        fill: 'black', // Text color
                        fontSize: 12, // Text font size
                        originX: 'center', // Center the text horizontally
                        originY: 'center', // Center the text vertically
                        fontFamily: 'CircularStd-Book',
                    });

                    rowObj = await new window.fabric.Group([rowRect, rowText], {
                        left: 0,
                        top: (index2 > 1) ? (rowNoObjHeight + 8) * (index2 - 1) : 0,
                        data: {rowname: [_index2, 'setRowname']},
                        id: randomid,
                        objType: 'chairRowname'
                    });

                    rowObj.toObject = await (function (toObject) {
                        return function (propertiesToInclude) {
                            return new window.fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                                data: {rowname: [_index2, 'setRowname']},
                                id: randomid,
                                objType: 'chairRowname'
                            });
                        }
                    })(rowObj.toObject);

                    await editor.canvas.add( rowObj );
                }

                _index = _rowStartFrom;
                for (let index = 1; index < parseInt(_elementQty) + 1; index++) {

                    let ticketsCount = 0;
                    let ticketRange = '';
                    let data = {}
                    let object = [];
                    let objWidth = 0;
                    let objHeight = 0;
                    let objType = '';
                    let randomId = generateRandomId(8);

                    // if (_rowEndOn > _rowStartFrom) {
                    //     _index = index;
                    // }

                    if (shapeType === 'image') {
                        let objWidth = 500;
                        let objHeight = 500;
                        const svgIndex = (index > 0) ? (index - 1) : 0;
                        let content = '';

                        if (isParent) objType = 'section';

                        const svgOptions = async (objects, options) => {
                            let svg = new window.fabric.util.groupSVGElements(objects, options);
                            svg.scaleToWidth(objWidth);
                            // svg.scaleToHeight(objHeight);

                            svg.id = randomId;
                            svg.left = (svgIndex > 0) ? ((objWidth + 15) * (svgIndex)) : 0;

                            if (typeof svg.forEachObject === 'function') {
                                // Traverse all objects in the group and set their fill color
                                svg.forEachObject(function (subObject) {
                                    subObject.set({
                                        fill: selectedColor,
                                        initFill: selectedColor,
                                        stroke: 'transparent',
                                    });
                                });
                            }

                            object.push(svg);

                            if (fields?.length > 0) {
                                // Calculate the center position of the SVG group
                                const centerX = svg.left + objWidth / 2;
                                const centerY = objHeight / 2;

                                // Create a Text object for your text
                                let text =  await new window.fabric.Text(content, {
                                    left: centerX, // Adjust the position as needed
                                    top: centerY, // Adjust the position as needed
                                    fill: 'white', // Text color
                                    fontSize: (objType === 'section') ? 250 : 24, // Text font size
                                    originX: 'center', // Center the text horizontally
                                    originY: 'center', // Center the text vertically,
                                    fontFamily: 'CircularStd-Book'
                                });
                                object.push(text);
                            }

                            // if (isParent) objType = 'section';

                            // Group the Rect and Text together
                            let _group = await new window.fabric.Group(object, {
                                left: (index > 1) ? ((objWidth + 15) * (index - 1)) : 0,
                                top: (index2 > 1) ? ((objHeight + 15) * (index2 - 1)) : 0,
                                id: randomId,
                                data: data,
                                objType: objType
                            });

                            _group.toObject = await (function (toObject) {
                                return function (propertiesToInclude) {
                                    return new window.fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                                        id: randomId,
                                        data: data,
                                        objType: objType
                                    });
                                }
                            })(_group.toObject);

                            // Add the group to the canvas
                            await editor.canvas.add(_group);
                        }

                        await new window.fabric.loadSVGFromURL(window.location.origin + `/${activeItem}.svg`, svgOptions);

                        if (fields?.length > 0) {
                            data = {}
                            fields?.forEach((o, i) => {
                                data[o] = ['', 'set' + o.replace(/\b\w/g, char => char.toUpperCase())];
                            });
                        }
                        // else {
                        //     data = {
                        //         title: ['', 'setTitle'],
                        //         price: [elementPrice, 'setPrice'],
                        //         tickettype: [elementTickettype, 'setTickettype'],
                        //         symbol: [constantWord, 'setSymbol'],
                        //         entrygate: [elementEntrygate, 'setEntrygate'],
                        //     }
                        // }
                    }

                    if (shapeType === 'chair') {
                        // let content = constantWord + _index;
                        let content = "" + _index + "";

                        // Create a custom object (a simple rectangle)
                        let chair = await new window.fabric.Rect({  // Use window.fabric.Rect
                            left: 0,
                            top: 0,
                            width: 20,
                            height: 20,
                            rx: (shapeName === 'circle') ? 40 : 5,
                            ry: (shapeName === 'circle') ? 40 : 5,
                            fill: selectedColor,
                            initFill: selectedColor
                        });

                        // Create a Text object for your text
                        let text = await new window.fabric.Text(content, {
                            left: chair.width / 2, // Adjust the position as needed
                            top: chair.height / 2, // Adjust the position as needed
                            fill: 'white', // Text color
                            fontSize: 12, // Text font size
                            originX: 'center', // Center the text horizontally
                            originY: 'center', // Center the text vertically
                            fontFamily: 'CircularStd-Book'
                        });

                        data = {
                            title: [elementTitle, 'setTitle'],
                            price: [elementPrice, 'setPrice'],
                            tickettype: [elementTickettype, 'setTickettype'],
                            symbol: [constantWord, 'setSymbol'],
                            entrygate: [elementEntrygate, 'setEntrygate'],
                            // ticketsCount: ['', setTicketsCount]
                            rowname: [_index2, 'setRowname'],
                            columnname: [content, 'setColumnname'],
                        }

                        object = [chair, text];
                        objWidth = chair.width;
                        objHeight = chair.height;
                        objType = 'chair';

                        // Group the Rect and Text together
                        let group = await new window.fabric.Group(object, {
                            left: (index > 1) ? ((objWidth + 8) * (index - 1)) + (rowNoObjWidth + objSpacing) : 0 + (rowNoObjWidth + objSpacing),
                            top: (index2 > 1) ? ((objHeight + 8) * (index2 - 1)) : 0,
                            id: randomId,
                            data: data,
                            objType: objType
                        });

                        group.toObject = await (function (toObject) {
                            return function (propertiesToInclude) {
                                return new window.fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                                    id: randomId,
                                    data: data,
                                    objType: objType
                                });
                            }
                        })(group.toObject);

                        // Add the group to the canvas
                        await editor.canvas.add(group);
                    }

                    if (shapeType === 'text') {
                        const text = await new window.fabric.Textbox(
                            'Text Here', {
                            width: 150,
                            fontSize: 18,
                            editable: true,
                            fontFamily: 'CircularStd-Book'
                        });

                        await editor.canvas.add(text);
                    }

                    if (loop) _index++;
                    else _index--;
                }

                if(loop2) _index2++;
                else _index2--;
            }

            // Make sure to render the canvas
            await editor.canvas.requestRenderAll();
            if (canvasItem === 0) await setCanvasItem(1)

            // await editor.canvas.renderAll();

            await autoCanvasSizeAdjust();
        }

        setTimeout(() => {setLoading(false)}, 1000);
    }

    const saveCanvasJson = async () => {
        if (editor) {
            const _canvasJson = await editor.canvas.toJSON();
            const objects = await editor.canvas.getObjects();

            await _canvasJson?.objects?.forEach((o, i) => {
                o.data = objects[i]?.data;
                o.id = objects[i]?.id;
                o.objType = objects[i]?.objType;
                o.isBooked = objects[i]?.isBooked;
                o.isReserved = objects[i]?.isReserved;
                o.isDisabled = objects[i]?.isDisabled;

                if (objects[i]._objects?.length) {
                    objects[i]._objects?.forEach((_o, _i) => {
                        if (_o?.type === 'rect' && o?.objects[_i]?.type === 'rect' && _o?.initFill !== undefined) {
                            o.objects[_i].initFill = _o?.initFill;
                        }
                    })
                }
            });

            setCanvasJson(_canvasJson);
            editor.canvas.clear();
        }
    }

    const loadCanvasJson = async () => {
        if (editor?.canvas) {
            await setScreenTitle('Multiple Seating Chart')
            await editor.canvas.clear(); // Clear the existing canvas
            await editor.canvas.loadFromJSON(canvasJson, () => {
                editor.canvas.renderAll();
            });
        }
    }

    const removeSelectedObject = () => {
        const activeObjects = editor.canvas.getActiveObjects();
        if (activeObjects?.length > 0) {
            activeObjects?.forEach((object, index) => {
                editor.canvas.remove(object);
            });
        }
    }

    const enableSelectedObject = async () => {
        const activeObjects = editor.canvas.getActiveObjects();
        if (activeObjects?.length > 0) {
            await activeObjects?.forEach(async (object, index) => {
                // Update the individual object
                object.isDisabled = false;
                await object?._objects?.forEach(async (_o) => {
                    if (_o.type === 'rect') {
                        _o.set({ fill: _o?.initFill });
                    }
                });
                // Update the canvas JSON
                await editor.canvas.renderAll();
            });
        }
    }

    const disableSelectedObject = async () => {
        const activeObjects = editor.canvas.getActiveObjects();
        if (activeObjects?.length > 0) {
            await activeObjects?.forEach(async (object, index) => {
                // Update the individual object
                object.isDisabled = true;
                await object?._objects?.forEach(async (_o) => {
                    console.log('_o.type', _o.type)
                    if (_o.type === 'rect') {
                        _o.set({ initFill: _o.fill });
                        _o.set({ fill: 'lightgray' });
                    }
                });
                // Update the canvas JSON
                await editor.canvas.renderAll();
            });
        }
    }

    const handleCanvasZoom = (event) => {
        editor.canvas.setZoom(event.target.value);
        setCanvasZoom(event.target.value);
    }

    const handleCanvasZoomIn = () => {
        let findIndex = canvasZoomList.findIndex(x => x.value === canvasZoom);
        let zoomInObj = canvasZoomList[findIndex + 1];

        if (zoomInObj != undefined) {
            editor.canvas.setZoom(zoomInObj?.value);
            setCanvasZoom(zoomInObj?.value);
        }
    }

    const handleCanvasZoomOut = () => {
        let findIndex = canvasZoomList.findIndex(x => x.value === canvasZoom);
        let zoomOutObj = canvasZoomList[findIndex - 1];

        if (zoomOutObj != undefined) {
            editor.canvas.setZoom(zoomOutObj?.value);
            setCanvasZoom(zoomOutObj?.value);
        }
    }

    const handleTicket = (event, object, field) => {
        let _ticket = [...ticket];
        let findTicketIndex = _ticket.findIndex(
            (x) => x.id === object.id
        );

        if (findTicketIndex !== -1) {
            _ticket[findTicketIndex][field] = event.target.value;

            setTicket(_ticket);
        }
    }

    const handleTicketValidation = (object) => {
        let _ticket = [...ticket];
        let findTicketIndex = _ticket.findIndex(
            (x) => x.id === object.id
        );

        if (findTicketIndex !== -1) {
            _ticket[findTicketIndex]['msg']['error'] = [];

            if (parseInt(_ticket[findTicketIndex]['to'] ?? 0) > 0 && parseInt(_ticket[findTicketIndex]['from'] ?? 0) > 0 && parseInt(_ticket[findTicketIndex]['count'] ?? 0) > 0) {
                let range = (parseInt(_ticket[findTicketIndex]['to'] ?? 0) - parseInt(_ticket[findTicketIndex]['from'] ?? 0)) + 1;

                if ((parseInt(_ticket[findTicketIndex]['from']) > parseInt(_ticket[findTicketIndex]['to'])) || range != parseInt(_ticket[findTicketIndex]['count'])) {
                    _ticket[findTicketIndex]['from'] = '';
                    _ticket[findTicketIndex]['to'] = '';
                    _ticket[findTicketIndex]?.msg?.error?.push('Invalid Range');
                }
            }

            setTicket(_ticket);
        }
    }

    const addTicket = () => {
        setTicket([
            ...ticket,
            {
                id: ticket?.length,
                type: "",
                count: "",
                from: "",
                to: "",
                msg: { success: [], error: [] }
            },
        ]);
    }

    const removeTicket = (inputId) => {
        const updatedTicket = [...ticket];
        let filteredTicket = updatedTicket.filter(
            (x) => x.id !== inputId
        );

        setTicket(filteredTicket);
    }

    const updateTheJsonObjAndLoadOnCanvas = async (id, isDoubleClick = false) => {
        const jsonObj = [...json];
        const jsonFindIndex = jsonObj.findIndex(x => x.id === id);

        // if double click
        if (isDoubleClick) {
            if (jsonFindIndex !== -1) {

                if (editor) {
                    await setTimeout(async () => {
                        //load the child element objects
                        // setScreenTitle('')
                        await editor.canvas.clear(); // Clear the existing canvas
                        await editor.canvas.loadFromJSON(jsonObj[jsonFindIndex].json, async () => {
                            await editor.canvas.renderAll();
                        }, (error) => {
                            console.error('Error loading JSON:', error);
                        });
                    }, 1000)
                }

            } else {
                // Copy the existing array into a new variable
                const newArray = await [...jsonObj];

                // Push the object 'obj' into the new array
                await newArray.push({
                    id: id,
                    json: {}
                });

                // Use the setJson function to update the state or value
                await setJson(newArray);
            }

        }

        // if backward click
        else {
            if (editor) {
                const _canvasJson = await editor.canvas.toJSON();
                const objects = await editor.canvas.getObjects();

                await _canvasJson?.objects?.forEach(async (o, i) => {
                    o.data = objects[i]?.data;
                    o.id = objects[i]?.id;
                    o.objType = objects[i]?.objType;
                    o.isBooked = objects[i]?.isBooked;
                    o.isReserved = objects[i]?.isReserved;
                    o.isDisabled = objects[i]?.isDisabled;

                    if (objects[i]._objects?.length) {
                        objects[i]._objects?.forEach((_o, _i) => {
                            if (_o?.type === 'rect' && o?.objects[_i]?.type === 'rect' && _o?.initFill !== undefined) {
                                o.objects[_i].initFill = _o?.initFill;
                            }
                        })
                    }
                })

                jsonObj[jsonFindIndex].json = await _canvasJson;
                await setJson(jsonObj);
            }
        }


    }

    const parentChildScreenEvent = async (event) => {      
        if (editor && editor.canvas && event?.target && event?.target?.objType === 'section') {
            setLoading(true);

            const data = { ...event.target.data }
            if (Object.keys(data).length > 0) await setScreenTitle(data?.title[0])

            // update the json object in the json array
            await setParentElementId(event?.target?.id);
            await updateTheJsonObjAndLoadOnCanvas(event?.target?.id, true);

            // if parent screen
            if (canvasItem === 1) await saveCanvasJson();

            // move to child screen
            await setCanvasItem(2);

            await editor.canvas.renderAll();
            
            await autoCanvasSizeAdjust();

            setTimeout(() => {setLoading(false)}, 1000)
        }
    }

    const enableDoubleClick = () => {
        if (editor) {
            editor.canvas.on({
                'mouse:dblclick': parentChildScreenEvent,
            });
        }
    }

    const disableDoubleClick = () => {
        if (editor) {
            editor.canvas?.off({
                'mouse:dblclick': parentChildScreenEvent,
            });
        }
    }

    const doubleFunc = (event) => {
        if (editor) {
            event.target = editor.canvas?.findTarget(event.srcEvent, true);

            if (event.target && event.target.tapCount === 1) {
                parentChildScreenEvent(event);
            } else {
                editor.canvas?.getObjects().forEach(obj => obj.tapCount = 0);

                if (event.target) {
                    event.target.tapCount += 1;
                }
            }
        }
    }

    const enableDoubleTap = () => {
        hammer?.on('tap', doubleFunc);
    }

    const disableDoubleTap = () => {
        hammer?.off('tap', doubleFunc);
    }

    const handleBackward = async (initScreen = false) => {
        setLoading(true);

        if (initScreen === true) {
            navigate('/seating-template');
        }
        else {
            await updateTheJsonObjAndLoadOnCanvas(parentElementId);

            await loadCanvasJson();

            // move to parent screen
            await setCanvasItem(1);

            await autoCanvasSizeAdjust();
        }

        setTimeout(() => {setLoading(false)}, 1000);
    }

    const publishNow = async () => {
        let chairs = [];

        // // intially save the parent element data
        const _canvasJson = await editor.canvas.toJSON();
        const objects = await editor.canvas.getObjects();
        setIsDisable(true);

        await _canvasJson?.objects?.forEach((o, i) => {

            o.data = objects[i]?.data;
            o.id = objects[i]?.id;
            o.objType = objects[i]?.objType;
            o.isBooked = objects[i]?.isBooked;
            o.isReserved = objects[i]?.isReserved;
            o.isDisabled = objects[i]?.isDisabled;

            if (objects[i]._objects?.length) {
                objects[i]._objects?.forEach((_o, _i) => {
                    if (_o?.type === 'rect' && o?.objects[_i]?.type === 'rect' && _o?.initFill !== undefined) {
                        o.objects[_i].initFill = _o?.initFill;
                    }
                })
            }
        })


        // if (canvasJson?.objects?.length > 0) {
        //     canvasJson?.objects?.forEach((o ,i) => {
        //         if(o?.objType === 'chair') {
        //             chairs.push({
        //                 parentId: null,
        //                 id: o?.id,
        //                 title: o?.data?.title[0],
        //                 isBooked: o?.isBooked ?? false
        //             })
        //         }
        //     });
        // }

        if (canvasItem === 1) {
            let parents = [];
            let Arr = await JSON.parse(JSON.stringify(json));
            let i = await Arr.findIndex(x => x.id === null);
            
            // parent element does not found
            if (i === -1) {
                // so create the parent element object
                await Arr.push({
                    "id": null,
                    "json": _canvasJson
                });
            }

            // else {
                let _i = await Arr.findIndex(x => x.id === null);
                
                if (Arr[_i].id === null) {
                    Arr[_i].json = await _canvasJson;
                    const element = await Arr[_i]?.json?.objects;
                    
                    if (element?.length > 0) {
                        await element?.forEach(async (o, i) => {
                            if (o?.objType === 'section') {
                                await parents.push({
                                    id: o?.id,
                                    title: o?.data?.title[0],
                                    rowReversed,
                                    colReversed,
                                    color: o?.objects[0]?.objects[0]?.fill
                                })
                            }
                        });
                        console.log(parents);
                    }
                }
            // }

            if (Arr?.length > 0) {
                for (let index = 0; index < Arr?.length; index++) {
                    const parentId = Arr[index]?.id;
                    const element = Arr[index]?.json?.objects;

                    if (element?.length > 0) {
                        await element?.forEach((o, i) => {

                            if (o?.objType === 'chair') {
                                chairs.push({
                                    parentId: parents.find(obj => obj["id"] === parentId),
                                    id: o?.id,
                                    title: o?.data?.title[0],
                                    price: o?.data?.price[0],
                                    tickettype: o?.data?.tickettype[0],
                                    symbol: o?.data?.symbol[0],
                                    entrygate: o?.data?.entrygate[0],
                                    rowname: o?.data?.rowname[0],
                                    columnname: o?.data?.columnname[0],
                                    color: o?.objects[0]?.fill,
                                    isBooked: o?.isBooked ?? false,
                                    isReserved: o?.isReserved ?? false,
                                    isDisabled: o?.isDisabled ?? false
                                });
                            }
                        });
                    }
                }
            }

            await setJson(Arr);
            await setChairs(chairs);
            await editor.canvas.renderAll();

            console.log('setJson(Arr)', Arr)
            console.log('setChairs(chairs)', chairs)

            if (stateReplica?.seating) {
                putData("seating_template/" + stateReplica.seating.id, undefined, {
                    event_style: "seating",
                    canvase: "multiple",
                    name: stateReplica?.templateName,
                    template_json: Arr,
                    seats_json: manipulateSeatingPayload(chairs),
                }).then((res) => {
                    if (res.status === 200) {
                        showSuccess("Seating template updated successfully.");
                        getData("seating_template", setSeating);
                        navigate("/seating-template");
                    } else{
                        showError("Something went wrong");
                    }
                }).catch((err) => {
                    showError(err?.response?.data?.error ?? err.message);
                }).finally(() => {
                    setIsDisable(false);
                });
            } else {
                postData("seating_template", undefined, {
                    event_style: "seating",
                    canvase: "multiple",
                    name: stateReplica?.templateName,
                    template_json: Arr,
                    seats_json: manipulateSeatingPayload(chairs),
                }).then((res) => {
                    if (res.status === 200) {
                        showSuccess("Seating template added successfully.");
                        getData("seating_template", setSeating);
                        navigate("/seating-template");
                    } else showError("Something went wrong");
                }).catch((err) => {
                    showError(err?.response?.data?.error ?? err.message);
                }).finally(() => {
                    setIsDisable(false);
                });
            }
        }

        // if(canvasItem === 2) {
        //     // setJson();
        // }
        console.log('canvasZoom', canvasZoom)
    }

    const applySavingInfo = async (fields, Arr) => {
        await Arr.forEach(async (obj) => {
            await obj?.json?.objects?.forEach((o, i) => {
                let findFieldInObj = fields.find(x => x.id === o.id);
                if (findFieldInObj && o.objType === 'chair') {

                    if (findFieldInObj?.isBooked) o.isBooked = findFieldInObj?.isBooked;
                    if (findFieldInObj?.isReserved) o.isReserved = findFieldInObj?.isReserved;
                    if (findFieldInObj?.isDisabled) o.isDisabled = findFieldInObj?.isDisabled;

                    if (o?.objects?.length > 0) {
                        o?.objects?.forEach((_o, _i) => {
                            if (_o?.type === 'rect') {
                                if (o.isBooked) _o.fill = 'grey';
                                else if (o?.isReserved) _o.fill = '#606060';
                                else if (o?.isDisabled) _o.fill = 'lightgray';
                            }
                        });
                    }
                }
            })

            if (obj?.id === null) {
                if (editor) {
                    await setScreenTitle('Multiple Seating Chart')
                    await editor.canvas.clear(); // Clear the existing canvas
                    await editor.canvas.loadFromJSON(obj?.json, () => {
                        editor.canvas.renderAll();
                    });

                    await setCanvasJson(obj?.json);
                }
            }
        });

        await setJson(Arr);
    }

    const countOfBookingSeats = (_fields) => {
        let result = [];
        if (_fields?.length > 0) {
            _fields?.forEach((o, i) => {
                if (o?.isBooked === true) {
                    result.push(o);
                }
            });
        }

        setBookingSeats(result?.length);
    }

    const loadData = async () => {
        const _fields = chairs ? JSON.parse(JSON.stringify(chairs)) : ''; // 
        const _json = json ? JSON.parse(JSON.stringify(json)) : '';

        await applySavingInfo(_fields, _json);
        await countOfBookingSeats(_fields);
        await setCanvasItem(1);
    }

    const canvasMoving = () => {
        if (editor) {
            const canvas = editor.canvas;
        
            let isPanning = false;
            let startPoint = { x: 0, y: 0 };
            let animationFrameId;
        
            const handleMouseMove = _.throttle((event) => {
                if (isPanning) {
                    const currentPoint = canvas?.getPointer(event.e);
                    const deltaX = currentPoint?.x - startPoint.x;
                    const deltaY = currentPoint?.y - startPoint.y;
            
                    canvas.relativePan({ x: deltaX, y: deltaY });
                    animationFrameId = requestAnimationFrame(() =>
                        handleMouseMove(event)
                    );
                }
            }, 32); // Increased interval to 32ms
        
            canvas.on("mouse:down", (event) => {
                isPanning = true;
                startPoint = canvas.getPointer(event.e);
            });
        
            canvas.on("mouse:move", handleMouseMove);
        
            canvas.on("mouse:up", () => {
                isPanning = false;
                // Cleanup animation frame on mouse up
                cancelAnimationFrame(animationFrameId);
            });
        
            return () => {
                // Cleanup or handle component unmounting
                cancelAnimationFrame(animationFrameId);
                canvas.off("mouse:down");
                canvas.off("mouse:move");
                canvas.off("mouse:up");
            };
        }
    }

    const autoCanvasSizeAdjust = async () => {
        let maxWidth = 0;
        let maxHeight = 0;

        if(editor)
        {
            await editor.canvas.getObjects().forEach((obj) => {
                if (obj.left + obj.width > maxWidth) {
                    maxWidth = obj.left + obj.width;
                }
    
                if (obj.top + obj.height > maxHeight) {
                    maxHeight = obj.top + obj.height;
                }
            });
    
            // Adjust canvas dimensions based on maximum dimensions of objects
            await editor.canvas.setDimensions({
                // width: Math.max(maxWidth, editor.canvas.width),
                // height: Math.max(maxHeight, editor.canvas.height)
                width: 4000,
                height: 1500
            });
    
            // Trigger canvas render
            await editor.canvas.requestRenderAll();
        }
    }

    useEffect(() => {
        console.log('loading', loading)
    }, [loading]);

    // used for initialization of canvas
    useEffect(() => {
        loadData();

    }, [editor?.canvas]);

    let myFunctions = {
        setTitle: function (data) {
            setTitle(data)
        },
        setSymbol: function (data) {
            setSymbol(data)
        },
        setTicket: function (data) {
            console.log('data', data)
            setTicket(data)
        },
        setEntrygate: function (data) {
            setEntrygate(data)
        },
        setTicketsCount: function (data) {
            setTicketsCount(data)
        },
        setPrice: function (data) {
            setPrice(data)
        },
        setTickettype: function (data) {
            setTickettype(data)
        },
        setRowname: function (data) {
            setRowname(data)
        },
        setColumnname: function (data) {
            setColumnname(data)
        },
    }

    useEffect(() => {
        let activeObject = editor?.canvas?.getActiveObject();
        let object = activeObject?.data;

        setSelectedObject(object)

        if (!activeObject) {

        }
        else {
            setActiveObjType(activeObject?.objType);

            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    let value = object[key][0];
                    let funcName = object[key][1];
                    let func = myFunctions[funcName];

                    if (typeof func === 'function') {
                        func(value); // Call the function from your object
                    } else {
                        console.log(`Function ${funcName} not found.`);
                    }
                }
            }
        }
    }, [editor?.canvas?.getActiveObject()])

    useEffect(() => {
        const activeObject = editor?.canvas?.getActiveObject();
        const groupToUpdate = editor?.canvas?.getObjects().find(obj => obj.id === activeObject?.id);

        if (groupToUpdate && groupToUpdate.type === 'group') {
            if (groupToUpdate?.data) {
                if (groupToUpdate.data?.title) groupToUpdate.data.title[0] = title;
                if (groupToUpdate.data?.symbol) groupToUpdate.data.symbol[0] = symbol;
                if (groupToUpdate.data?.ticket) groupToUpdate.data.ticket[0] = ticket;
                if (groupToUpdate.data?.entrygate) groupToUpdate.data.entrygate[0] = entrygate;
                if (groupToUpdate.data?.ticketsCount) groupToUpdate.data.ticketsCount[0] = ticketsCount;
                if (groupToUpdate.data?.price) groupToUpdate.data.price[0] = price;
                if (groupToUpdate.data?.tickettype) groupToUpdate.data.tickettype[0] = tickettype;
                if (groupToUpdate.data?.rowname) groupToUpdate.data.rowname[0] = rowname;
                if (groupToUpdate.data?.columnname) groupToUpdate.data.columnname[0] = columnname;
            }

            // Identify the child object within the group (for example, by its type)
            const childObjectToUpdate = groupToUpdate.getObjects().find(obj => obj.type === 'text');

            if (childObjectToUpdate) {
                let content = '';

                if(groupToUpdate.objType === 'chair') {
                    
                    content += columnname;
                }
                else if(groupToUpdate.objType === 'chairRowname') {
                    content += rowname;
                }
                else {
                    content += title;

                    if (activeObject?.data?.symbol && symbol?.length > 0) 
                    {
                        content += ' ' + symbol;
                    }
                }
                
                if (activeObject?.data?.ticket) {
                    if (ticket?.length > 0) {
                        ticket?.forEach((o) => {
                            if (o.count?.length > 0 && o.from?.length > 0 && o.to?.length > 0) {
                                if (parseInt(o.to) > parseInt(o.from)) {
                                    content +=
                                        "\n" + o.count + " Tickets \n" +
                                        "Ticket Type " +
                                        o.type +
                                        "\n" +
                                        "From " +
                                        o.from +
                                        " -" +
                                        " To " +
                                        o.to +
                                        "\n\n";
                                }
                            }
                        });
                    }
                }

                if (activeObject?.data?.ticketsCount && activeObject?.data?.price) {
                    content += ticketsCount + '\n' + 'Price: ' + price + '\n' + tickettype;
                }

                // Modify the child object's properties
                childObjectToUpdate.set({
                    text: content
                });

                // Call renderAll() to apply the changes and update the canvas
                editor?.canvas.renderAll();
            }
        }

    }, [title, symbol, ticket, ticketsCount, price, tickettype, entrygate, rowname, columnname]);
    
    useEffect(() => {
    
        if (canvasItem === 1) {
            enableDoubleClick();
            enableDoubleTap();
        }

        return () => {
            disableDoubleClick();
            disableDoubleTap();
        }

    }, [canvasItem, editor?.canvas])


    return (
        <>
            <div>

                <Sidebar />
                <div className=''>
                    <Header />

                    <div className='page__container_wrapper ticket__page'>
                        <Filters />
                        <div className='seperator'></div>

                        <div className='seatingtemplate__main_wrap'>
                            <div className='tab-header'>
                                <Row>
                                    <Col lg={2}>
                                        <Link to={'/seating-template'} className='nav-link'>Templates</Link>
                                        <a href='#' className={'nav-link active'}>Create New</a>
                                    </Col>
                                    <Col lg={10}>
                                        <div className='screentitle__wrapper'>
                                            <div className='d-flex'>
                                                <div className='d-flex me-auto'>
                                                    <button
                                                        onClick={() => ((canvasItem === 2) ? handleBackward() : handleBackward(true))}
                                                        className='fa fa-arrow-left me-2 bg-transparent border-0'>
                                                    </button>
                                                    <h2>{(screenTitle.length > 0) ? screenTitle : 'Title Here'}</h2>
                                                </div>
                                                <p>Booked Seats: <b>{bookingSeats}</b></p>
                                            </div>
                                            <p>{screenDescription}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className='tab-body'>
                                <div className='create-new'>
                                    <Row>
                                        <Col lg={2}>
                                            <div className='add-area'>
                                                {
                                                    selectedObject?.title &&
                                                    <FormGroup className='mb-4'>
                                                        {
                                                            activeObjType === 'section' ?
                                                                <FormLabel>Floor</FormLabel> :
                                                            activeObjType === 'chair' ?
                                                                <FormLabel>Section name</FormLabel> :
                                                            
                                                            <FormLabel>Title</FormLabel>
                                                        }
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setTitle(event.target.value)}
                                                            value={title}
                                                        />
                                                    </FormGroup>
                                                }
                                                {/* {
                                                    selectedObject?.title && activeObjType === 'section' && (
                                                        <>
                                                            <FormGroup className='mb-4'>
                                                                <input type='checkbox' 
                                                                 checked={rowReversed}
                                                                 onChange={(e)=>setRowReversed(e.target.checked)}
                                                                />
                                                                <FormLabel style={{marginLeft:10}}>Reversed Row</FormLabel> 
                                                            </FormGroup>
                                                            <FormGroup className='mb-4'>
                                                                <input type='checkbox' 
                                                                 checked={colReversed}
                                                                 onChange={(e)=>setcolReversed(e.target.checked)}
                                                                />
                                                                <FormLabel style={{marginLeft:10}}>Reversed Column</FormLabel> 
                                                            </FormGroup>
                                                        </>
                                                    ) 
                                                } */}

                                                {
                                                    selectedObject?.symbol &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Symbol</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setSymbol(event.target.value)}
                                                            value={symbol}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    (selectedObject?.ticket) &&
                                                    <div className="tickettype__container">
                                                        {
                                                            (ticket?.length > 0) &&
                                                            ticket?.map((object, index) => {
                                                                return (
                                                                    <div
                                                                        className="tickettype"
                                                                        key={object.id}
                                                                        id={object.id}
                                                                    >
                                                                        {object.id != 0 && (
                                                                            <div className="seperator mb-4"></div>
                                                                        )}

                                                                        <FormGroup className="mb-4">
                                                                            <div className="d-flex">
                                                                                <FormLabel>Number of Tickets</FormLabel>
                                                                                {object.id != 0 && (
                                                                                    <a
                                                                                        href="#"
                                                                                        title=""
                                                                                        className="text-danger ms-auto text-decoration-none"
                                                                                        onClick={() =>
                                                                                            removeTicket(object.id)
                                                                                        }
                                                                                    >
                                                                                        Delete
                                                                                    </a>
                                                                                )}
                                                                            </div>

                                                                            <input
                                                                                type="number"
                                                                                placeholder=""
                                                                                className="form-control"
                                                                                value={object.count}
                                                                                onChange={(event) =>
                                                                                    handleTicket(
                                                                                        event,
                                                                                        object,
                                                                                        "count"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormGroup>

                                                                        <FormGroup className="mb-4">
                                                                            <FormLabel>Ticket Type</FormLabel>
                                                                            <input
                                                                                type="text"
                                                                                placeholder=""
                                                                                className="form-control"
                                                                                value={object.type}
                                                                                onChange={(event) =>
                                                                                    handleTicket(event, object, "type")
                                                                                }
                                                                            />
                                                                        </FormGroup>

                                                                        <Row>
                                                                            <Col lg={6}>
                                                                                <FormGroup className="mb-4">
                                                                                    <FormLabel>From</FormLabel>
                                                                                    <input
                                                                                        type="number"
                                                                                        placeholder=""
                                                                                        className="form-control"
                                                                                        value={object.from}
                                                                                        onChange={(event) =>
                                                                                            handleTicket(
                                                                                                event,
                                                                                                object,
                                                                                                "from"
                                                                                            )
                                                                                        }
                                                                                        onBlur={() => handleTicketValidation(object)}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col lg={6}>
                                                                                <FormGroup className="mb-4">
                                                                                    <FormLabel>To</FormLabel>
                                                                                    <input
                                                                                        type="number"
                                                                                        placeholder=""
                                                                                        className="form-control"
                                                                                        value={object.to}
                                                                                        onChange={(event) =>
                                                                                            handleTicket(
                                                                                                event,
                                                                                                object,
                                                                                                "to"
                                                                                            )
                                                                                        }
                                                                                        onBlur={() => handleTicketValidation(object)}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                        {
                                                                            (object?.msg?.error?.length > 0)
                                                                                ?
                                                                                object?.msg?.error?.map((o, i) => {
                                                                                    return (
                                                                                        <p key={i} className='text-danger'>{o}</p>
                                                                                    );
                                                                                })
                                                                                :
                                                                                <></>
                                                                        }
                                                                        <hr />
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                        <button
                                                            type="button"
                                                            className="addmore mb-4"
                                                            onClick={addTicket}
                                                        >
                                                            + Add more ticket type
                                                        </button>
                                                    </div>
                                                }

                                                {
                                                    selectedObject?.entrygate &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Entry gate</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setEntrygate(event.target.value)}
                                                            value={entrygate}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.ticketsCount &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Tickets</FormLabel>
                                                        <input
                                                            type="number"
                                                            className='form-control'
                                                            onChange={(event) => setTicketsCount(event.target.value)}
                                                            value={ticketsCount}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.tickettype &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Ticket Type</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setTickettype(event.target.value)}
                                                            value={tickettype}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.price &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Price</FormLabel>
                                                        <input
                                                            type="number"
                                                            className='form-control'
                                                            onChange={(event) => setPrice(event.target.value)}
                                                            value={price}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.rowname &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Row Name</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setRowname(event.target.value)}
                                                            value={rowname}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.columnname &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Column Name</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setColumnname(event.target.value)}
                                                            value={columnname}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    editor?.canvas?.getActiveObject() &&
                                                    <FormGroup className='d-flex align-items-center'>
                                                        <a onClick={enableSelectedObject} disabled={!cropImage} href='#' title='' className='text-secondary me-4 text-decoration-none'>Enable</a>
                                                        <a onClick={disableSelectedObject} disabled={!cropImage} href='#' title='' className='text-secondary me-4 text-decoration-none'>Disable</a>
                                                        <a onClick={removeSelectedObject} disabled={!cropImage} href='#' title='' className='text-danger text-decoration-none'>Delete</a>
                                                    </FormGroup>
                                                }
                                            </div>
                                        </Col>

                                        <Col lg={10}>
                                            <div className='canvas__container'>
                                                {
                                                    (loading) && <Skeleton height={800} borderRadius={25} /> 
                                                }
                                                
                                                <div className={loading && 'd-none'}>
                                                    <div className='canvas__navigation'>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div className='canvas__zoom'>
                                                                    <button className='fa fa-search-plus' onClick={handleCanvasZoomIn}></button>
                                                                    <FormSelect className='canvas__zoomselect' onChange={handleCanvasZoom} value={canvasZoom}>
                                                                        {canvasZoomList.map((event) => (
                                                                            <option key={event.value} value={event.value}>
                                                                                {event.name}
                                                                            </option>
                                                                        ))}
                                                                    </FormSelect>
                                                                    <button className='fa fa-search-minus' onClick={handleCanvasZoomOut}></button>
                                                                </div>
                                                            </Col>
                                                            <Col lg={5}>
                                                                <Row className='flex-row-reverse'>
                                                                    <Col lg={2}>
                                                                        {/* <button onClick={removeSelectedObject} disabled={!cropImage}>Delete</button> */}
                                                                        {/* <button onClick={handleDisableDoubleClick}>Double Click Disable</button> */}
                                                                    </Col>
                                                                    <Col lg={3}>

                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={4} className='d-flex justify-content-end'>
                                                                <Dropdown onClick={() => handleActiveItem([])}>
                                                                    <Dropdown.Toggle
                                                                        variant=" primary"
                                                                        id="dropdown-basic"
                                                                        className='element-dropdown-toggle'
                                                                    >
                                                                        <img className='element-icon' src={window.location.origin + '/chair.svg'} alt="" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu
                                                                        onClick={handleDropdownClick}
                                                                    >
                                                                        <div className='mb-3'>
                                                                            <label className='element-title'>Select Element</label>
                                                                            <Row className='element-container m-0'>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'chair', 'square')} className={'dropdown-item ' + (shapeName === 'square' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/square.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'chair', 'circle')} className={'dropdown-item ' + (shapeName === 'circle' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/circle.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>

                                                                        <div className='mb-3'>
                                                                            <label className='element-title'>Color</label>
                                                                            <CirclePicker
                                                                                className='element-title'
                                                                                colors={['#000', '#ffc627', '#00b3e3', '#9b27ff', '#4949d6', '#4dc978', '#d89e42']}
                                                                                circleSpacing={5}
                                                                                onChange={handleColorChange}
                                                                            />
                                                                        </div>

                                                                        <div className='mb-3'>
                                                                            <Row className=''>
                                                                                <Col lg={12} className='mb-3'>
                                                                                    <input
                                                                                        type='text'
                                                                                        className='form-control'
                                                                                        onChange={(event) => setElementTitle(event.target.value)}
                                                                                        value={elementTitle}
                                                                                        placeholder='Section name'
                                                                                    />
                                                                                </Col>

                                                                                <Col lg={12} className='mb-3'>
                                                                                    <input
                                                                                        type='text'
                                                                                        className='form-control'
                                                                                        onChange={(event) => setElementEntrygate(event.target.value)}
                                                                                        value={elementEntrygate}
                                                                                        placeholder='Entry Gate'
                                                                                    />
                                                                                </Col>

                                                                                <Col lg={6} className='mb-3'>
                                                                                    <input
                                                                                        type='text'
                                                                                        className='form-control'
                                                                                        onChange={(event) => setConstantWord(event.target.value)}
                                                                                        value={constantWord}
                                                                                        placeholder='Symbol'
                                                                                    />
                                                                                </Col>

                                                                                <Col lg={6} className='mb-3'>
                                                                                    <input
                                                                                        type="number"
                                                                                        className='form-control'
                                                                                        onChange={(event) => setElementPrice(event.target.value)}
                                                                                        value={elementPrice}
                                                                                        placeholder='Price'
                                                                                    />
                                                                                </Col>

                                                                                <Col lg={12} className='mb-3'>
                                                                                    <input
                                                                                        type="text"
                                                                                        className='form-control'
                                                                                        value={elementTickettype}
                                                                                        onChange={(e) => setElementTickettype(e.target.value)}
                                                                                        placeholder='Ticket Type'
                                                                                    />
                                                                                </Col>

                                                                                <Col lg={12}>
                                                                                    <label>Column</label>
                                                                                </Col>
                                                                                <Col lg={6} className='mb-3'>
                                                                                    <input
                                                                                        type='number'
                                                                                        className='form-control'
                                                                                        onChange={(event) => setStartFrom(event.target.value)}
                                                                                        value={startFrom}
                                                                                        placeholder='Start'
                                                                                    />
                                                                                </Col>
                                                                                <Col lg={6} className='mb-3'>
                                                                                    <input
                                                                                        type='number'
                                                                                        className='form-control'
                                                                                        onChange={(event) => setEndOn(event.target.value)}
                                                                                        value={endOn}
                                                                                        placeholder='End'
                                                                                    />
                                                                                </Col>

                                                                                <Col lg={12}>
                                                                                    <label>Row</label>
                                                                                </Col>
                                                                                <Col lg={6} className='mb-3'>
                                                                                    <input
                                                                                        type='number'
                                                                                        className='form-control'
                                                                                        onChange={(event) => setColumnStartFrom(event.target.value)}
                                                                                        value={columnStartFrom}
                                                                                        placeholder='Start'
                                                                                    />
                                                                                </Col>
                                                                                <Col lg={6} className='mb-3'>
                                                                                    <input
                                                                                        type='number'
                                                                                        className='form-control'
                                                                                        onChange={(event) => setColumnEndOn(event.target.value)}
                                                                                        value={columnEndOn}
                                                                                        placeholder='End'
                                                                                    />
                                                                                </Col>

                                                                            </Row>
                                                                        </div>

                                                                        <div className='py-3 d-flex justify-content-center'>
                                                                            <Button onClick={createCustomObject} className='btn btn-theme'>Add Chair</Button>
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                                <Dropdown onClick={() => handleActiveItem([])}>
                                                                    <Dropdown.Toggle
                                                                        variant=" primary"
                                                                        id="dropdown-basic"
                                                                        className='element-dropdown-toggle'>

                                                                        <img className='element-icon' src={window.location.origin + '/table.svg'} alt="" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu
                                                                        onClick={handleDropdownClick}
                                                                    >
                                                                        <div className='mb-3'>
                                                                            <label className='element-title'>Select Element</label>
                                                                            <Row className='element-container m-0'>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem(['title'], 'image', 'square-strok', true)} className={'dropdown-item ' + (activeItem === 'square-strok' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/square-strok.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem(['title'], 'image', 'rectangle-strok', true)} className={'dropdown-item ' + (activeItem === 'rectangle-strok' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/rectangle-strok.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem(['title'], 'image', 'circle-strok', true)} className={'dropdown-item ' + (activeItem === 'circle-strok' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/circle-strok.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem(['title'], 'image', 'oval-strok', true)} className={'dropdown-item ' + (activeItem === 'oval-strok' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/oval-strok.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem(['title'], 'image', 'triangle-strok', true)} className={'dropdown-item ' + (activeItem === 'triangle-strok' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/triangle-strok.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem(['title'], 'image', 'pentagon-strok', true)} className={'dropdown-item ' + (activeItem === 'pentagon-strok' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/pentagon-strok.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <div className='mb-3'>
                                                                            <label className='element-title'>Color</label>
                                                                            <CirclePicker
                                                                                className='element-title'
                                                                                colors={['#000', '#ffc627', '#00b3e3', '#9b27ff', '#4949d6', '#4dc978', '#d89e42']}
                                                                                circleSpacing={5}
                                                                                onChange={handleColorChange}
                                                                            />
                                                                        </div>

                                                                        <div className='py-3 d-flex justify-content-center'>
                                                                            <Button onClick={createCustomObject} className='btn btn-theme'>Add Section</Button>
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                                <Dropdown onClick={() => handleActiveItem(['title', 'symbol', 'ticket'], 'image', 'square-strok', false)}>
                                                                    <Dropdown.Toggle
                                                                        variant=" primary"
                                                                        id="dropdown-basic"
                                                                        className='element-dropdown-toggle'
                                                                    >
                                                                        <img className='element-icon' src={window.location.origin + '/standingarea.svg'} alt="" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu
                                                                        onClick={handleDropdownClick}
                                                                    >
                                                                        <div className='mb-3'>
                                                                            <label className='element-title'>Color</label>
                                                                            <CirclePicker
                                                                                className='element-title'
                                                                                colors={['#000', '#ffc627', '#00b3e3', '#9b27ff', '#4949d6', '#4dc978', '#d89e42']}
                                                                                circleSpacing={5}
                                                                                onChange={handleColorChange}
                                                                            />
                                                                        </div>



                                                                        <div className='py-3 d-flex justify-content-center'>
                                                                            <Button onClick={createCustomObject} className='btn btn-theme'>Add</Button>
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                                <Dropdown onClick={() => handleActiveItem([])}>
                                                                    <label className='ms-2 me-2'>Elements:</label>
                                                                    <Dropdown.Toggle
                                                                        variant=" primary"
                                                                        id="dropdown-basic"
                                                                        className='p-0 border-0'>

                                                                        <img className='element-icon' src={window.location.origin + '/dropdownbutton.svg'} alt="" />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu
                                                                        onClick={handleDropdownClick}
                                                                    >
                                                                        <div className='mb-3'>
                                                                            <label className='element-title'>Select Element</label>
                                                                            <Row className='element-container m-0'>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'door')} className={'dropdown-item ' + (activeItem === 'door' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/door.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'gate')} className={'dropdown-item ' + (activeItem === 'gate' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/gate.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'glasses')} className={'dropdown-item ' + (activeItem === 'glasses' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/glasses.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'stage')} className={'dropdown-item ' + (activeItem === 'stage' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/stage.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'crockery')} className={'dropdown-item ' + (activeItem === 'crockery' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/crockery.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>

                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'text')} className={'dropdown-item ' + (activeItem === 'text' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/text.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'circle-fill2')} className={'dropdown-item ' + (activeItem === 'circle-fill2' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/circle-fill2.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'oval')} className={'dropdown-item ' + (activeItem === 'oval' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/oval.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'square')} className={'dropdown-item ' + (activeItem === 'square' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/square.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'rectangle')} className={'dropdown-item ' + (activeItem === 'rectangle' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/rectangle.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>

                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'triangle')} className={'dropdown-item ' + (activeItem === 'triangle' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/triangle.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'pentagon')} className={'dropdown-item ' + (activeItem === 'pentagon' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/pentagon.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                                <Col className='p-0'>
                                                                                    <Button onClick={() => handleActiveItem([], 'image', 'leftarrow')} className={'dropdown-item ' + (activeItem === 'leftarrow' ? 'activeItem' : '')}>
                                                                                        <img className='element-icon' src={window.location.origin + '/leftarrow.svg'} alt="" />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                        <div className='mb-3'>
                                                                            <label className='element-title'>Color</label>
                                                                            <CirclePicker
                                                                                className='element-title'
                                                                                colors={['#000', '#ffc627', '#00b3e3', '#9b27ff', '#4949d6', '#4dc978', '#d89e42']}
                                                                                circleSpacing={5}
                                                                                onChange={handleColorChange}
                                                                            />
                                                                        </div>

                                                                        <div className='py-3 d-flex justify-content-center'>
                                                                            <Button onClick={createCustomObject} className='btn btn-theme'>Add Element</Button>
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                    <div className='canvas__wrapper'>
                                                        <FabricJSCanvas
                                                            className="canvas"
                                                            onReady={onReady}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {
                                    (canvasItem === 1 && !loading) ?
                                        <div>
                                            <div>
                                                <div className='seperator my-4'></div>
                                            </div>

                                            <Row>
                                                <Col lg={2}></Col>
                                                <Col lg={10}>
                                                    <FormGroup className='d-flex align-items-center justify-content-end'>
                                                        <Button
                                                            className={`btn btn-theme px-4 ${isDisable ? 'disabled' : ''}`}
                                                            onClick={isDisable ? null : publishNow}
                                                            disabled={isDisable}
                                                        >
                                                            {
                                                            isDisable ?
                                                                'Publishing...'
                                                                :
                                                                'Publish Now'
                                                            }
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MultipleSeating;