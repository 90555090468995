import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../dashboard/header";
import Horizontalchart from "../dashboard/chart";
import Horizontalchart2 from "../dashboard/chart2";
import Card from "../dashboard/card";
import TableComponent from "../table/table";
import Sidebar from "../leftsidebar/sidebar";
import CardNav from "../Cardbar/cardnav";
import { getData, postData } from "../../../services/data";
import { setInvoices } from "../../../store/slices/invoice";
import TableEvents from "../table/TableEvents";
import { setEvents } from "../../../store/slices/event";
const Home = () => {
  const [event, setEvent] = useState(null);
  const [data, setData] = useState();

  const orders = useSelector((state) => state.invoice.data);
  const eventsTableData = useSelector((state) => state.event.data);

  const getordersHandler = () => {
    const payload = {
      page: 1,
      pageSize: 10,
      event_id: event !== 0 && event,
    };
    postData("invoices/invoices_filter", setInvoices, payload);
  };

  useEffect(() => {
    getData(`/events/dashboard/${event || 0}`).then((res) => setData(res.data));
    getordersHandler();
    getData("events/admin", setEvents);
  }, [event]);

  return (
    <>
      <Sidebar />
      <div className="main__container_appbody_wrap">
        <div className="appbody__main_container">
          <Header />
          <div className="appbody__content_container">
            <div className="appbody__card_wrap">
              <Card data={data} setEvent={setEvent} />
            </div>
            <div className="appbody__chart_wrap">
              <div className="appbody__chart_left_col">
                <Horizontalchart data={data?.countByMonth} />
              </div>
              <div className="appbody__chart_right_col">
                <Horizontalchart2 data={data?.sumByMonth} />
              </div>
            </div>
            <div className="appbody__table_wrap homePage">
              {event ? (
                <TableComponent
                  orders={orders?.invoices?.filter(
                    (i) => i.event_id?.toString() === event?.toString()
                  )}
                  title="Recent Order"
                />
              ) : (
                <TableComponent
                  orders={orders?.invoices}
                  title="Recent Order"
                />
              )}
            </div>
            <div className="appbody__table_wrap homePage">
              {!event && (
                <TableEvents orders={eventsTableData} title="Current Events" />
              )}
            </div>
          </div>
        </div>
        <CardNav events={data?.eventsWithImages} />
      </div>
    </>
  );
};

export default Home;
