import { Outlet } from "react-router-dom";
import ProtectedPage from "../../components/pages/protectedpage";

const RoleBasedRoutes = ({ role, user }) => {
    // if (user.roles?.find(userRole => userRole.name === role)) {
    //     return <Outlet />;
    // }
    if (user?.roles?.some(userRole => role.includes(userRole.name))) {
        return <Outlet />;
    }
    return <ProtectedPage />;
}

export default RoleBasedRoutes;