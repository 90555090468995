import React from 'react';
import Sidebar from '../leftsidebar/sidebar';
import Header from '../header/headerInner';
import './filter.css';
import './ticketPrint.css';
import printLogo from './printLogo.png';
import qr from './qr.JPG';
import logoWrap from './logowrap.JPG';

const Ticket = () => {
  const tickets = {
    "data": [
      {
        "eventTitle": "Junkanoo Boxing Day Parade",
        "eventType": "Boxing Day",
        "location": "The Bahamas Nursing Council, Nassau, The Bahamas.",
        "date": "02 Sep 2023",
        "time": "09:00 AM",
        "entryGate": "Bay Street Gate",
        "tickets" :
        [
            {
                "ticketNo": "J-87609263",
                "type" : "Standard",
                "section": "A",
                "row": "02",
                "seats": "11",
            },
            {
                "ticketNo": "J-87609263",
                "type" : "Standard",
                "section": "A",
                "row": "02",
                "seats": "11",
            },
        ]
      }
    ]
  };

  return (
    <div>
      <Sidebar />

      <div className="">
        <Header />
        <div className='page__container_wrapper ticket__page'>
          {tickets.data.map((ticket, index) => (
            <div className='ticket__print_wrap' key={index}>
              <div className='ticket__print_wrap_header'>
                <img src={printLogo} alt='-' />
                <div className='ticket__print_wrap_header_title'>
                  {ticket.eventTitle}
                </div>
              </div>

              <div className='ticket__print_wrap_body'>
                <div className='ticket__print_wrap_body_ticket_info'>
                  <div className='ticket__print_wrap_body_left_cont'>
                    <div className='ticket__print_wrap_body_left_cont_list'>
                      <div className='ticket__print_wrap_cont_list_label'>
                        Event Type
                      </div>
                      <div className='ticket__print_wrap_cont_list_text'>
                        {ticket.eventType}
                      </div>
                    </div>
                    <div className='ticket__print_wrap_body_left_cont_list'>
                      <div className='ticket__print_wrap_cont_list_label'>
                        Location
                      </div>
                      <div className='ticket__print_wrap_cont_list_text'>
                        {ticket.location}
                      </div>
                    </div>
                    <div className='ticket__print_wrap_body_left_cont_list'>
                      <div className='ticket__print_wrap_cont_list_label'>
                        Date & Time
                      </div>
                      <div className='ticket__print_wrap_cont_list_text'>
                        {ticket.date} | {ticket.time}
                      </div>
                    </div>
                    <div className='ticket__print_wrap_body_left_cont_list'>
                      <div className='ticket__print_wrap_cont_list_label'>
                        Entry Gate
                      </div>
                      <div className='ticket__print_wrap_cont_list_text'>
                        {ticket.entryGate}
                      </div>
                    </div>
                  </div>
                  <div className='ticket__print_wrap_body_right_cont'>
                    <div className='ticket__print_wrap_body_right_cont_qr'>
                      <img src={qr} alt='-' />
                    </div>
                    <div className='ticket__print_wrap_body_right_cont_text'>
                      Show this at gate
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="table-container-ticket-print">
            <table className="data-table-ticket-print" border="0">
              <thead>
                <tr>
                  <th>Ticket No.</th>
                  <th>Type</th>
                  <th>Section</th>
                  <th>Row</th>
                  <th>Seat</th>
                </tr>
              </thead>
              <tbody>
                {tickets.data[0].tickets.map((ticket, index) => (
                  <tr key={index}>
                    <td>{ticket.ticketNo}</td>
                    <td>{ticket.type}</td>
                    <td>{ticket.section}</td>
                    <td>{ticket.row}</td>
                    <td>{ticket.seats}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


<div className='ticket__print_sponser_main_wrap'>
    <div className='ticket__print_sponser_title'>
    Sponsors
    </div>
                    <div className='ticket__print_sponser_logo'>
                        <img src={logoWrap} alt='-'/>
                    </div>
</div>


<div className='ticket__print_terms_content_wrap'>
    <div className='ticket__print_terms_content_title'>
    Terms & Conditions
    </div>
    <div className='ticket__print_terms_content'>

    <p>To access your tickets, go to the Tickets Wallet in the Aliv Event app and in the current tickets tap view all available Tickets on the corresponding event you are going to. The Aliv Event application is your ticket and there is no printing required. </p>
    
    <p>At the venue, show your ticket to the appropriate person for scanning and validation. If you have purchased the ticket from the store and received the ticket on your email (just like attachmnent with this email) you can show this picture at the venue for scanning. </p>
    
    <p> <strong>Note:</strong> Please don’t share your tickets with anyone. Aliv will not be responsible if you have shared the ticket with anyone else and they got it scaned and entered in the event before you.</p>
    
    </div>

</div>


        </div>
      </div>
    </div>
  );
};

export default Ticket;
