import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
};

export const adjustment = createSlice({
    name: "adjustment",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAdjustments: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setLoading, setAdjustments } = adjustment.actions;

export default adjustment.reducer;
