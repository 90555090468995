import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import { postData } from "../../../services/data";

const ForgotNumber = ({ nextStepHandler }) => {
  const initialValues = {
    number: "",
  };

  const validationSchema = Yup.object().shape({
    number: Yup.string().required("Phone number is required"),
  });

  const handleSubmit = (values) => {
    const payload = { phone: values.number.slice(1) };
    postData("users/send_forgot_password_otp", undefined, payload).then(
      (response) => {
        if (response.status === 200) {
          nextStepHandler(payload);
        }
      }
    );
    // Handle login logic here
    // dispatch(login(true))
    // navigate(SENDRESETOTP);
    // setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {(props) => {
        const { values, handleChange, handleBlur } = props;
        return (
          <Form>
            <div className="fieldWrap">
              <label>Phone number</label>
              <PhoneInput
                initialValueFormat="national"
                international
                defaultCountry="BS"
                className="phone-input"
                placeholder="Enter Phone number"
                value={values.number}
                onChange={(val) => {
                  handleChange({
                    target: {
                      name: "number",
                      value: val ?? "",
                    },
                  });
                }}
                onBlur={() =>
                  handleBlur({
                    target: {
                      name: "number",
                      value: values.number,
                    },
                  })
                }
              />
              <ErrorMessage
                className="errorMessage"
                name="number"
                component="div"
              />
            </div>
            <div className="fieldWrap fieldOnFooter">
              <button className="submitButton" type="submit">
                Submit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotNumber;
