import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
};

export const marketingSlice = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNotifications: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setLoading, setNotifications } = marketingSlice.actions;

export default marketingSlice.reducer;
