import React, { useState, useEffect } from "react";
import Filters from "./filter";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import OrderDetails from "./OrderDetails";
import { getData, postData } from "../../../services/data";
import { setInvoices } from "../../../store/slices/invoice";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import "./order.css";

const Order = () => {
  const dispatch = useDispatch();
  const [isAdmin, setAdmin] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [search, setSearch] = useState("");

  const [paymentTypeFilter, setPaymentTypeFilter] = useState(null);
  const [eventNameFilter, setEventNameFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);

  const invoices = useSelector((state) => state.invoice.data);
  const userDetails = useSelector((state) => state.user.data);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPage, setMaxPage] = useState(1);

  const getordersHandler = (search) => {
    const payload = {
      page: page,
      pageSize: pageSize,
      payment_type:
        paymentTypeFilter === null || paymentTypeFilter === "All"
          ? null
          : paymentTypeFilter,
      date: dateFilter === null ? null : dateFilter,
      status:
        statusFilter === null || statusFilter === "All" ? null : statusFilter,
      event_id:
        eventNameFilter === null || eventNameFilter === "All"
          ? null
          : eventNameFilter,
    };
    if (search) {
      payload["search"] = search;
    }
    postData("invoices/invoices_filter", setInvoices, payload).then((res) => {
      dispatch(setInvoices(res.data));
      setMaxPage(res.data.totalPages);
    });
  };

  useEffect(() => {
    getordersHandler();
  }, [page, paymentTypeFilter, eventNameFilter, statusFilter, dateFilter]);

  useEffect(() => {
    if (search.length === 0) {
      getordersHandler();
      return;
    }
    if (search.length > 4) {
      getordersHandler(search);
    }
  }, [search]);

  useEffect(() => {
    getData("users/get_user/" + userDetails?.id).then((res) => {
      res.data?.roles?.forEach((role) => {
        if (role.name === "admin") setAdmin(true);
      });
    });
  }, [userDetails]);

  const toggleOptions = (index) => {
    setShowOptions(!showOptions);
    setSelectedRowIndex(index);
  };

  const toggleOrderDetails = (order) => {
    const obj = {
      ...order,
      cash_vendor: order?.vendor
        ? `${order?.vendor?.first_name ?? ""} ${order?.vendor?.last_name ?? ""}`
        : "=",
      customerName: `${order?.creator?.first_name ?? ""} ${
        order?.creator?.last_name ?? ""
      }`,
      customerPhone: order?.creator ? `+${order?.creator?.phone}` : "",
      eventName: order?.Event?.name,
    };
    setSelectedOrder(obj);
    setShowOrderDetails(!showOrderDetails);
    toggleOptions();
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header search={search} setSearch={setSearch} />
        <div className="page__container_wrapper ticket__page">
          {showOrderDetails && selectedOrder ? (
            <OrderDetails
              order={selectedOrder}
              onClose={() => toggleOrderDetails(null)}
            />
          ) : (
            <div className="table-container">
              <Filters
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                paymentTypeFilter={paymentTypeFilter}
                setPaymentTypeFilter={setPaymentTypeFilter}
                eventNameFilter={eventNameFilter}
                setEventNameFilter={setEventNameFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
              />
              <table className="data-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Status</th>
                    <th>Event Name</th>
                    <th>Customer </th>
                    <th>Phone No.</th>
                    <th>Discount</th>
                    <th>Paid</th>
                    <th>Gateway</th>
                    <th>Cash Vendor</th>
                    {isAdmin && <th>Order status</th>}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {invoices?.invoices?.length === 0 ? (
                    <tr>
                      <td colSpan={10} className="text-center">
                        No Result Found
                      </td>
                    </tr>
                  ) : (
                    invoices?.invoices?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {(page == 1 ? 0 : (page - 1) * 10) + (index + 1)}
                        </td>
                        <td>{item.invoice_no}</td>
                        <td>
                          {new Date(item.created_at).toLocaleDateString(
                            "en-US"
                          )}
                        </td>
                        <td>
                          {item.is_paid
                            ? "Paid"
                            : item.is_reserved
                            ? "Reserved"
                            : "Pending"}
                        </td>
                        <td>{item?.Event?.name}</td>
                        <td>
                          {item?.creator?.first_name} {item?.creator?.last_name}
                        </td>
                        <td>
                          {item?.creator ? `+${item?.creator?.phone}` : ""}
                        </td>
                        <td>{item.discount_amount}</td>
                        <td>{item.total}</td>
                        <td>{item.payment_type}</td>
                        <td>
                          {item.vendor?.first_name} {item?.vendor?.last_name}
                        </td>
                        {isAdmin && (
                          <td>{item.is_cancelled ? "Cancelled" : "Active"}</td>
                        )}
                        <td>
                          <div className="userCreation__main_table_options">
                            <div
                              className="userCreation__main_table_options__button"
                              onClick={() => toggleOptions(index)}
                            >
                              <i className="fas fa-ellipsis-v"></i>
                            </div>
                            {showOptions && selectedRowIndex === index && (
                              <div className="userCreation__main_table_menu_dropdown">
                                <ul className="userCreation__main_table_menu_options">
                                  <li onClick={() => toggleOrderDetails(item)}>
                                    View
                                  </li>
                                  {/* <li >Delete</li> */}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <ReactPaginate
                breakLabel="..."
                className="paginationBot"
                nextLabel=">"
                onPageChange={(e) => setPage(e.selected + 1)}
                pageRangeDisplayed={15}
                pageCount={maxPage}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Order;
