import React, { useEffect, useState } from "react";
import profileImage from "../header/profile.jpg";
import TickImg from "./checkTick.png";
import "./OrderSummary.css";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getData, postData } from "../../../services/data";
import { setDiscounts } from "../../../store/slices/discount";
import {
  calculateTotalAfterAction,
  emptyCart,
  removeFromCart,
  removeFromCartNoSeating,
  setCustomer,
  setDiscount,
} from "../../../store/slices/cart";
import { showError, showSuccess } from "../../../helpers/toast";
import { setInvoices } from "../../../store/slices/invoice";
import HeaderInner from "../cashheader/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import { useNavigate } from "react-router-dom";

const OrderSummaryData = () => {
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const cart = useSelector((state) => state.cart);
  const events = useSelector((state) => state.event.data);
  const user = useSelector((state) => state.user.data);
  const discounts = useSelector((state) => state.discount.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [processingAJAX, setProcessingAJAX] = useState(false);
  useEffect(() => {
    getData("discount_code", setDiscounts);
  }, []);

  const toggleOrderSummary = () => {
    if (showOrderSummary) {
      dispatch(
        calculateTotalAfterAction({
          action: emptyCart,
        })
      );
    }
    setShowOrderSummary(!showOrderSummary);
    dispatch(setCustomer(""));
    navigate("/cash");
    getData("invoices", setInvoices);
  };
  const seatingInvoiceHandler = () => {
    postData("invoices", undefined, {
      event_id: cart.eventId,
      is_paid: true,
      is_paid_status_code: 1,
      tax_percentage: cart.eventVat,
      sub_total: parseFloat(cart.subTotal).toFixed(2),
      tax: parseFloat(cart.vat).toFixed(2),
      total: parseFloat(
        cart.subTotal + cart.vat - cart.discount_amount
      ).toFixed(2),
      created_by: cart.customer.id,
      payment_type: "cash",
      chairs: cart.items.map((item) => item.ticket),
      payment_gateway_response: "Payment successful",
      discount_id: cart.discount.id ?? null,
      discount_amount: parseFloat(cart.discount_amount).toFixed(2) ?? 0,
      is_discount: !!cart.discount?.id,
      cash_vendor: user.id,
    })
      .then((res) => {
        if (res.status !== 201) {
          showError(res?.message);
          setProcessingAJAX(false);
        } else {
          setShowOrderSummary(!showOrderSummary);
          setProcessingAJAX(false);
        }
      })
      .catch((err) => {
        showError(err.response?.data?.error ?? err?.message);
        setProcessingAJAX(false);
      });
  };
  const nonSeatingInvoiceHandler = () => {
    const tickets = [];
    cart.items.forEach((item) => {
      const isExsist = tickets.findIndex((t) => t.id === item.symbolId);
      if (isExsist >= 0) {
        tickets[isExsist].ticket_count++;
        return;
      }
      tickets.push({
        id: item.symbolId,
        ticket_count: 1,
      });
    });
    postData("invoices/no-seating", undefined, {
      event_id: cart.eventId,
      is_paid: true,
      is_paid_status_code: 1,
      tax_percentage: cart.eventVat,
      sub_total: parseFloat(cart.subTotal).toFixed(2),
      tax: parseFloat(cart.vat).toFixed(2),
      total: parseFloat(
        cart.subTotal + cart.vat - cart.discount_amount
      ).toFixed(2),
      created_by: cart.customer.id,
      payment_type: "cash",
      tickets: tickets,
      payment_gateway_response: "Payment successful",
      discount_id: cart.discount.id ?? null,
      discount_amount: parseFloat(cart.discount_amount).toFixed(2) ?? 0,
      is_discount: !!cart.discount?.id,
      cash_vendor: user.id,
    })
      .then((res) => {
        if (res.status !== 201) {
          showError(res?.message);
          setProcessingAJAX(false);
        } else {
          setShowOrderSummary(!showOrderSummary);
          setProcessingAJAX(false);
        }
      })
      .catch((err) => {
        showError(err.response?.data?.error ?? err?.message);
        setProcessingAJAX(false);
      });
  };

  const handleSubmitCart = () => {
    setProcessingAJAX(true);
    if (cart.items[0].eventStyle === "no seating") {
      nonSeatingInvoiceHandler();
      return;
    }
    seatingInvoiceHandler();
  };

  const handelCancel = () => {
    dispatch(
      calculateTotalAfterAction({
        action: emptyCart,
      })
    );
    dispatch(setCustomer(""));
    navigate("/cash");
  };

  const removeFromCartHandler = (item) => {
    if (item.eventStyle === "no seating") {
      dispatch(
        calculateTotalAfterAction({
          action: removeFromCartNoSeating,
          data: item.symbolId,
        })
      );
      return;
    }
    dispatch(
      calculateTotalAfterAction({
        action: removeFromCart,
        data: item.ticket,
      })
    );
  };

  return (
    <div className="cash__vander_main">
      <HeaderInner />
      <Sidebar />
      <div className="page__container_wrapper">
        <div className="order__detaials_main_wrap">
          <div className="order__detaials_header">
            <h2>Order Summary</h2>
          </div>
          <div className="order-details">
            <div className="user__orderDetails_wrap">
              <div className="user__detail_col_box">
                <div className="user_img_wrap">
                  <img
                    className="profile-picture"
                    src={profileImage}
                    alt="pic"
                  />
                </div>

                <ul className="user__orderDetails_list">
                  <li>
                    Order ID: <strong>-</strong>
                  </li>
                  <li>
                    Order Date:{" "}
                    <strong>
                      {format(new Date(Date.now()), "yyyy-MM-dd")}
                    </strong>{" "}
                  </li>
                  <li>
                    Customer Name:{" "}
                    <strong>
                      {cart.customer.first_name + " " + cart.customer.last_name}
                    </strong>{" "}
                  </li>
                  <li>
                    Phone Number: <strong>{cart.customer.phone}</strong>{" "}
                  </li>
                  <li>
                    No. Of Tickets: <strong>{cart.items.length}</strong>{" "}
                  </li>
                  <li>
                    Gateway: <strong>Cash</strong>{" "}
                  </li>
                  <li>
                    Event Name:{" "}
                    <strong>
                      {events.find((e) => e.id === cart.eventId)?.name}
                    </strong>
                  </li>
                  <li>
                    Cash Vendor:{" "}
                    <strong>{user.first_name + " " + user.last_name}</strong>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="tickets__details_wrap">
              <div className="header__main_box">
                <h2>Ticket Details</h2>{" "}
              </div>

              <table>
                <thead>
                  <th>
                    <strong>#</strong>
                  </th>
                  <th>
                    <strong>Seat No.</strong>
                  </th>
                  <th>
                    <strong>Type</strong>
                  </th>
                  <th>
                    <strong>Section</strong>
                  </th>
                  <th>
                    <strong>Row</strong>
                  </th>
                  <th>
                    <strong>Seat</strong>
                  </th>
                  <th>
                    <strong>Price</strong>
                  </th>
                  <th>
                    <strong> </strong>
                  </th>
                </thead>
                <tbody>
                  {cart.items.map((ticket, index) => (
                    <tr key={"CARTITEMS" + index}>
                      <td>{index + 1}</td>

                      <td>
                        {ticket?.eventStyle === "no seating"
                          ? "-"
                          : ticket.ticket}
                      </td>
                      <td>
                        {ticket.type ?? "-"}
                      </td>
                      <td>{ticket.symbol}</td>
                      <td>
                        {ticket?.eventStyle === "no seating" ? "-" : ticket.row}
                      </td>
                      <td>
                        {ticket?.eventStyle === "no seating"
                          ? "-"
                          : ticket.seat}
                      </td>
                      <td>{ticket.price}</td>
                      <td>
                        <div
                          className="close__delete"
                          onClick={() => removeFromCartHandler(ticket)}>
                          +
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="ticket__detail_footer">
                <div className="btn_box_col">
                  <div className="heading-container">
                    <h2 className="cash-heading ordSum">Select Discount</h2>
                    <select
                      className="select-container form-select"
                      value={cart.discount.id}
                      onChange={(e) => {
                        if (
                          parseFloat(
                            discounts.find(
                              (d) => d.id.toString() === e.target.value
                            )?.minValue
                          ) <
                          cart.subTotal + cart.vat
                        ) {
                          dispatch(
                            calculateTotalAfterAction({
                              action: setDiscount,
                              data: discounts.find(
                                (d) => d.id.toString() === e.target.value
                              ),
                            })
                          );
                        } else {
                          showError(
                            "Minimum amount required to apply discount is " +
                              discounts.find(
                                (d) => d.id.toString() === e.target.value
                              )?.minValue
                          );
                        }
                      }}>
                      <option value="">Select Discount</option>
                      {discounts
                        .filter(
                          (d) =>
                            d.event_id?.toString() === cart.eventId?.toString()
                        )
                        .map((d) => (
                          <option key={"DISC" + d.id} value={d.id}>
                            {d.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="total__cal_col">
                  <ul>
                    <li>
                      Subtotal{" "}
                      <strong>${parseFloat(cart.subTotal).toFixed(2)}</strong>
                    </li>
                    <li>
                      Discount{" "}
                      <strong>
                        ${parseFloat(cart.discount_amount).toFixed(2)}
                      </strong>
                    </li>
                    <li>
                      VAT <strong>${parseFloat(cart.vat).toFixed(2)}</strong>
                    </li>
                    <li>
                      Total Amount{" "}
                      <strong>
                        $
                        {parseFloat(
                          cart.subTotal + cart.vat - cart.discount_amount
                        ).toFixed(2)}
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ticket__detail_footer">
                <div className="btn_box_col">
                  {/* <div className='btn_box_btn_print_ticket'>Edit Tickets</div> */}
                </div>
                <div className="total__cal_col">
                  <div className="event_btn_right">
                    <button
                      className={`save__bt_cancle ${
                        processingAJAX ? "disabledButton" : ""
                      }`}
                      onClick={handelCancel}>
                      Cancel
                    </button>
                    {cart.items.length < 1 ? (
                      <button
                        className={`save__bt_publish disabledButton`}
                        onClick={handleSubmitCart}>
                        Proceed
                      </button>
                    ) : (
                      <button
                        className={`save__bt_publish ${
                          processingAJAX ? "disabledButton" : ""
                        }`}
                        onClick={handleSubmitCart}>
                        Proceed
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showOrderSummary && (
            <div className="orderSummary__pop_detail">
              <div
                className="orderSummary__pop_detail_overlay"
                onClick={toggleOrderSummary}></div>
              <div className="orderSummary__pop_detail__container">
                <div className="orderSummary__pop_detail_header">
                  <img src={TickImg} alt="-" />
                  <h3>Congratulations</h3>
                  <p>
                    Your tickets have been booked. Please check your email and
                    show it at the time of checkin
                  </p>
                </div>
                <div className="orderSummary__pop_detail_order_cost">
                  <div className="orderSummary__pop_detail_order_costtitle">
                    Order Summary
                  </div>
                  <div className="orderSummary__pop_detail_order_costprice">
                    Amount Received{" "}
                    <span>
                      $
                      {parseFloat(
                        cart.subTotal + cart.vat - cart.discount_amount
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="orderSummary__pop_detail_table_list">
                  <table>
                    <thead>
                      <th>
                        <strong>Ticket No.</strong>
                      </th>
                      <th>
                        <strong>Type</strong>
                      </th>
                      <th>
                        <strong>Section</strong>
                      </th>
                      <th>
                        <strong>Row</strong>
                      </th>
                      <th>
                        <strong>Seat</strong>
                      </th>
                      <th>
                        <strong>Price</strong>
                      </th>
                    </thead>
                    <tbody>
                      {cart.items.map((ticket, index) => (
                        <tr key={"CARTSUMMARYITEMS" + index}>
                          <td>
                            {ticket?.eventStyle === "no seating"
                              ? "-"
                              : ticket.ticket}
                          </td>
                          <td>
                            {ticket?.eventStyle === "no seating"
                              ? "-"
                              : ticket.type ?? "-"}
                          </td>
                          <td>{ticket.symbol}</td>
                          <td>
                            {ticket?.eventStyle === "no seating"
                              ? "-"
                              : ticket.row}
                          </td>
                          <td>
                            {ticket?.eventStyle === "no seating"
                              ? "-"
                              : ticket.seat}
                          </td>
                          <td>{ticket.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="orderSummary__pop_detail_footer">
                  <div className="orderSummary__pop_detail_footer_btn">
                    {/* <button className="print_btn_ticket" >Print Ticket</button>
                        <button className="print_btn_invoice" >Print Invoice</button>    */}
                    <button
                      className="book__next_btn "
                      onClick={toggleOrderSummary}>
                      Book Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryData;
