export const jsonToFormData = (json) => {
  const formData = new FormData();
  for (const key in json) {
    formData.append(key, json[key]);
  }
  return formData;
};

export const appendImagesToFormData = (formData, key, images) => {
  images.forEach((img) => {
    formData.append(key, img);
  });
  return formData;
};

export const getAKeyFromObjArr = (arr, key) => {
  return arr.map(o => o[key]).filter((x, i, a) => a.indexOf(x) === i);
}