import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom";
import { getUser } from "../../helpers/local-storage";
import { loginFromLocalStorage, logout } from "../../store/slices/user";
import { LOGIN } from "../../constants/app-routes";

const ProtectedRoutes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userFromStore = useSelector((state) => state.user);
    const userFromLocalStorage = getUser();
    useEffect(() => {
        if (!userFromLocalStorage) {
            dispatch(logout())
            navigate(LOGIN);
        } else {
            if (userFromStore.access_token !== userFromLocalStorage.access_token) {
                dispatch(loginFromLocalStorage(userFromLocalStorage));
            }
        };
    }, []);
    useEffect(() => {
        if (!userFromLocalStorage && !userFromStore.auth) {
            navigate(LOGIN);
        };
    }, [userFromStore]);
    return <Outlet />;
}

export default ProtectedRoutes;