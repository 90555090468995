import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false
}

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setEvents: (state, action) => {
            state.data = action.payload;
        }
    }
})

export const { setLoading, setEvents } = eventSlice.actions;

export default eventSlice.reducer;