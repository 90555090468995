import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./newseditor.css";
import EditImage from "./edit.svg";
import DeleteImg from "./delete.svg";
import AddNewImg from "./add.svg";
import EventImage from "./eventImg.png";
import { useSelector } from "react-redux";
import { getData, postData, putData, deleteData } from "../../../services/data";
import { showError, showSuccess } from "../../../helpers/toast";
import { setNews } from "../../../store/slices/news";
import { AxiosInstance } from "../../../config/axios";

const NewsList = () => {
  const news = useSelector((state) => state.news.data);
  const user = useSelector((state) => state.user.data);
  const [filterNews, setFilterNews] = useState("All");
  const [filterType, setFilterType] = useState("All");
  const [filterDate, setFilterDate] = useState(null);
  const [addNewsUpdatesBox, setAddNewsUpdatesBox] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [policyContent, setPolicyContent] = useState("");
  const [isLoading, setLoading] = useState(false)

  const getDataHelper = () => {
    if (search && search !== "") {
      getData(`news?search=${search}`, setNews);
      return;
    }
    getData("news", setNews);
  };

  useEffect(() => {
    getDataHelper();
  }, [search]);

  const handlePolicyChange = (content) => {
    setPolicyContent(content);
  };

  const handleSubmit = () => {
    if (isLoading) return;
    setLoading(true)
    const formData = new FormData();
    formData.append("newsType", text1);
    formData.append("newsTitle", text2);
    formData.append("created_by", user.id);
    formData.append("description", policyContent);
    uploadedImage?.forEach((img) => {
      formData.append("images", img);
    });
    if (isEdit) {
      putData("news/" + isEdit, undefined, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.status !== 200) {
          showError(res?.message);
        } else {
          getDataHelper();
          showSuccess("News updated successfully");
          setAddNewsUpdatesBox(0);
        }
      }).finally(() => setLoading(false));
    } else {
      postData("news", undefined, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.status !== 201) {
          showError(res?.message);
        } else {
          getDataHelper();
          showSuccess("News published successfully");
          setAddNewsUpdatesBox(0);
        }
      }).finally(() => setLoading(false))
    }
  };
  const deleteHandler = (id) => {
    if (deleteLoader) return;
    setdeleteLoader(true);
    deleteData("news/" + id).then(() => {
      showSuccess("News deleted successfully");
      setdeleteLoader(false);
      getDataHelper();
    });
  };

  // Filtered data based on selected filter values
  const filteredData = news?.filter((filter) => {
    // Apply News filter
    if (filterNews === "Published" && filter.status === "Published") {
      return false;
    }
    if (filterNews === "Archive" && filter.status !== "Published") {
      return false;
    }

    // Apply Type filter
    if (filterType !== "All" && filter.category !== filterType) {
      return false;
    }

    // Apply Date filter
    if (filterDate && filterDate !== filter.status) {
      return false;
    }

    return true;
  });

  const addNewsUpdates = () => {
    setText1("");
    setText2("");
    setOldImages(null);
    setPolicyContent("");
    setIsEdit(false);
    setAddNewsUpdatesBox(1);
  };

  // Function to handle filter changes
  const handleNewsFilterChange = (event) => {
    setFilterNews(event.target.value);
  };

  const handleTypeFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleDateFilterChange = (event) => {
    setFilterDate(event.target.value);
  };

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [oldImages, setOldImages] = useState(null);

  const onDrop = (acceptedFiles) => {
    // Handle the uploaded image file here
    setUploadedImage(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const removeImage = () => {
    // Remove the uploaded image
    setUploadedImage(null);
    setOldImages(null);
  };

  const editNews = (news) => {
    setText1(news.newsType);
    setText2(news.newsTitle);
    setOldImages(news.images.map((img) => img.file_name));
    setPolicyContent(news.description);
    setIsEdit(news.id);
    setAddNewsUpdatesBox(1);
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header saerch={search} setSearch={setSearch} />
        <div className="page__container_wrapper news__events">
          {addNewsUpdatesBox === 0 && (
            <>
              <div className="newslist__header">
                <h2>
                  News and Updates
                  <span
                    className="addnew__newsUpdate_btn"
                    onClick={addNewsUpdates}
                  >
                    <img src={AddNewImg} alt="-" /> Add New
                  </span>
                </h2>
                <div className="newsfilter__main_wrap">
                  <div className="newsfilter__filter_fields">
                    <label htmlFor="newsFilter">News</label>
                    <select
                      id="newsFilter"
                      value={filterNews}
                      onChange={handleNewsFilterChange}
                      className="form-select"
                    >
                      <option value="All">All</option>
                      <option value="Published">Published</option>
                      <option value="Archive">Archive</option>
                    </select>
                  </div>

                  <div className="newsfilter__filter_fields">
                    <label htmlFor="typeFilter">Types</label>
                    <select
                      id="typeFilter"
                      value={filterType}
                      onChange={handleTypeFilterChange}
                      className="form-select"
                    >
                      <option value="All">All</option>
                    </select>
                  </div>
                  <div className="newsfilter__filter_fields">
                    <label htmlFor="datePicker">Date:</label>
                    <input
                      type="date"
                      id="datePicker"
                      value={filterDate || ""}
                      onChange={handleDateFilterChange}
                    />
                  </div>
                </div>
              </div>

              <div className="newsandupdate__list_main">
                <ul className="card__newsUpdated_wrap">
                  {filteredData?.map((filter) => (
                    <li key={filter.id}>
                      <img
                        src={
                          process.env.REACT_APP_API_URL +
                          "/" +
                          filter?.images[0]?.file_name
                        }
                        alt="-"
                      />
                      <div className="content__box_main_wrap">
                        <div className="content__box_category_name">
                          {filter?.newsType}
                        </div>
                        <div className="content__box_category_title">
                          {filter?.newsTitle}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: filter?.description,
                          }}
                          className="content__box_category_description"
                        ></div>
                        <div className="content__box_footer">
                          <div className="content__box_auther">
                            By {filter?.created_by}
                          </div>
                          <div className="content__box_time_actionbtns">
                            <div className="content__box_time_col">
                              {filter?.created_at}
                            </div>
                            <div className="content__box_actionbtns">
                              <button
                                style={{
                                  opacity: deleteLoader ? 0.5 : 1,
                                  cursor: deleteLoader ? "default" : "pointer",
                                }}
                                onClick={() => deleteHandler(filter.id)}
                              >
                                <img src={DeleteImg} alt="-" />
                              </button>
                              <button onClick={() => editNews(filter)}>
                                <img src={EditImage} alt="-" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {addNewsUpdatesBox === 1 && (
            <div className="addnewsupdates__main_wrapper">
              <div className="addnewsupdates__header_wrap">
                <h2>Add News and Updates</h2>
              </div>
              <div className="addnewsupdates__body_wrap">
                <div className="addnewsupdates__left__fields_box">
                  <div className="addnewsupdates__left__fields_box_col">
                    <div className="addnewsupdates__NewsType">
                      <label>News Type</label>
                      <input
                        type="text"
                        value={text1}
                        onChange={(e) => setText1(e.target.value)}
                      />
                    </div>
                    <div className="addnewsupdates__title_field">
                      <label>Title</label>
                      <input
                        type="text"
                        value={text2}
                        onChange={(e) => setText2(e.target.value)}
                      />
                    </div>
                    <div className="addnewsupdates__image_upload">
                      <label>Upload Photo/Video</label>
                      <div
                        {...getRootProps()}
                        className={
                          uploadedImage
                            ? " dropzone haveImage"
                            : "dropzone noImage"
                        }
                      >
                        <input {...getInputProps()} />
                        {oldImages ? (
                          oldImages.map((img) => (
                            <>
                              <img
                                src={process.env.REACT_APP_API_URL + "/" + img}
                                alt="Uploaded"
                                className="uploaded-image"
                              />
                              <button onClick={removeImage}>+</button>
                            </>
                          ))
                        ) : uploadedImage ? (
                          uploadedImage.map((img) => (
                            <>
                              <img
                                src={URL.createObjectURL(img)}
                                alt="Uploaded"
                                className="uploaded-image"
                              />
                              <button onClick={removeImage}>+</button>
                            </>
                          ))
                        ) : (
                          <p className="empty__image">
                            <span>+</span>upload
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addnewsupdates__right_description_box">
                  <label>Description</label>
                  <ReactQuill
                    value={policyContent}
                    onChange={handlePolicyChange}
                    modules={{
                      toolbar: [
                        [{ header: "1" }, { header: "2" }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="addnewsupdates__footer_wrap">
                {/* <div className="addnewsupdates__footer_savDraft_btn">
                  Save in Draft
                </div> */}
                <div className="addnewsupdates__footer_cancel_publish_box">
                  <div
                    onClick={() => setAddNewsUpdatesBox(0)}
                    className="addnewsupdates__footer_cancel_btn"
                  >
                    Cancel
                  </div>
                  <div
                    className="addnewsupdates__footer_publish_btn"
                    onClick={handleSubmit}
                  >
                    Publish
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsList;
