import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import { postData } from "../../../services/data";
import OTPInput from "react-otp-input";

const ForgotOtp = ({ nextStepHandler, inputData }) => {
  const [otp, setOtp] = useState("");

  const handleSubmit = () => {
    const payload = {
      ...inputData,
      phone_otp: otp,
    };
    postData("users/verify_password_reset_otp", undefined, payload).then(
      (response) => {
        if (response.status === 200) {
          nextStepHandler({ otp });
        }
      }
    );
    // Handle login logic here
    // dispatch(login(true))
    // navigate(SENDRESETOTP);
    // setSubmitting(false);
  };

  return (
    <div>
      <div className="fieldWrap">
        <OTPInput
          value={otp}
          onChange={setOtp}
          shouldAutoFocus
          inputStyle="form-class form-class-2"
          numInputs={4}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="fieldWrap fieldOnFooter">
        <button
          className="submitButton"
          type="submit"
          disabled={otp.length < 4}
          onClick={handleSubmit}>
          Validate
        </button>
      </div>
    </div>
  );
};

export default ForgotOtp;
