import React, { useState } from "react";
import "./report.css";

import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import { getData, postData } from "../../../services/data";
import { setEvents } from "../../../store/slices/event";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";

function Report() {
  const [reportType, setReportType] = useState("");
  const [selectEvents, setSelectEvents] = useState("");
  const [selectEventsOrder, setSelectOrderEvents] = useState("");
  const [selectEventsTicket, setSelectTicketEvents] = useState(0);
  const [paymentType, setPaymentType] = useState("all");
  const [sellerDepartment, setSellerDepartment] = useState("");
  const [scanType, setScanType] = useState("empty");
  const [sellerPhoneNo, setSellerPhoneNo] = useState("");
  // const [buyerPhoneNo, setBuyerPhoneNo] = useState("");
  const [cashPhone, setcashPhone] = useState("");
  const [cashName, setcashName] = useState("");
  const [cashId, setcashId] = useState(null);
  const [isCashSelected, setCashSelected] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [disbaledButton, setDisabledButton] = useState(true);
  const [ticketReserve, setTicketReserve] = useState(false);
  const [userEmail, setuserEmail] = useState("");
  const [location, setLocation] = useState("");

  const events = useSelector((state) => state.event.data);

  useEffect(() => {
    getData("events/admin", setEvents);
  }, []);

  const handleCashPhoneNumberChange = (e) => {
    const value = e;
    const replacedValue = e?.replace(/\D/g, "");
    setcashName("");
    setcashPhone(value);

    if (replacedValue?.length > 4) {
      getData(`users/cash_vendor/${replacedValue}`).then((res) => {
        if (res.data && res.data.length > 0) {
          setSuggestions(res.data);
        }
      });
    }
    if (
      replacedValue?.length === 0 ||
      replacedValue === undefined ||
      replacedValue === null
    ) {
      setSuggestions([]);
      setCashSelected(false);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    // setcashPhone(`+${suggestion.phone}`);
    setcashPhone(suggestion.phone);
    setcashName(suggestion.first_name + " " + suggestion.last_name);
    setcashId(suggestion.id);
    setSuggestions([]);
    setCashSelected(true);
  };
  const genrateReportResponseHandler = (data, filename) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `${filename} ${new Date().toDateString("en-US")}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setDisabledButton(false);
  };
  const generateReport = () => {
    if (reportType !== null || reportType !== undefined || reportType !== "") {
      setDisabledButton(true);
      const postUrl =
        reportType === "report3"
          ? "/users/members_csv"
          : reportType === "report2"
          ? "/invoices/invoice_csv"
          : reportType === "report4"
          ? "/invoices/deleteinvoices_csv"
          : reportType === "report5"
          ? "/reserve/reserve_csv"
          : "/tickets/ticket_csv";

      const filename =
        reportType === "report3"
          ? "members"
          : reportType === "report2"
          ? "invoices"
          : reportType === "report4"
          ? "delete-invoices"
          : reportType === "report5"
          ? "reserve"
          : "tickets";

      const formData =
        reportType === "report2"
          ? {
              event_id: selectEventsTicket,
              payment_type: paymentType,
              date_from: fromDate === null ? null : fromDate.target.value,
              date_to: toDate === null ? null : toDate?.target?.value,
              cash_vendor: cashId,
              user_email: userEmail,
              location: location,
            }
          : reportType === "report4"
          ? {
              event_id: selectEventsTicket,
              date_from: fromDate === null ? null : fromDate.target.value,
              date_to: toDate === null ? null : toDate?.target?.value,
            }
          : reportType === "report5"
          ? {
              event_id: selectEventsTicket,
            }
          : {
              event_id: selectEventsTicket,
              payment_type: paymentType,
              is_reserved: ticketReserve,
              date_from: fromDate === null ? null : fromDate.target.value,
              date_to: toDate === null ? null : toDate?.target?.value,
              scan_type: scanType === "empty" ? "empty" : JSON.parse(scanType),
            };
      if (reportType === "report3") {
        getData("users/members_csv").then((res) => {
          genrateReportResponseHandler(res.data, filename);
        });
      } else {
        postData(postUrl, undefined, formData).then((res) => {
          genrateReportResponseHandler(res.data, filename);
        });
      }
    } else {
      setDisabledButton(true);
    }
  };

  const renderFormFields = () => {
    if (reportType === "report1") {
      // Ticket Report
      return (
        <>
          <div className="report__page_fieldset ticket_reporting">
            <label>Select Event</label>
            <select
              value={selectEventsTicket}
              onChange={(e) => setSelectTicketEvents(e.target.value)}
              className="form-select"
            >
              <option value={0}>All Events</option>
              {events?.map((ev) => (
                <option value={ev.id}>{ev.name}</option>
              ))}
            </select>
          </div>

          <div className="report__page_fieldset ticket_reporting">
            <label value="">Payment Type</label>
            <select
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <option value="all" selected>
                All
              </option>
              <option value="cash">Cash</option>
              <option value="app">Online</option>
            </select>
          </div>

          <div className="report__page_fieldset ticket_reporting">
            <label>Scan Type</label>
            <select
              value={scanType}
              onChange={(e) => setScanType(e.target.value)}
            >
              <option value="empty" selected>
                All
              </option>
              <option value="true">Scanned</option>
              <option value="false">Not Scanned</option>
            </select>
          </div>

          {/* <div className="report__page_fieldset ticket_reporting">
            <label >Seller Department</label>
            <select value={sellerDepartment} onChange={(e) => setSellerDepartment(e.target.value)}>
              <option value="dept1">Department 1</option>
              <option value="dept2">Department 2</option>
            </select>
          </div> */}

          {/* <div className="report__page_fieldset ticket_reporting">
            <label>Seller PhoneNo</label>
            <input
              type="text"
              value={sellerPhoneNo}
              onChange={(e) => setSellerPhoneNo(e.target.value)}
            />
          </div>

          <div className="report__page_fieldset ticket_reporting">
            <label>Buyer PhoneNo</label>
            <input
              type="text"
              value={buyerPhoneNo}
              onChange={(e) => setBuyerPhoneNo(e.target.value)}
            />
          </div> */}

          <div className="report__page_fieldset ticket_reporting date__fields">
            <label>Date From</label>
            <input type="date" onChange={(e) => setFromDate(e)} />
          </div>

          <div className="report__page_fieldset ticket_reporting date__fields">
            <label>Date To</label>
            <input type="date" onChange={(e) => setToDate(e)} />
          </div>

          <div className="report__page_fieldset ticket_reporting date__fields">
            <label>Only reserved tickets</label>
            <input
              type="checkbox"
              onChange={(e) => setTicketReserve(e.target.checked)}
            />
          </div>
        </>
      );
    } else if (reportType === "report2") {
      // Order Report
      return (
        <>
          <div className="report__page_fieldset order_reporting">
            <label>Select Event</label>
            <select
              value={selectEventsTicket}
              onChange={(e) => setSelectTicketEvents(e.target.value)}
              className="form-select"
            >
              <option value={0}>All Events</option>
              {events?.map((ev) => (
                <option value={ev.id}>{ev.name}</option>
              ))}
            </select>
          </div>

          <div className="report__page_fieldset order_reporting">
            <label value="">Payment Type</label>
            <select
              value={paymentType}
              onChange={(e) => setPaymentType(e.target.value)}
            >
              <option value="all">All</option>
              <option value="cash">Cash</option>
              <option value="app">Online</option>
            </select>
          </div>

          {/* <div className="report__page_fieldset order_reporting">
            <label >Seller Department</label>
            <select value={sellerDepartment} onChange={(e) => setSellerDepartment(e.target.value)}>
              <option value="dept1">Department 1</option>
              <option value="dept2">Department 2</option>
            </select>
          </div> */}
          {/* <div className="report__page_fieldset order_reporting">
            <label>Seller PhoneNo</label>
            <input
              type="text"
              value={sellerPhoneNo}
              onChange={(e) => setSellerPhoneNo(e.target.value)}
            />
          </div> */}

          <div className="report__page_fieldset ticket_reporting date__fields">
            <label>Date From</label>
            <input type="date" onChange={(e) => setFromDate(e)} />
          </div>

          <div className="report__page_fieldset ticket_reporting date__fields">
            <label>Date To</label>
            <input type="date" onChange={(e) => setToDate(e)} />
          </div>
          <div className="report__page_fieldset order_reporting">
            <label>Cash vendor phone</label>
            {/* <input
              type="number"
              value={cashPhone}
              onChange={(e) => handleCashPhoneNumberChange(e.target.value)}
            /> */}
            <PhoneInput
              defaultCountry="BS"
              international
              className="w-100"
              placeholder="Enter Phone Number"
              value={cashPhone}
              onChange={(value) => handleCashPhoneNumberChange(value)}
            />
            {cashPhone && suggestions.length > 0 && (
              <ul className="suggestions">
                {suggestions.map((suggestion) => (
                  <li
                    key={suggestion.phone}
                    onClick={() => handleSuggestionSelect(suggestion)}
                  >
                    {suggestion.phone}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="report__page_fieldset order_reporting">
            <label>Cash vendor name</label>
            <input disabled type="text" value={cashName} />
          </div>

          <div className="report__page_fieldset order_reporting">
            <label>User email</label>
            <input
              type="text"
              value={userEmail}
              onChange={(e) => setuserEmail(e.target.value)}
            />
          </div>
          <div className="report__page_fieldset order_reporting">
            <label>Location</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
        </>
      );
    } else if (reportType === "report4") {
      // Delete invoice Report
      return (
        <>
          <div className="report__page_fieldset order_reporting">
            <label>Select Event</label>
            <select
              value={selectEventsTicket}
              onChange={(e) => setSelectTicketEvents(e.target.value)}
              className="form-select"
            >
              <option value={0}>All Events</option>
              {events?.map((ev) => (
                <option value={ev.id}>{ev.name}</option>
              ))}
            </select>
          </div>

          <div className="report__page_fieldset ticket_reporting date__fields">
            <label>Date From</label>
            <input type="date" onChange={(e) => setFromDate(e)} />
          </div>

          <div className="report__page_fieldset ticket_reporting date__fields">
            <label>Date To</label>
            <input type="date" onChange={(e) => setToDate(e)} />
          </div>
        </>
      );
    } else if (reportType === "report5") {
      // Reserve Report
      return (
        <>
          <div className="report__page_fieldset order_reporting">
            <label>Select Event</label>
            <select
              value={selectEventsTicket}
              onChange={(e) => setSelectTicketEvents(e.target.value)}
              className="form-select"
            >
              <option value={0}>All Events</option>
              {events?.map((ev) => (
                <option value={ev.id}>{ev.name}</option>
              ))}
            </select>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const handleReporttype = (e) => {
    setDisabledButton(false);
    setReportType(e.target.value);
  };

  return (
    <>
      <Sidebar />
      <Header />

      <div className="page__container_wrapper report__page">
        <div className="report__page_header">
          <h2>Reports</h2>
        </div>

        <div className="report__page_bodyContain">
          <div className="report__page_fieldset">
            <label>Report Type</label>
            <select
              value={reportType}
              onChange={(e) => {
                handleReporttype(e);
              }}
            >
              <option value="" disabled>
                Select
              </option>
              <option value="report1">Ticket Report</option>
              <option value="report2">Order Report</option>
              <option value="report3">Members Report</option>
              <option value="report4">Delete invoice Report</option>
              {/* <option value="report5">Reserve Report</option> */}
              {/* <option value="report3">Full Finance Report</option>
              <option value="report4">Finance Transaction Balance Summary Report</option> */}
            </select>
          </div>
          {renderFormFields()}
          <div className="report__page_fieldset_btn_submit">
            <button
              onClick={generateReport}
              className={`${disbaledButton ? "disbaledButton" : ""}`}
            >
              GENERATE
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;
