import axios from 'axios';
import { getUser } from '../../helpers/local-storage';
import { showError } from '../../helpers/toast';
import { store } from '../../store';
import { setLoading } from '../../store/slices/user';

const AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

AxiosInstance.interceptors.request.use(async config => {
    store.dispatch(setLoading(true));
    const user = getUser();
    if (user?.access_token) {
        config.headers['Authorization'] = user.access_token;
    }
    return config;
});

AxiosInstance.interceptors.response.use(response => {
    store.dispatch(setLoading(false));
    return response;
}, error => {
    store.dispatch(setLoading(false));
    return showError(error.response?.data?.error ?? error.message);
});

export { AxiosInstance };