import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import eventReducer from "./slices/event";
import faqReducer from "./slices/faq";
import marketingReducer from "./slices/marketing";
import feedbackReducer from "./slices/feedback";
import newsReducer from "./slices/news";
import speakerReducer from "./slices/speaker";
import ticketReducer from "./slices/ticket";
import invoiceReducer from "./slices/invoice";
import seatingReducer from "./slices/seating";
import discountReducer from "./slices/discount";
import policyReducer from "./slices/policy";
import termsReducer from "./slices/terms";
import cartReducer from "./slices/cart";
import userAdminReducer from "./slices/user-admin";
import userAppReducer from "./slices/user-app";
import adjustmentReducer from "./slices/adjustment";
import financeReducer from "./slices/finance";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";

import bannersSlice from "./slices/banners";

const persistConfig = {
  key: "cart",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["cart", "user"],
};

const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  event: eventReducer,
  faq: faqReducer,
  feedback: feedbackReducer,
  news: newsReducer,
  speaker: speakerReducer,
  ticket: ticketReducer,
  invoice: invoiceReducer,
  seating: seatingReducer,
  discount: discountReducer,
  policy: policyReducer,
  terms: termsReducer,
  userAdmin: userAdminReducer,
  userApp: userAppReducer,
  adjustment: adjustmentReducer,
  finance: financeReducer,
  marketing: marketingReducer,
  banners: bannersSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
