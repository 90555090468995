import React, { useState, useEffect } from 'react';
import './TableComponent.css'; // Import your CSS file for styling
import ViewAll from "./viewAll.png";
import { Link } from 'react-router-dom';

const TableEvents = ({ orders, title }) => {
  return (
    <div className="table-container">
      <h2>{title}<Link to={'/event'}><span className='tableheading__viewall'>View All <img src={ViewAll} alt='-' /> </span></Link></h2>
      <table className="data-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Event Name</th>
            <th>Type</th>
            <th>Date</th>
            <th>Location</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            orders?.length === 0 ?
              <tr>
                <td colSpan={8} className='text-center'>No Result Found</td>
              </tr>
              :
              orders?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>{item.date_from ? new Date(item.date_from).toLocaleString() : ''}</td>
                  <td>{item.venue}</td>
                  <td>{item.is_active ? 'Active' : 'Inactive'}</td>
                </tr>
              ))
            }
        </tbody>
      </table>
    </div>
  );
};

export default TableEvents;
