import React, { useState, useEffect, useRef } from "react";

import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import "./reserved.css";
import { getData, postData, putData } from "../../../services/data";
import { setEvents } from "../../../store/slices/event";
import { useDispatch, useSelector } from "react-redux";
import { setTickets } from "../../../store/slices/ticket";
import { showSuccess } from "../../../helpers/toast";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  addToCart,
  calculateTotalAfterAction,
  emptyCart,
  setCustomer,
} from "../../../store/slices/cart";
import OrderSummaryData from "./OrderSummary";

const countryLabels = { US: "+1", PK: "+92", IN: "+91" };
const countryCodes = Object.keys(countryLabels);

const Order = ({ isUserReserver }) => {
  const [tickets, setTickets] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedData, setselectedData] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState("All");
  const [bookingStatusFilter, setBookingStatusFilter] = useState("All");
  const [eventNameFilter, setEventNameFilter] = useState("All");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [orderPreview, setorderPreview] = useState(false);
  const [eventSections, seteventSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState("all");

  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [countryCode, setCountryCode] = useState("PK");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [locationType, setLocationType] = useState("");
  const customer = useSelector((state) => state.cart.customer);
  const user = useSelector((state) => state.user.data);
  const events = useSelector((state) => state.event.data);
  const currency = useSelector((state) => state.cart.currency);
  const dispatch = useDispatch();

  const getTicketHandler = () => {
    // getData("tickets/reserve_tickets").then((res) => {
    //   if (res.status === 200) {
    //     setTickets(res.data.tickets);
    //     if (selectedEvent) {
    //       setselectedData(
    //         res.data.tickets.filter((t) => t.event_id == selectedEvent)
    //       );
    //     }
    //   }
    // });
  };
  useEffect(() => {
    dispatch(
      calculateTotalAfterAction({
        action: emptyCart,
      })
    );
    getData("events/admin_cash", setEvents);
    getTicketHandler();
  }, []);

  // useEffect(() => {
  //   if (tickets && tickets.length > 0) {
  //     setselectedData(tickets);
  //     return;
  //   }
  //   setselectedData([]);
  // }, [tickets]);

  const handleSelectAllCheckboxes = (e) => {
    if (e.target.checked) {
      const newSelectedCheckboxes = {};
      selectedData.forEach((ticket) => {
        newSelectedCheckboxes[ticket.id] = true;
      });
      setSelectedCheckboxes(newSelectedCheckboxes);
    } else {
      setSelectedCheckboxes({});
    }
  };

  const handleCheckboxChange = (id) => {
    // setSelectedCheckboxes((prevSelectedCheckboxes) => ({
    //   ...prevSelectedCheckboxes,
    //   [id]: !prevSelectedCheckboxes[id],
    // }));

    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      return {
        ...prevSelectedCheckboxes,
        [id]: !prevSelectedCheckboxes[id],
      };
    });
  };

  const toggleOptions = (index) => {
    setShowOptions(!showOptions);
    setSelectedRowIndex(index);
  };

  const toggleOrderDetails = (order) => {
    setSelectedOrder(order);
    setShowOrderDetails(!showOrderDetails);
    toggleOptions();
  };

  const handleUnReserve = () => {
    postData("tickets/unreserve-tickets", undefined, {
      ticketIds: Object.keys(selectedCheckboxes).filter(
        (ch) => selectedCheckboxes[ch]
      ),
    }).then((res) => {
      if (res.status === 200) {
        showSuccess(res.data.message);
        setSelectedCheckboxes({});
        getTicketHandler();
        getTicketsHelper(selectedEvent, selectedSection);
      }
    });
  };

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setInputValue("");
    // dispatch(setCustomer({}));
    setShowPopup(!showPopup);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e;
    const replacedValue = e?.replace(/\D/g, "");
    setInputValue(value);
    dispatch(setCustomer({}));

    if (replacedValue?.length > 4) {
      getData(`users/${replacedValue}`).then((res) => {
        if (res.data && res.data.length > 0) {
          setSuggestions(res.data);
        }
      });
    }
    if (
      replacedValue?.length === 0 ||
      replacedValue === undefined ||
      replacedValue === null
    ) {
      dispatch(setCustomer({}));
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    // setInputValue(suggestion.phone.slice(2));
    // dispatch(setCustomer(suggestion));
    // setSuggestions([]);
    setInputValue(suggestion.phone);
    dispatch(setCustomer(suggestion));
    setSuggestions([]);
  };
  const getTicketsHelper = (eventid, section) => {
    let route = `tickets/event_reserve_tickets?event_id=${eventid}`;
    if (section && section !== "all") {
      route = route + `&section=${section}`;
    }
    getData(route).then((res) => {
      if (res.status === 200) {
        setselectedData(res.data?.tickets ?? []);
        return;
      }
      setselectedData([]);
    });
  };
  const getSectionsHelper = (eventid) => {
    getData(`events/get_section?event_id=${eventid}`).then((res) => {
      if (res.status === 200) {
        seteventSections(res?.data?.data ?? []);
        return;
      }
      seteventSections([]);
    });
  };

  const filterTickets = (eventid) => {
    setSelectedEvent(eventid);
    setSelectedSection("all");
    setSelectedCheckboxes({});
    getTicketsHelper(eventid);
    getSectionsHelper(eventid);
    // setSelectedEvent(eventid);
    // setselectedData(tickets.filter((t) => t.event_id == eventid));
  };
  const filterSection = (section) => {
    setSelectedSection(section);
    getTicketsHelper(selectedEvent, section);
  };
  const buySuccess = () => {
    getTicketHandler();
    getTicketsHelper(selectedEvent, selectedSection);
    setSelectedCheckboxes({});
  };
  const buyTicket = (e) => {
    e.preventDefault();
    if (customer) {
      const event = events.find((e) => e.id == selectedEvent);
      Object.keys(selectedCheckboxes).forEach((ticket) => {
        const item = selectedData.find((t) => t.id == ticket);
        dispatch(
          calculateTotalAfterAction({
            action: addToCart,
            data: {
              ticket: "-",
              price: item.ticket_price,
              vat: event?.vat ?? 0,
              quantity: 1,
              eventId: event?.id,
              eventVat: event?.vat,
              currency: event?.currency,
              type: item.type,
              symbol: item.symbol,
              symbolId: item.id,
              row: item.row,
              seat: item.seat,
              eventStyle: event?.style,
              currency:
                event.currency?.length > 0 ? event.currency?.length : currency,
            },
          })
        );
      });
      togglePopup();
      setorderPreview(true);
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header />
        <div className="page__container_wrapper ticket__page">
          {orderPreview ? (
            <OrderSummaryData
              successHandler={buySuccess}
              toggleHandler={() => setorderPreview(false)}
            />
          ) : (
            <div className="table-container">
              <div className="tickets-container">
                <h2 className="filter-title">Reserved</h2>
                <div className="filter-options" style={{ gap: 20 }}>
                  <div className="filter__option_col">
                    <h6>Event Name</h6>
                    <select
                      value={selectedEvent}
                      onChange={(e) => filterTickets(e.target.value)}
                      className="form-select"
                    >
                      <option value={0} disabled selected>
                        Select event
                      </option>
                      {events.map((event) => (
                        <option key={event.id} value={event.id}>
                          {event.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="filter__option_col">
                    <h6>Section</h6>
                    <select
                      className="form-select"
                      value={selectedSection}
                      onChange={({ target }) => filterSection(target.value)}
                    >
                      <option value="all">All</option>
                      {eventSections.map((section) => (
                        <option key={section.symbol} value={section.symbol}>
                          {section.symbol}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="filter__option_col">
                    <h6>Seat</h6>
                    <select className="form-select">
                      <option value={0} disabled selected>
                        Select seat
                      </option>
                    </select>
                  </div>
                  <div className="filter__option_col">
                    <h6>Row</h6>
                    <select className="form-select">
                      <option value={0} disabled selected>
                        Select row
                      </option>
                    </select>
                  </div> */}
                </div>
              </div>
              <div className="data-table-wrapper">
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={
                            Object.keys(selectedCheckboxes).filter(
                              (ch) => selectedCheckboxes[ch]
                            ).length === selectedData.length &&
                            selectedData.length > 0
                          }
                          onChange={handleSelectAllCheckboxes}
                        />
                      </th>
                      <th>Reserved ID</th>
                      <th>Date</th>
                      <th>Event Name</th>
                      <th>Ticket No.</th>
                      <th>Payment Type</th>
                      <th>Section</th>
                      <th>Seat</th>
                      <th>Row</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedData?.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedCheckboxes[item.id]}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        </td>
                        <td>{item.chair_id}</td>
                        <td>
                          {new Date(item.created_at).toLocaleDateString(
                            "en-US"
                          )}
                        </td>
                        <td>{item.event_name}</td>
                        <td>{item.number}</td>
                        <td>{item.payment_type}</td>
                        <td>{item.section}</td>
                        <td>{item.seat}</td>
                        <td>{item.row}</td>
                        <td>{item.ticket_price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="footer__reserved_page">
                <div className="estimation">
                  <h4>Total Tickets</h4>
                  <p>
                    {
                      Object.keys(selectedCheckboxes).filter(
                        (ch) => selectedCheckboxes[ch]
                      ).length
                    }
                  </p>
                  <h4>Total Amount</h4>
                  <p>
                    $
                    {selectedData
                      .filter((t) => selectedCheckboxes[t.id])
                      .reduce((acc, item) => acc + item.ticket_price, 0)
                      .toFixed(2)}
                  </p>
                </div>
                <div className="action-box">
                  <div
                    onClick={handleUnReserve}
                    className={`btn_box_btn_ureserved ${
                      Object.keys(selectedCheckboxes)?.length < 1
                        ? "disabledButton"
                        : ""
                    }`}
                  >
                    Unreserve
                  </div>
                  <div
                    onClick={togglePopup}
                    className={`btn_box_btn_buy_ticket ${
                      Object.keys(selectedCheckboxes)?.length < 1
                        ? "disabledButton"
                        : ""
                    }`}
                  >
                    Buy Tickets
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showPopup && (
        <div className="discount_create_pop_main_wrap reservedpageWrap">
          {/* <span
            className="pop-up-reserved-close" onClick={togglePopup}
          >
          </span> */}
          <div className="discount_create_pop_content">
            <div className="close__discount_create_pop_header">
              <h2>Buyer’s Detail</h2>
            </div>
            <form className="discount__fields_form_wrap">
              <div className="discount__field_wrap_body">
                <div className="cash__form_left_fields">
                  <div className="country-container inputformPhone">
                    <label htmlFor="phone">Phone:</label>
                    <PhoneInput
                      defaultCountry="BS"
                      className="w-100"
                      placeholder="Enter Phone Number"
                      value={inputValue}
                      onChange={(value) => handlePhoneNumberChange(value)}
                    />
                    {inputValue && suggestions.length > 0 && (
                      <ul className="suggestions">
                        {suggestions.map((suggestion) => (
                          <li
                            key={suggestion.phone}
                            onClick={() => handleSuggestionSelect(suggestion)}
                          >
                            {suggestion.phone}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="input-element">
                    <label htmlFor="Name">Name:</label>
                    <input
                      type="text"
                      id="Name"
                      placeholder="Enter Name"
                      disabled
                      value={
                        Object.keys(customer)?.length > 1
                          ? customer?.first_name + " " + customer?.last_name
                          : "Enter Name"
                      }
                    />
                    <label htmlFor="Email">Email:</label>
                    <input
                      type="text"
                      placeholder="Enter Email"
                      value={customer?.email ? customer?.email : "Enter Email"}
                      disabled
                    />
                    <label htmlFor="locationType">Location:</label>
                    <input
                      id="locationType"
                      type="text"
                      placeholder="Enter Location Type"
                      value={customer?.address}
                      onChange={(e) => setLocationType(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="discount__field_wrap_footer">
                <button
                  className="discount__field_wrap_footer_cancel"
                  onClick={togglePopup}
                >
                  Cancel
                </button>
                <button
                  disabled={inputValue?.length < 4 || !customer?.id}
                  onClick={buyTicket}
                  className="discount__field_wrap_footer_save"
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Order;
