import React, { useState, useEffect } from "react";
import "./TableComponent.css"; // Import your CSS file for styling
import { useSelector } from "react-redux";
import ViewAll from "./viewAll.png";
import { getData } from "../../../services/data";
import { setUserApp } from "../../../store/slices/user-app";
import { Link } from "react-router-dom";

const TableComponent = ({ orders, title }) => {
  const appUsers = useSelector((state) => state.userApp.data);

  useEffect(() => {
    getData("users/get_app_users", setUserApp);
  }, []);

  return (
    <div className="table-container">
      <h2>
        {title}
        <Link to={"/order"}>
          <span className="tableheading__viewall">
            View All <img src={ViewAll} alt="-" />{" "}
          </span>
        </Link>{" "}
      </h2>
      <table className="data-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Order ID</th>
            <th>Order Date</th>
            <th>Status</th>
            <th>Customer</th>
            <th>Phone No.</th>
            <th>Amount</th>
            <th>Gateway</th>
          </tr>
        </thead>
        <tbody>
          {orders?.length === 0 ? (
            <tr>
              <td colSpan={8} className="text-center">
                No Result Found
              </td>
            </tr>
          ) : (
            orders?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.invoice_no}</td>
                <td>{new Date(item.created_at).toLocaleDateString("en-US")}</td>
                <td>
                  {item.is_paid
                    ? "Paid"
                    : item.is_reserverd
                    ? "Reserved"
                    : "Pending"}
                </td>
                <td>
                  {item?.creator?.first_name} {item?.creator?.last_name}
                </td>
                <td>{item?.creator ? `+${item?.creator?.phone}` : ""}</td>
                <td>{item.total}</td>
                <td>{item.payment_type}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
