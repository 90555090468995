import { EVENT_TICKETS_AVAILABILITY } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  calculateTotalAfterAction,
  removeFromCart,
} from "../../../store/slices/cart";

const Seats = ({ data, event }) => {
  // CONST VALS
  const dispatch = useDispatch();

  // REDUX DATA
  const { currency, items: cartItems } = useSelector(({ cart }) => cart);

  // HANDLERS
  const selectSeatHandler = (target, row) => {
    const isAdded = cartItems?.find((x) => x.ticket === target.id);
    if (isAdded) {
      dispatch(
        calculateTotalAfterAction({
          action: removeFromCart,
          data: target.id,
        })
      );
      return;
    }
    if (target?.isDisabled) {
      return;
    }
    dispatch(
      calculateTotalAfterAction({
        action: addToCart,
        data: {
          ticket: target?.id,
          section: target.title,
          row: row,
          seat: target.column,
          price: target.price,
          type: target.ticketType,
          symbol: target.symbol,
          vat: 0,
          quantity: 1,
          eventId: event.id,
          eventVat: event.vat,
          currency:
            event.currency?.length > 0 ? event.currency?.length : currency,
        },
      })
    );
  };
  return (
    <div className="seats-wrapper">
      <p>Select your seat to book tickets</p>
      <ul className="ticket-availability">
        {EVENT_TICKETS_AVAILABILITY.map((item, index) => (
          <li key={index}>
            <span
              style={{
                backgroundColor: item.isThemeColor
                  ? data[0]?.color
                  : item.color,
              }}
            />
            {item.title}
          </li>
        ))}
      </ul>
      <div className="seats-listing-wrapper">
        <div className="seats-row-wrapper">
          {data.map((item, index) => (
            <div className="title" key={index}>
              {item.row}
            </div>
          ))}
        </div>
        <div className="seats-listing">
          {data.map((item, index) => (
            <div className="seats-row" key={index}>
              {/* <div className="title">{item.row}</div> */}
              <div className="columns">
                {item.columns.map((seat, i) => {
                  const isAdded = cartItems?.find((x) => x.ticket === seat.id);
                  return (
                    <span
                      key={i}
                      className={`seat ${seat.isDisabled ? "disabled" : ""} `}
                      style={{
                        borderColor: seat.background,
                        background: isAdded ? "transparent" : seat.background,
                        color: isAdded ? seat.background : seat.color,
                      }}
                      onClick={() => {
                        selectSeatHandler(seat, item.row);
                      }}>
                      {seat.column}
                    </span>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Seats;
