import React, { useEffect, useState } from "react";
import "./usercreation.css";
import Filters from "../order/filter";
import profileImage from "./profile.jpg";
import tickImage from "./tick.png";
import { getData } from "../../../services/data";
import { useSelector } from "react-redux";
import { setEvents } from "../../../store/slices/event";
import ReactPaginate from "react-paginate";

const ViewCard = ({ userData, orderDetailHandler }) => {
  const [userDetails, setUserDetails] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const events = useSelector((state) => state.event.data);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState("all");
  const [eventNameFilter, setEventNameFilter] = useState("all");
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);
  useEffect(() => {
    if (userData) {
      getData("events/admin", setEvents);
      getData(
        "users/get_admin_users/" +
          userData.id +
          `?page=${page}&pageSize=${pageSize}&date_from=${dateFromFilter}&date_to=${dateToFilter}&status=${paymentTypeFilter}&event_id=${eventNameFilter}`
      ).then((res) => {
        setMaxPage(res.data?.pagination?.totalPages);
        setUserDetails(res.data);
      });
    }
  }, [
    userData,
    page,
    dateFromFilter,
    dateToFilter,
    paymentTypeFilter,
    eventNameFilter,
  ]);

  return (
    <div className="userDetails_main_wrap">
      <div className="userDetails_edit_main_container">
        <div className="userDetails_edit_profile_action">
          <img
            className="userDetails_edit_profile_picture"
            src={profileImage}
            alt="pic"
          />
          {/* <div className='userDetails_edit_action_btn'>
            <button className='userDetails_action_dlt_btn'>Delete</button>
            <span className='userDetails_action_sep'> | </span>
            <button className='userDetails_action_edit_btn'>Edit</button>
          </div> */}
        </div>

        <div className="userInfo_details_box">
          <h3>
            {userDetails?.user?.first_name + " " + userDetails?.user?.last_name}
          </h3>
          <p>{userDetails?.user?.email}</p>
          <p>{userDetails?.user?.phone}</p>
          <p>{userDetails?.user?.address}</p>
          <p>
            Register <span>form:{userDetails?.user?.created_at}</span>
          </p>
        </div>

        <div className="userInfo__box_roles">
          <h3>Role Assign</h3>
          {userDetails?.roles?.map((r) => (
            <p>
              <img src={tickImage} alt="" />
              {r.name}
            </p>
          ))}
        </div>
      </div>

      <div className="userInfo__button_container ">
        <div className="userInfo__ticket_solds">
          <span className="main__box_icon">
            Total Tickets Sold
            <span className="count">{userDetails?.total_tickets}</span>
          </span>
        </div>
        <div className="userInfo__ticket_aount_raised">
          <span className="main__box_icon">
            Total amount raise
            <span className="count">${userDetails?.total_amount}</span>
          </span>
        </div>
        {/* <div className="userInfo__ticket_total_ticket_reserved">
          <span className="main__box_icon">
            Total Ticket Reserved
            <span className="count">{userDetails?.total_tickets_reserved}</span>
          </span>
        </div>
        <div className="userInfo__ticket_total_amount_receivable">
          <span className="main__box_icon">
            Total Amount Receivable
            <span className="count">${userDetails?.amount_receiveable}</span>
          </span>
        </div> */}
        <div className="userInfo__ticket_current_balance_amount">
          <span className="main__box_icon">
            Current Balance Amount
            <span className="count">${userDetails?.total_balance}</span>
          </span>
        </div>
      </div>
      <div>
        <div className="userCreation__main_edit_filters">
          <div className="userCreation__main_filteroption">
            <div className="userCreation__main_filteroption_wrap">
              <h2>Orders</h2>

              <div className="userCreation__main_filteroption_fields">
                <div className="filter__option_col">
                  <h6>Status</h6>
                  <select
                    value={paymentTypeFilter}
                    onChange={(e) => setPaymentTypeFilter(e.target.value)}
                    className="form-select"
                  >
                    <option value="all">All </option>
                    <option value="paid">Paid</option>
                    <option value="pending">Pending</option>
                  </select>
                </div>
                <div className="filter__option_col">
                  <h6>Event Name</h6>
                  <select
                    value={eventNameFilter}
                    onChange={(e) => setEventNameFilter(e.target.value)}
                    className="form-select"
                  >
                    <option value="All">All </option>
                    {events?.map((event) => (
                      <option value={event.id}>{event.name}</option>
                    ))}
                  </select>
                </div>
                <div className="filter__option_col">
                  <h6>Date From</h6>
                  <input
                    value={dateFromFilter}
                    onChange={(e) => setDateFromFilter(e.target.value)}
                    type="date"
                  />
                </div>
                <div className="filter__option_col">
                  <h6>Date To</h6>
                  <input
                    value={dateToFilter}
                    onChange={(e) => setDateToFilter(e.target.value)}
                    type="date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table className="userCreation__main_table_box">
        <thead>
          <tr>
            <th>#</th>
            <th>Order ID</th>
            <th>Order Date</th>
            <th>Status</th>
            <th>Event Name</th>
            <th>Customer</th>
            <th>Phone No.</th>
            <th>Tickets</th>
            <th>Discount</th>
            <th>Paid</th>
            <th>Gateaway</th>
          </tr>
        </thead>
        <tbody>
          {userDetails?.orders
            // ?.filter(
            //   (i) =>
            //     (!eventNameFilter ||
            //       eventNameFilter === "All" ||
            //       i.event_id.toString() === eventNameFilter.toString()) &&
            //     (!paymentTypeFilter ||
            //       paymentTypeFilter === "All" ||
            //       i.payment_type === paymentTypeFilter) &&
            //     (!dateFilter ||
            //       new Date(i.created_at).setHours(0, 0, 0, 0) ===
            //         new Date(dateFilter).setHours(0, 0, 0, 0))
            // )
            ?.map((row, index) => (
              <tr key={index}>
                <td>{(page == 1 ? 0 : (page - 1) * 10) + (index + 1)}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => orderDetailHandler(row)}
                >
                  {row.invoice_no}
                </td>
                <td>{new Date(row.created_at).toLocaleDateString("en-US")}</td>
                <td>
                  {row.is_paid
                    ? "Paid"
                    : row.is_reserverd
                    ? "Reserved"
                    : "Pending"}
                </td>
                <td>{row?.Event?.name}</td>
                <td>
                  {row?.creator?.first_name} {row?.creator?.last_name}
                </td>
                <td>{row?.creator ? `+${row?.creator?.phone}` : ""}</td>
                <td>{row.ticket_count}</td>
                <td>{row.discount_amount ?? "0"}</td>
                <td>{row.total}</td>
                <td>{row.payment_type}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        className="paginationBot"
        nextLabel=">"
        onPageChange={(e) => setPage(e.selected + 1)}
        pageRangeDisplayed={15}
        pageCount={maxPage}
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default ViewCard;
