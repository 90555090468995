import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom";
import { getUser } from "../../helpers/local-storage";
import { HOME } from "../../constants/app-routes";
import { loginFromLocalStorage } from "../../store/slices/user";

const AuthRoutes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userFromStore = useSelector((state) => state.user);
    const userFromLocalStorage = getUser();
    
    useEffect(() => {
        if (userFromStore.access_token) {
            // navigate(HOME);
            if(userFromLocalStorage?.roles[0].name === "admin" || userFromLocalStorage?.roles[0].name === "viewer"|| userFromLocalStorage?.roles[0].name === "live_feeds" || userFromLocalStorage?.roles[0].name === "organizer" || userFromLocalStorage?.roles[0].name === "create_events"){
                navigate('/home');
            }
            if(userFromLocalStorage?.roles[0].name === "cash_vendor"){
                navigate('/cash');
            }
            if(userFromLocalStorage?.roles[0].name === "reserve_tickets"){
                navigate('/reserved');
            }
            if(userFromLocalStorage?.roles[0].name === "finance"){
                navigate('/finance-track');
            }
        } else {
            if (userFromLocalStorage) {
                dispatch(loginFromLocalStorage(userFromLocalStorage));
                // navigate(HOME);
                if(userFromLocalStorage?.roles[0].name === "admin" || userFromLocalStorage?.roles[0].name === "viewer"|| userFromLocalStorage?.roles[0].name === "live_feeds" || userFromLocalStorage?.roles[0].name === "organizer" || userFromLocalStorage?.roles[0].name === "create_events"){
                    navigate('/home');
                }
                if(userFromLocalStorage?.roles[0].name === "cash_vendor"){
                    navigate('/cash');
                }
                if(userFromLocalStorage?.roles[0].name === "reserve_tickets"){
                    navigate('/reserved');
                }
                if(userFromLocalStorage?.roles[0].name === "finance"){
                    navigate('/finance-track');
                }
            }
        }
    }, [userFromStore]);
    return <Outlet />;
}

export default AuthRoutes;