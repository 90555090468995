const RoleBasedComponent = ({ role, user, children }) => {
    // if (user.roles?.find(userRole => userRole.name === role)) {
    //     return children;
    // }

    if (user.roles?.some(userRole => role.includes(userRole.name))) {
        return children;
    }

    return <></>;
}

export default RoleBasedComponent;