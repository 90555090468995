import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false
}

export const speakerSlice = createSlice({
    name: 'speaker',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setSpeakers: (state, action) => {
            state.data = action.payload;
        }
    }
})

export const { setLoading, setSpeakers } = speakerSlice.actions;

export default speakerSlice.reducer;