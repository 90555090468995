import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./mail.css";
import Reply from "./images/replyIcon.png";
import Trash from "./images/trash.png";
import Back from "./images/backto.png";
import profileImage from "./profile.jpg";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { setFeedbacks } from "../../../store/slices/feedback";
import { deleteData, getData } from "../../../services/data";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { showSuccess } from "../../../helpers/toast";
import unknowProfile from "./images/unknown-user.png";

function Mail() {
  const { index } = useParams();

  const [replyContent, setReplyContent] = useState("");
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [staticReply, setStaticReply] = useState("");

  const feedbacks = useSelector((state) => state.feedback.data);
  const feedback = feedbacks[index];
  useEffect(() => {
    getData("feedbacks", setFeedbacks);
  }, []);

  const handleReplyClick = () => {
    setIsReplyOpen(true);
  };

  const handleReplySubmit = () => {
    if (replyContent.trim() !== "") {
      setStaticReply(replyContent);
      setReplyContent("");
      setIsReplyOpen(false);
    }
  };

  if (!feedback) {
    return <div>Email not found</div>;
  }

  return (
    <div>
      <Sidebar />

      <div className="">
        <Header />

        <div className="page__container_wrapper feedback__page">
          <div className="feedback_page_header">
            <Link to="/feedback" className="back-link">
              <img src={Back} alt="-" />
              <span className="arrowText"> Back To Inbox</span>
            </Link>
          </div>

          <div className="feedback_page_content">
            <div className="feedback_page_content_header">
              <div className="feedback_page_content_details">
                <img
                  className="feedback_page_profile_picture"
                  src={feedback.image === null ? unknowProfile : feedback.image}
                  alt="pic"
                />
                <div className="feedback_page_info_content">
                  <div className="feedback_page_profile_name">
                    {feedback.first_name} {feedback.last_name}
                  </div>
                  <div className="feedback_page_profile_username">
                    {feedback.email}
                  </div>
                  <div className="feedback_page_profile_username">
                    {feedback.phone}
                  </div>
                </div>
              </div>
              {/* <div className="feedback_page_action_buttons">
                <span className="feedback_page_profile_date_container">
                  {feedback.date}
                </span>
                <span
                  className="feedback__page_icon"
                  onClick={handleReplyClick}
                >
                  <img src={Reply} alt="-" />
                  Reply
                </span>
                <span onClick={() => {
                  if (window.confirm("Are you sure you want to delete this event?"))
                    deleteData(`feedbacks/${feedback.id}`).then((res) => {
                      if (res.status === 200) {
                        showSuccess(res.data.message);
                        getData('events', setFeedbacks);
                      }
                    })
                }} className="feedback__page_icon">
                  <img src={Trash} alt="-" />
                  Delete
                </span>
              </div> */}
            </div>
            <div
              className="feedback_page_profile_text_content"
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              {feedback.subject}
            </div>
            <div className="feedback_page_profile_text_content">
              <p>{feedback.message}</p>
            </div>
          </div>
          {staticReply && (
            <div className="feedback_page_reply">
              <div className="feedback_page_content">
                <div className="feedback_page_content_header">
                  <div className="feedback_page_content_details">
                    <img
                      className="feedback_page_profile_picture"
                      src={profileImage}
                      alt="pic"
                    />
                    <div className="feedback_page_info_content">
                      <div className="feedback_page_profile_name">
                        {feedback.name}
                      </div>
                      <div className="feedback_page_profile_username">
                        {feedback.username}
                      </div>
                      <div className="feedback_page_profile_subject">
                        {feedback.subject}
                      </div>
                    </div>
                  </div>
                  <div className="feedback_page_action_buttons">
                    <span className="feedback_page_profile_date_container">
                      {feedback.date}
                    </span>
                    {/* <span className="feedback__page_icon" onClick={handleReplyClick}><img src={Reply} alt="-"/>Reply</span> */}
                    <span className="feedback__page_icon">
                      <img src={Trash} alt="-" />
                      Delete
                    </span>
                  </div>
                </div>
                <div className="feedback_page_profile_text_content">
                  <div dangerouslySetInnerHTML={{ __html: staticReply }} />
                </div>
              </div>
            </div>
          )}
          {isReplyOpen && (
            <div className="feedback_replyBox_wrapper">
              <div className="feedback_replyBox_img_replyBox">
                <img
                  className="feedback_replyBox_profile_picture"
                  src={profileImage}
                  alt="pic"
                />
                <div className="feedback_quill_container">
                  <ReactQuill
                    className="feedback_quill_editor"
                    value={replyContent}
                    onChange={setReplyContent}
                    modules={{
                      toolbar: [
                        [{ header: "1" }, { header: "2" }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                  />
                  <div className="feedback_replyBox_footer">
                    <button
                      className="feedback_replyBox_submitBtn"
                      onClick={handleReplySubmit}
                    >
                      Send
                    </button>
                    <span className="feedback_replyBox_icon">
                      <img src={Trash} alt="-" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Mail;
