import React, { useEffect, useRef, useState } from 'react';
import 'fabric-with-gestures';

const MyFabricComponent = () => {
  const canvasRef = useRef(null);
  const [editor, setEditor] = useState({ canvas: null });

  useEffect(() => {
    // Initialize Fabric.js canvas
    const canvas = new window.fabric.Canvas(canvasRef.current, {
      width: 1024,
      height: 768,
      backgroundColor: 'lightgray',
    });

    // Set the canvas instance in the state
    setEditor({ canvas });

    return () => {
      canvas.dispose();
    };
  }, []);

    useEffect(() => {
        if(editor)
            editor?.canvas?.on({
                'mouse:dblclick': function () {
                    console.log('mouse:dblclick');
                    alert('mouse:dblclick')
                },
                'touch:longpress': function () {
                    console.log('touch:longpress');
                    alert('touch:longpress')
                },
            });
    }, [editor]);

  const handleButtonClick = () => {
    
    // Example: Add a rectangle to the canvas
    const rect = new window.fabric.Rect({
        width: 50,
        height: 50,
        left: 100,
        top: 100,
        fill: 'blue',
    });
  
    editor.canvas.add(rect);
  
  };

  return (
    <div>
      <canvas ref={canvasRef} width="1024" height="768"></canvas>
      <button onClick={handleButtonClick}>Get Objects on Canvas</button>
    </div>
  );
};

export default MyFabricComponent;
