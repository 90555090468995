import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Header from "../header/headerInner";
import "./termscondition.css";
import Sidebar from "../leftsidebar/sidebar";
import axios from "axios";
import { setTerms } from "../../../store/slices/terms";
import { useSelector } from "react-redux";
import { getData, postData } from "../../../services/data";
import { showSuccess } from "../../../helpers/toast";

const TermsConditionEditor = () => {
  const [termsContent, setTermContent] = useState("");

  // Function to fetch initial content from the API
  const terms = useSelector((state) => state.terms.data);

  useEffect(() => {
    getData("termsandcondition", setTerms);
  }, []);

  useEffect(() => {
    setTermContent(terms.description);
  }, [terms]);

  const handleTermChange = (content) => {
    setTermContent(content);
  };

  const handleSubmit = async () => {
    try {
      postData("termsandcondition", undefined, {
        description: termsContent,
      })
      showSuccess("Content saved successfully!");
    } catch (error) {
      console.error("Error saving terms content:", error);
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header />
        <div className="page__container_wrapper terms__page">
          <h2>Terms & Conditions</h2>
          <div className="editor__main_wrap">
            <ReactQuill
              value={termsContent}
              onChange={handleTermChange}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
            />
            <button onClick={handleSubmit} className="savnpublish__btn">
              Save & Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditionEditor;
