import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "../components/pages/login";
import OTP from "../components/pages/login/otp";
import ForgotPassword from "../components/pages/login/forgotpassword";
import Home from "../components/pages/home";
import About from "../components/pages/about";
import Ticket from "../components/pages/Ticket/ticket";
import TicketPrint from "../components/pages/Ticket/ticketPrint";
import Speakers from "../components/pages/speakers/speakers";
import Policyeditor from "../components/pages/Policyditor/policyeditor";
import Termscondition from "../components/pages/termscondition/termscondition";
import HomepageBanners from "../components/pages/homepageBanners/homepagebanners";
import Order from "../components/pages/order/order";
import Cash from "../components/pages/cashvendor/cash";
import CashDetail from "../components/pages/cashvendor/cashdetail";
import Discount from "../components/pages/discountcode/discount";
import NewsEditor from "../components/pages/newsupdate/newseditor";
import Member from "../components/pages/members/member";
import FaqEditor from "../components/pages/faq/faqeditor";
import FeedbackEditor from "../components/pages/feedback/feedbackeditor";
import Mail from "../components/pages/feedback/mail";
import UserCreationForm from "../components/pages/userinfo/usercreationform";
import SeatingTemplate from "../components/pages/seatingtemplate/index";
import SingleSeating from "../components/pages/seatingtemplate/singleseating";
import MultipleSeating from "../components/pages/seatingtemplate/multipleseating";
import Usercreation from "../components/pages/userinfo/usercreation";
import ProtectedRoutes from "./protected-routes";
import AuthRoutes from "./auth-routes";
import IndexEvent from "../components/pages/eventcreation/index";
import CreateEvent from "../components/pages/eventcreation/create";
import EditEvent from "../components/pages/eventcreation/edit";
import EventCreation from "../components/pages/eventcreation/eventcreation";
import ProfileSetting from "../components/pages/header/profilesetting";
import OrderSummary from "../components/pages/cashvendor/OrderSummary";
import Report from "../components/pages/reports/report";
import Payment from "../components/pages/payment";
import Reserved from "../components/pages/reserved/reserved";
import FinanceTrack from "../components/pages/financetrack/financetrack";
import Adjustment from "../components/pages/adjustment/adjustment";
import MyFabricComponent from "../components/pages/seatingtemplate/MyFabricComponent";
import Marketing from "../components/pages/marketing";
import RoleBasedRoutes from "./role-based-routes";
import { useEffect, useState } from "react";
import { getData } from "../services/data";
import Donation from "../components/pages/donation/donation";

const MyRoutes = () => {
  const [userWithRoles, setUserWithRoles] = useState({});
  const user = useSelector((state) => state.user.auth);
  const userDetails = useSelector((state) => state.user.data);
  useEffect(() => {
    getData("users/get_user/" + userDetails?.id).then((res) => {
      setUserWithRoles(res.data);
    });
  }, [userDetails]);

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route
            element={
              <RoleBasedRoutes
                role={[
                  "admin",
                  "viewer",
                  "reserve_tickets",
                  "finance",
                  "cash_vendor",
                ]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/Ticket" element={<Ticket />} />
            <Route path="/ticket-print" element={<TicketPrint />} />
            <Route path="/Order" element={<Order />} />
            <Route path="/members" element={<Member />} />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={["admin", "viewer", "create_events"]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/seating-template" element={<SeatingTemplate />} />
            <Route
              path="/seating-template/single"
              element={<SingleSeating />}
            />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={[
                  "admin",
                  "viewer",
                  "live_feeds",
                  "organizer",
                  "create_events",
                ]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/home" element={<Home />} />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={[
                  "admin",
                  "organizer",
                  "viewer",
                  "cash_vendor",
                  "finance",
                ]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/about" element={<About />} />
            <Route path="/speakers" element={<Speakers />} />
            <Route path="/Policyeditor" element={<Policyeditor />} />
            <Route path="/Termscondition" element={<Termscondition />} />
            <Route path="/bannerImage" element={<HomepageBanners />} />
            <Route path="/faq" element={<FaqEditor />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/feedback" element={<FeedbackEditor />} />
            <Route path="/mail/:index" element={<Mail />} />
            <Route path="/userinfo" element={<Usercreation />} />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={["admin", "reserve_tickets"]}
                user={userWithRoles}
              />
            }
          >
            <Route
              path="/reserved"
              element={<Reserved isUserReserver={userWithRoles} />}
            />
          </Route>
          <Route path="/donation" element={<Donation />} />
          <Route
            element={
              <RoleBasedRoutes
                role={["admin", "reserve_tickets", "cash_vendor"]}
                user={userWithRoles}
              />
            }
          >
            <Route
              path="/cash"
              element={<Cash isUserCashVendor={userWithRoles} />}
            />
            <Route path="/order-summary" element={<OrderSummary />} />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={["admin", "finance", "viewer"]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/finance-track" element={<FinanceTrack />} />
            <Route path="/adjustment" element={<Adjustment />} />
            <Route path="/report" element={<Report />} />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={["admin", "cash_vendor", "viewer"]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/cash-detail" element={<CashDetail />} />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={["admin", "create_events", "viewer", "organizer"]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/discountcode" element={<Discount />} />
            <Route path="/discountcode/:id/edit" element={<EditEvent />} />
            <Route path="/newsupdate" element={<NewsEditor />} />
            <Route
              path="/seating-template/multiple"
              element={<MultipleSeating />}
            />
          </Route>

          <Route
            element={
              <RoleBasedRoutes
                role={["admin", "create_events", "viewer"]}
                user={userWithRoles}
              />
            }
          >
            <Route path="/event" element={<IndexEvent />} />
            <Route path="/event/create" element={<CreateEvent />} />
            <Route path="/event/:id/edit" element={<EditEvent />} />
            <Route path="/eventcreation" element={<EventCreation />} />
          </Route>

          <Route
            path="/profilesetting"
            element={<ProfileSetting user={userWithRoles} />}
          />
        </Route>
        <Route element={<AuthRoutes />}>
          <Route path="/" element={<Login user={user} />} />
          <Route
            path="/otp"
            element={<OTP user={user} isUserRole={userWithRoles} />}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword user={user} />}
          />
        </Route>

        <Route path="/MyFabricComponent" element={<MyFabricComponent />} />
      </Routes>
    </Router>
  );
};

export default MyRoutes;
