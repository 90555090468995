import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
};

export const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPolicy: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setLoading, setPolicy } = policySlice.actions;

export default policySlice.reducer;
