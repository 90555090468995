import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Logo from "../../../assets/media/logo.svg";
import iconHidden from "../../../assets/media/icon-hidden.svg";
import iconVisible from "../../../assets/media/icon-visible.svg";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../../services/auth";
import { sendOtp } from "../../../services/auth";
import { store } from "../../../store";
import { login as loginAction } from "../../../store/slices/user";
import { ReactReduxContext } from "react-redux";

const Login = (props) => {
  const [passVisible, setPassVisible] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    emailOrPhone: "",
    password: "",
  };

  const passVisibleHandler = () => {
    setPassVisible(!passVisible);
  };

  const validationSchema = Yup.object().shape({
    emailOrPhone: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    await sendOtp(values).then((res) => {
      if (res === true) {
        localStorage.setItem("emailOrPhone", values.emailOrPhone);
        navigate("/otp");
        setSubmitting(false);
      } else {
        return false;
      }
    });
  };

  return (
    <div className="App bodyLogin">
      {/* <div className="container"> */}
      <div className="landingWrapper">
        <div className="landingRow">
          <div className="landingCol">
            <img className="logo" src={Logo} alt="" />
            <h1>Dashboard Login</h1>
            <p className="d-none">{props.user ? "logged In" : "logged out"}</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              <Form>
                <div className="fieldWrap">
                  <label>User Id</label>
                  <Field
                    className="form-class"
                    type="text"
                    name="emailOrPhone"
                  />
                  <ErrorMessage
                    className="errorMessage"
                    name="emailOrPhone"
                    component="div"
                  />
                </div>
                <div className="fieldWrap">
                  <label>Password</label>
                  <div className="fieldWidIcon">
                    <Field
                      className="form-class"
                      type={passVisible ? "text" : "password"}
                      name="password"
                    />
                    <span className="fieldIcon" onClick={passVisibleHandler}>
                      <img
                        src={passVisible ? iconVisible : iconHidden}
                        alt=""
                      />
                    </span>
                  </div>
                  <ErrorMessage
                    className="errorMessage"
                    name="password"
                    component="div"
                  />
                </div>
                <div className="fieldWrap fW2col">
                  <div className="fWleft">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault">
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div className="fWright">
                    <Link to={"/forgot-password"} className="login-page-link">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <div className="fieldWrap fieldOnFooter">
                  <button className="submitButton" type="submit">
                    Login
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Login;
