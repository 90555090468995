import React, { useState } from 'react';
import './filter.css';

const Filters = ({ dateFilter, setDateFilter }) => {

  return (
    <div className='filter__option_col'>
      <h6>Date</h6>
      <input value={dateFilter} onChange={(e) => setDateFilter(e.target.value)} type="date" />
    </div>
  );
};

export default Filters;
