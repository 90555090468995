import React, { useState, useRef } from "react";
import "./imageUploader.scss";
import { showError, showSuccess } from "../../../helpers/toast";
import { getData, postData } from "../../../services/data";
import { setBanners } from "../../../store/slices/banners";

const ImageUploader = () => {
  //STATES
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);

  //REFS
  const fileInputRef = useRef(null);

  //CONST VALS
  const MIN_WIDTH = 800; // Example maximum width
  const MIN_HEIGHT = 600; // Example maximum height

  //HANDLERS
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImageChange(file);
    setDragOver(false);
  };

  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
            showError(
              `Image dimensions should be atleast ${MIN_WIDTH}x${MIN_HEIGHT}px`
            );
            return;
          }
          setImagePreview(reader.result);
          setImage(file);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    handleImageChange(e.target.files[0]);
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const handleUpload = () => {
    if (image) {
      setLoading(true);
      const formData = new FormData();
      console.log(image);
      formData.append("url", image); // Convert data URI to Blob

      postData("banner", undefined, formData).then((res) => {
        showSuccess(res.data?.message);
        setImage(null);
        setImagePreview(null);
        fileInputRef.current.value = "";
        setLoading(false);
        getData("banner", setBanners);
      });
    } else {
      showError("Please upload an image first.");
    }
  };

  return (
    <>
      <div
        className={`image-uploader ${dragOver ? "drag-over" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleFileInputClick}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        {imagePreview && <img src={imagePreview} alt="Uploaded" />}
        {!imagePreview && <p>Drag & drop or click to upload</p>}
      </div>
      <button
        className={`save__bt_publish`}
        onClick={handleUpload}
        disabled={loading}
      >
        {loading ? <i class="fa-solid fa-spin fa-spinner"></i> : "Upload"}
      </button>
    </>
  );
};

export default ImageUploader;
