import React, { useEffect } from 'react';
import ticketIcon from './images/ticket.png';
import Footfall from './images/footfall.png';
import TotalSale from './images/dollarIcon.png';
import Appmember from './images/member.png';
import DottedLine from './images/dottedlines.png';
import './card.css';
import { useSelector } from 'react-redux';
import { getData } from '../../../services/data';
import { setEvents } from '../../../store/slices/event';

const Card = ({ data, setEvent }) => {
    const events = useSelector(state => state.event.data);
    useEffect(() => {
        getData('events/admin', setEvents);
    }, []);
    return (
        <div>
            <div className='card__dashboard_header'>
                <h2 className='header__main_overview'>Overview</h2>
                <select onChange={e => setEvent(e.target.value)} className='form-select'>
                    <option value={0}>All Events</option>
                    {events?.map(ev => <option value={ev.id}>{ev.name}</option>)}
                </select>
            </div>
            <div className="button-container list_dasboard_buttons">
                <button className="button ticket-sales">
                    <span className='main__box_icon'>Tickets Sold
                        <span className="count">{data?.totalTickets}</span></span>
                    {/* <img src={DottedLine} className='icon__image_DottedLine' alt='' /> */}
                    <img src={ticketIcon} className='icon__image_buttons' alt='' />
                </button>
                <button className="button footfall">
                    <span className='main__box_icon'>Footfall
                        <span className="count">{data?.footfall}</span></span>
                    {/* <img src={DottedLine} className='icon__image_DottedLine' alt='' /> */}
                    <img src={Footfall} className='icon__image_buttons' alt='' />
                </button>
                <button className="button total-sales">
                    <span className='main__box_icon'>Total Sales
                        <span className="count">${data?.totalSales?.totalAmount}</span></span>
                    {/* <img src={DottedLine} className='icon__image_DottedLine' alt='' /> */}
                    <img src={TotalSale} className='icon__image_buttons' alt='' />
                </button>
                <button className="button app-members">
                    <span className='main__box_icon'>App Members
                        <span className="count">{data?.appMembers}</span></span>
                    {/* <img src={DottedLine} className='icon__image_DottedLine' alt='' /> */}
                    <img src={Appmember} className='icon__image_buttons' alt='' />
                </button>
            </div>  </div>
    );
}

export default Card;
