import React, { useState } from 'react';

const Accordion = ({ seating, onEdit, onDelete, onClone }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="accordion">
      <div className={`accordion-header ${isExpanded ? 'expanded' : ''}`} onClick={toggleAccordion}>
        {seating.name}
        <div className="arrow">{isExpanded ? <i className="fa-solid fa-chevron-down"></i> : <i className="fa-solid fa-chevron-right"></i>}</div>
      </div>
      {isExpanded && (
        <div className="accordion-body accordion-body-edittemplate">
          <button onClick={onEdit} className='template__accor_edit'>Edit Template</button>
          <button onClick={onClone} className='template__accor_clone'><i class="fa-regular fa-clone"></i></button>
          <button onClick={onDelete} className='template__accor_delete'><i class="fa-solid fa-trash"></i></button>
        </div>
      )}
    </div>
  );
};

export default Accordion;
