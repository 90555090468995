import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false
}

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setNews: (state, action) => {
            state.data = action.payload;
        }
    }
})

export const { setLoading, setNews } = newsSlice.actions;

export default newsSlice.reducer;