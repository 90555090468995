import React, { useEffect, useState } from "react";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import ReactPaginate from "react-paginate";
import { getData } from "../../../services/data";

const Donation = (props) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const getDonationsHelper = () => {
    let route = `donation?page=${page}`;
    if (search && search != "") route += `&search=${search}`;
    getData(route).then(({ data }) => {
      setData(data?.donations);
      setMaxPage(data?.totalPages);
    });
  };

  useEffect(() => {
    getDonationsHelper();
  }, [page, search]);
  return (
    <div>
      <Sidebar />
      <div className="">
        <Header search={search} setSearch={setSearch} />
        <div className="page__container_wrapper ticket__page">
          <div className="tickets-container">
            <div className="filter-options member_filter">
              <h2>Donations</h2>

              {/* <div className="filter__option_col">
                <h6>Date</h6>
                <input
                  value={dateFilter}
                  onChange={(e) => setDateFilter(e.target.value)}
                  type="date"
                />
              </div> */}
            </div>
          </div>

          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Donation Date</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.first_name + item?.last_name}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.email}</td>
                    <td>
                      {new Date(item.created_at).toLocaleDateString("en-US")}
                    </td>
                    <td>${item?.amount}</td>
                    <td>{item?.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ReactPaginate
              breakLabel="..."
              className="paginationBot"
              nextLabel=">"
              onPageChange={(e) => setPage(e.selected + 1)}
              pageRangeDisplayed={15}
              pageCount={maxPage}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;
