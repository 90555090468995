import React, { useEffect, useState } from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
import profileImage from "./profile.jpg";
import LiveFeed from "./livefeed"; // Import the LiveFeedPopup component
import RoleBasedComponent from "../../utils/rolebasedcomponent";
import { useSelector } from "react-redux";
import { getData } from "../../../services/data";

const Header = () => {
  const [isLiveFeedOpen, setIsLiveFeedOpen] = useState(false);

  const [userWithRoles, setUserWithRoles] = useState({});
  const userDetails = useSelector((state) => state.user.data);
  useEffect(() => {
    getData("users/get_user/" + userDetails?.id).then((res) => {
      setUserWithRoles(res.data);
    });
  }, [userDetails]);

  const handleLiveFeedClick = () => {
    setIsLiveFeedOpen(true);
  };

  const handleCloseLiveFeed = () => {
    setIsLiveFeedOpen(false);
  };

  const navigate = useNavigate();

  const goToProfilePage = () => {
    navigate("/profilesetting");
  };

  return (
    <div className="header_main_container">
      <div className="header__main_wrap">
        <div className="left">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            className="search-input"
            type="text"
            placeholder="Search Now..."
          />
        </div>
        <div className="right">
          <RoleBasedComponent
            role={["admin", "live_feeds"]}
            user={userWithRoles}
          >
            <div className="live-feed" onClick={handleLiveFeedClick}>
              <i className="fa-solid fa-play"></i>
              <span>Live Feed</span>
            </div>
          </RoleBasedComponent>
          {/* <i className="fa-regular fa-bell notification"></i> */}
          <img
            className="profile-picture"
            src={profileImage}
            alt="pic"
            onClick={goToProfilePage}
          />
        </div>
      </div>
      {isLiveFeedOpen && <LiveFeed onClose={handleCloseLiveFeed} />}
    </div>
  );
};

export default Header;
