import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { format } from "date-fns";

const initialState = {
  items: [],
  subTotal: 0,
  vat: 0,
  discount_amount: 0,
  eventId: null,
  eventVat: 0,
  discount: {},
  paymentPage: null,
  paymentPageCreatedAt: null,
  invoiceNo: null,
  currency: "$",
  customer: {},
};

export const calculateTotalAfterAction = createAsyncThunk(
  "cart/alterCart",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch(payload.action(payload.data));
    thunkAPI.dispatch(calculateTotal());
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    increment: (state, action) => {
      if (state.items[action.payload])
        state.items[action.payload.index].quantity += 1;
    },
    decrement: (state, action) => {
      if (state.items[action.payload])
        state.items[action.payload.index].quantity -= 1;
    },
    addToCart: (state, action) => {
      if (state.items[0]?.eventId != action.payload.eventId) {
        state.items = [
          {
            ...action.payload,
            addedAt: format(new Date(Date.now()), "yyyy-MM-ddTHH:mm:SSXXX"),
          },
        ];
      } else {
        state.items = [
          ...state.items,
          {
            ...action.payload,
            addedAt: format(new Date(Date.now()), "yyyy-MM-ddTHH:mm:SSXXX"),
          },
        ];
      }
      // state.eventId = action.payload.eventId;
      state.eventVat = action.payload.eventVat;
      state.currency = action.payload.currency;
    },
    removeFromCart: (state, action) => {
      if (state.items.length === 1) {
        state.items = initialState.items;
        state.discount = {};
        state.paymentPage = null;
        state.invoiceNo = null;
        // state.eventId = null;
        state.eventVat = 0;
        state.vat = null;
      } else {
        state.items.splice(
          state.items.map((i) => i.ticket).indexOf(action.payload),
          1
        );
      }
    },
    removeFromCartNoSeating: (state, action) => {
      if (state.items.length === 1) {
        state.items = initialState.items;
        state.discount = {};
        state.paymentPage = null;
        state.invoiceNo = null;
        state.subTotal=0
        // state.eventId = null;
        state.eventVat = 0;
        state.vat = null;
      } else {
        state.items.splice(
          state.items.map((i) => i.symbolId).indexOf(action.payload),
          1
        );
      }
    },
    calculateTotal: (state, action) => {
      let total = 0;
      let vat = 0;
      let discount = 0;
      state.items.forEach((item) => {
        total += parseFloat(item.price) * (item.quantity ?? 1);
        vat += (state.eventVat / 100) * (item.quantity ?? 1) * item.price;
      });
      if (
        Object.keys(state.discount).length > 0 &&
        state.discount.minValue <= total + vat
      ) {
        if (state.discount.type === "fixed") discount = state.discount.value;
        else {
          discount = total * (state.discount.value / 100);
        }
      } else {
        state.discount = {};
        state.discount_amount = 0;
      }
      state.subTotal = total;
      state.vat = vat;
      state.discount_amount = discount;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    emptyCart: (state) => {
      state.items = initialState.items;
      state.discount = {};
      state.paymentPage = null;
      state.invoiceNo = null;
      state.vat = null;
      state.subTotal = 0;
      state.discount_amount = 0;
    },
    setPaymentPage: (state, action) => {
      state.paymentPage = action.payload;
      state.paymentPageCreatedAt = format(
        new Date(Date.now()),
        "yyyy-MM-ddTHH:mm:SSXXX"
      );
    },
    setInvoice: (state, action) => {
      state.invoiceNo = action.payload;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  addToCart,
  removeFromCart,
  removeFromCartNoSeating,
  calculateTotal,
  setDiscount,
  emptyCart,
  setPaymentPage,
  setInvoice,
  setCustomer,
  setEventId,
} = cartSlice.actions;

export default cartSlice.reducer;
