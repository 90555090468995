import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Filters from './filter';
import Header from '../header/headerInner';
import Sidebar from '../leftsidebar/sidebar';
import AddNewImg from './add.svg';
import './filter.css';
import './eventcreation.css';
import EventEditScreen from './EventEditScreen';
import { useSelector } from 'react-redux';
import { deleteData, getData } from '../../../services/data';
import { setEvents } from '../../../store/slices/event';
import { getAKeyFromObjArr } from '../../../helpers/utils';
import { showSuccess } from '../../../helpers/toast';

const CreateEvent = (props) => {
  const [search, setSearch] = useState('');
  return (
    <div>
      <Sidebar />
      <div className=''>
        <Header search={search} setSearch={setSearch} />
        <div className='page__container_wrapper ticket__page'>
          <EventEditScreen event={false} />
        </div>

      </div>

    </div>

  );
};

export default CreateEvent;
