import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Header from "../header/headerInner";
import "./policy.css";
import Sidebar from "../leftsidebar/sidebar";
import axios from "axios";
import { getData, postData } from "../../../services/data";
import { setTerms } from "../../../store/slices/terms";
import { useSelector } from "react-redux";
import { showError, showSuccess } from "../../../helpers/toast";
import { setPolicy } from "../../../store/slices/policy";

const PrivacyPolicyEditor = () => {
  const [policyContent, setPolicyContent] = useState("");

  const policy = useSelector((state) => state.policy.data);

  useEffect(() => {
    getData("privacypolicy", setPolicy);
  }, []);

  useEffect(() => {
    setPolicyContent(policy.description);
  }, [policy]);

  const handlePolicyChange = (content) => {
    setPolicyContent(content);
  };

  const handleSubmit = async () => {
    try {
      postData("privacypolicy", undefined, {
        description: policyContent,
      });
      showSuccess("Content saved successfully!");
    } catch (error) {
      showError("Error saving policy content:", error);
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header />
        <div className="page__container_wrapper policy__page">
          <h2>Privacy Policy</h2>
          <div className="editor__main_wrap">
            <ReactQuill
              value={policyContent}
              onChange={handlePolicyChange}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
            />
            <button onClick={handleSubmit} className="savnpublish__btn">
              Save & Publish
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyEditor;
