import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../cashheader/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import Sections from "./sections";
import Seats from "./seats";
import "./cash.css";
import { getData, postData } from "../../../services/data";
import { setEvents } from "../../../store/slices/event";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import {
  addToCart,
  calculateTotalAfterAction,
  emptyCart,
  setCustomer,
  setEventId,
} from "../../../store/slices/cart";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { manipulateSeatingList } from "../../../data-manipulator/seating";
import { EVENT_SECTION_SORT_ORDER } from "../../../constants";
import { showError, showSuccess } from "../../../helpers/toast";

const countryLabels = { US: "+1", PK: "+92", IN: "+91" };
const countryCodes = Object.keys(countryLabels);

const Cash = ({ isUserCashVendor }) => {
  const navigate = useNavigate();
  // STATES
  const [sections, setSections] = useState([]);
  const [seats, setSeats] = useState([]);
  const [selectedSection, setselectedSection] = useState(null);
  const [sectionSeats, setsectionSeats] = useState([]);
  const [groupSeats, setGroupSeats] = useState([]);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [isPhoneNumberAvailable, setIsPhoneNumberAvailable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [eventStyle, setEventStyle] = useState("");
  const [userId, setUserId] = useState(null);

  // CONST VALS
  const dispatch = useDispatch();
  const isCashVendor = useRef(isUserCashVendor.roles[0].name);

  // REDUX DATA
  const events = useSelector((state) => state.event.data);
  const { eventId } = useSelector((state) => state.cart);
  const totalAmount =
    useSelector((state) => state.cart.subTotal) +
    useSelector((state) => state.cart.vat) -
    useSelector((state) => state.cart.discount_amount);
  const currency = useSelector((state) => state.cart.currency);
  const totalTickets = useSelector((state) => state.cart.items).length;
  const customer = useSelector((state) => state.cart.customer);
  const cartItems = useSelector((state) => state.cart.items);
  const event = events.find((x) => x.id === parseInt(eventId));

  // HANDLERS
  const getEventSeatingHandler = (eventType) => {
    setGroupSeats([]);
    const style = eventType ?? eventStyle;
    setShowLoader(true);
    getData("event_seating_chair/byEvent/" + eventId)
      .then((res) => {
        setShowLoader(false);
        if (res.status === 200) {
          // dispatch(setCustomer(""));
          // setInputValue()

          // for no seating
          if (style === "no seating") {
            if (res?.data?.length > 0) {
              setGroupSeats(
                res?.data?.map((item) => {
                  return { ...item, quantity: 0 };
                })
              );
            } else {
              setGroupSeats([]);
            }
            return;
          }
          const { sections, data } = manipulateSeatingList(res.data);
          setSections(sections);
          setSeats(data);
          if (sections.length === 0) {
            setsectionSeats(seats);
            return;
          }
          setselectedSection(sections[0].id);
          const selectedSeats = data.filter((x) =>
            x.columns.find((y) => y.parent_id === sections[0].id)
          );
          if (sections?.[0]?.rowReverse) {
            selectedSeats.reverse();
          }
          setsectionSeats(selectedSeats);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        return false;
      });
  };
  const setSectionHandler = (section) => {
    setselectedSection(section);
    const sectionDetail = sections.find((x) => x.id === section);
    const selectedSeats = seats.filter((x) =>
      x.columns.find((y) => y.parent_id === section)
    );
    if (sectionDetail?.rowReverse) {
      selectedSeats.reverse();
    }
    setsectionSeats(selectedSeats);
  };

  useEffect(() => {
    if (eventId !== "Select Event" && eventId !== null && events) {
      const eventStyle = events.find((x) => x.id === parseInt(eventId))?.style;
      setEventStyle(eventStyle);
      getEventSeatingHandler(eventStyle);
    }
  }, [eventId, events]);

  useEffect(() => {
    getData("events/admin_cash", setEvents);
  }, []);

  // Function to handle the dropdown change
  const handleSelectChange = (e) => {
    dispatch(
      calculateTotalAfterAction({
        action: emptyCart,
      })
    );
    dispatch(setEventId(e.target.value));
  };

  // const countryLabels = { data1: "090078601", data2: "0311292482224", data3: "1215455212121" };

  // const countryCodes = Object.keys(countryLabels);

  const handlePhoneNumberChange = (e) => {
    const value = e;
    const replacedValue = e?.replace(/\D/g, "");
    setInputValue(value);

    if (replacedValue?.length > 4) {
      getData(`users/${replacedValue}`).then((res) => {
        if (res.data && res.data.length > 0) {
          setSuggestions(res.data);
        }
      });
    }
    if (
      replacedValue?.length === 0 ||
      replacedValue === undefined ||
      replacedValue === null
    ) {
      dispatch(setCustomer());
      setIsPhoneNumberAvailable(false);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setInputValue(suggestion.phone);
    dispatch(setCustomer(suggestion));
    setIsPhoneNumberAvailable(true);
    setSuggestions([]);
    setUserId(suggestion.id);
  };

  const toggleOrderSummary = () => {
    setShowOrderSummary((prev) => !prev);
  };

  const handleAddToCart = () => {
    if (!event) return;
    groupSeats.forEach((item) => {
      let quantity = item.quantity;
      if (quantity > 0) {
        [...Array(quantity)].forEach(() => {
          dispatch(
            calculateTotalAfterAction({
              action: addToCart,
              data: {
                ticket: "-",
                price: item.price,
                vat: event?.vat ?? 0,
                quantity: 1,
                eventId: event?.id,
                eventVat: event?.vat,
                currency: event?.currency,
                type: item.ticket_type,
                symbol: item.symbol,
                symbolId: item.id,
                eventStyle: eventStyle,
                currency:
                  event.currency?.length > 0
                    ? event.currency
                    : "$",
              },
            })
          );
        });
      }
    });
    // set quantity again to zero
    setGroupSeats(
      groupSeats.map((item) => {
        return { ...item, quantity: 0 };
      })
    );
  };

  const incrementQuantity = (index) => {
    const temp = [...groupSeats];
    const secData = temp[index];
    const addedTickets = cartItems.filter(
      (x) => x.symbol === secData.ticket_type
    ).length;
    const availableTickets =
      secData?.no_of_tickets -
      secData?.tickets_booked -
      secData?.tickets_reserved -
      addedTickets;

    if (availableTickets <= secData?.quantity) return;
    const _index = temp.findIndex((v, k) => k === index);
    temp[_index].quantity = (temp[_index].quantity ?? 1) + 1;
    setGroupSeats(temp);
  };
  const manageQuantity = (val = 0, index) => {
    const value = val ? parseInt(val) : 0;
    const temp = [...groupSeats];
    const secData = temp[index];
    const addedTickets = cartItems.filter(
      (x) => x.symbol === secData.ticket_type
    ).length;
    const availableTickets =
      secData?.no_of_tickets -
      secData?.tickets_booked -
      secData?.tickets_reserved -
      addedTickets;

    if (value > availableTickets) return;
    if (value < 0) return;
    const _index = temp.findIndex((v, k) => k === index);
    temp[_index].quantity = value;
    setGroupSeats(temp);
  };

  const decrementQuantity = (index) => {
    const temp = [...groupSeats];
    const _index = temp.findIndex((v, k) => k === index);

    if (temp[_index].quantity === 0) return;
    temp[_index].quantity = (temp[_index].quantity ?? 1) - 1;

    setGroupSeats(temp);
  };

  const book = (group_index) => {
    let payload = {
      group_index: group_index,
      user_id: userId,
      event_id: eventId,
    };
    const confirmed = window.confirm("Are you sure you want to proceed?");
    if (confirmed) {
      if (userId) {
        postData(`event_seating_chair/book`, undefined, payload).then(
          async (res) => {
            showSuccess(res.data?.msg);
            getEventSeatingHandler();
            navigate("/home");
          }
        );
      } else {
        showError("Invalid User!");
      }
    } else {
      // User clicked Cancel, do something else or nothing
    }
  };

  return (
    <div className="cash__vander_main">
      <Header
        PhoneNumberAvailable={isPhoneNumberAvailable}
        CashVendor={isCashVendor.current}
      />
      <Sidebar />
      <div className="content-container drawer-container"></div>
      <div className="page__container_wrapper">
        <div className="heading-container">
          <h2 className="cash-heading">Cash Vendor</h2>
          <select
            className="select-container form-select"
            value={eventId ?? null}
            onChange={handleSelectChange}
          >
            <option value={null}>Select Event</option>
            {events.map((e, i) => (
              <option key={"EVENT" + e.id.toString() + i} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="cash-form">
          <div
            className={`seating-overlay ${showLoader ? "d-flex" : "d-none"}`}
          >
            <i class="fa-solid fa-spin fa-spinner"></i>
          </div>
          <h3 className="cash__form_title">Buyers Detail</h3>

          <div className="cash__form_left_fields">
            <div className="country-container inputformPhone">
              <label htmlFor="phone">Phone:</label>

              {/* <Flags
                id="phone"
                selected={countryCode}
                onSelect={(code) => setCountryCode(code)}
                countries={countryCodes}
                customLabels={countryLabels}
              />
              <input
                type="text"
                placeholder="Enter Phone Number"
                value={inputValue}
                onChange={handlePhoneNumberChange}
              />
              {inputValue && suggestions.length > 0 && (
                <ul className="suggestions">
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.phone}
                      onClick={() => handleSuggestionSelect(suggestion)}
                    >
                      {suggestion.phone}
                    </li>
                  ))}
                </ul>
              )} */}
              <PhoneInput
                defaultCountry="BS"
                className="w-100"
                placeholder="Enter Phone Number"
                value={inputValue}
                onChange={(value) => handlePhoneNumberChange(value)}
              />
              {inputValue && suggestions.length > 0 && (
                <ul className="suggestions">
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.phone}
                      onClick={() => handleSuggestionSelect(suggestion)}
                    >
                      {suggestion.phone}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="input-element">
              <label htmlFor="Name">Name:</label>
              <input
                type="text"
                id="Name"
                placeholder="Enter Name"
                disabled
                value={
                  customer?.first_name && customer?.last_name
                    ? customer?.first_name + " " + customer?.last_name
                    : ""
                }
              />
              <label htmlFor="Email">Email:</label>
              <input
                type="text"
                id="Email"
                placeholder="Enter Email"
                value={customer?.email ?? ""}
                disabled
              />
              {/* <label htmlFor="locationType">Location:</label>
              <input
                id="locationType"
                type="text"
                placeholder="Enter Location Type"
                disabled
                value={customer?.address}
                onChange={(e) => setLocationType(e.target.value)}
              /> */}
            </div>

            <div className="total-container">
              <p>
                Total Ticket: <span>{totalTickets}</span>
              </p>
              <p>
                Total Amount:
                <span>
                  {" "}
                  {currency}
                  {parseFloat(totalAmount).toFixed(2)}
                </span>
              </p>
            </div>
          </div>

          <div className="cash__form_right_seatField">
            {/* <div className="screentitle__wrapper mb-4">
              <div className="d-flex">
                <div className="d-flex me-auto">
                  {
                    <button
                      onClick={() => handleBackward()}
                      className={
                        "fa fa-arrow-left me-2 bg-transparent border-0 " +
                        (canvasItem !== 2 ? "invisible" : "")
                      }>
                      <span className="ms-2">Back</span>
                    </button>
                  }
                </div>
              </div>
            </div> */}
            <div className="canvas__container ms-0">
              {eventStyle === "seating" ? (
                <div className="seating-box-wrapper">
                  {sectionSeats.length >= 1 && (
                    <button
                      className="refresh-btn"
                      onClick={getEventSeatingHandler}
                    >
                      <FontAwesomeIcon icon={faRefresh} />
                    </button>
                  )}
                  {sections.length >= 1 && (
                    <Sections
                      data={sections}
                      event={events.find((x) => x.id == eventId)}
                      selectedSection={selectedSection}
                      setSectionHandler={setSectionHandler}
                    />
                  )}
                  {sectionSeats.length >= 1 && (
                    <Seats
                      data={sectionSeats}
                      event={events.find((x) => x.id == eventId)}
                    />
                  )}
                </div>
              ) : (
                eventStyle === "no seating" && (
                  <div className="seating-box-wrapper">
                    {" "}
                    {/* Ensure you have a wrapper */}
                    {groupSeats?.length === 0 ? (
                      <h2 className="no-seats-available">No seats exist!</h2>
                    ) : (
                      <div className="w-100 non-seating-box-wrapper">
                        {groupSeats?.map((value, index) => {
                          const addedTickets = cartItems.filter(
                            (x) => x.symbol === value.ticket_type
                          ).length;
                          const remainingTickets =
                            value?.no_of_tickets -
                            value?.tickets_booked -
                            value?.tickets_reserved -
                            addedTickets;
                          return (
                            <div
                              key={index}
                              className="position-relative bg-white mb-4 px-4 non-seating-box"
                            >
                              <div className="title-box">
                                <h4>{value.ticket_type}</h4>
                                <p>
                                  {value.section} "{value.symbol}"
                                </p>
                                <p>{value.entry_gate}</p>
                              </div>
                              <h5>
                                {event?.currency !== "" ? event?.currency : "$"}
                                {value.price}
                              </h5>
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  <button
                                    type="button"
                                    onClick={() => decrementQuantity(index)}
                                    className="quanity-btn"
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    value={value.quantity ?? 1}
                                    onChange={(e) =>
                                      manageQuantity(e.target.value, index)
                                    }
                                  />
                                  <button
                                    type="button"
                                    onClick={() => incrementQuantity(index)}
                                    className="quanity-btn"
                                  >
                                    +
                                  </button>
                                </div>
                                <span className="remaining-tickets">
                                  {remainingTickets} tickets remaining
                                </span>
                              </div>
                            </div>
                          );
                        })}
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            onClick={handleAddToCart}
                            className="add-to-cart"
                          >
                            Add to Cart
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        {/* <button onClick={toggleOrderSummary}>Cash Vender Order Summary</button> */}
        {/* {showOrderSummary && <OrderDetailsPop />} */}
      </div>
    </div>
  );
};

export default Cash;
