import React, { useState, useEffect, useRef } from "react";
import Filters from "./filter";
import Sidebar from "../leftsidebar/sidebar";
import NoSeatingObject from "./noseatingobject";
import {
  Row,
  Col,
  FormSelect,
  FormLabel,
  FormGroup,
  Button,
} from "react-bootstrap";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { CirclePicker } from "react-color";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../header/headerInner";
import Accordion from "./components/seatingTemplate";
import "./filter.css";
import "./seatingtemplate.css";
import { deleteData, getData, postData, putData } from "../../../services/data";
import { setSeating } from "../../../store/slices/seating";
import { useSelector } from "react-redux";
import { showError, showSuccess } from "../../../helpers/toast";

const SeatingTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(true);
  const [canvasZoom, setCanvasZoom] = useState("1");
  const [stateReplica, setStateReplica] = useState(location.state);
  const [panels, setPanels] = useState([
    true, // create template
    false, // noseating
  ]);
  const [templates, setTemplates] = useState([
    { name: "seating", active: false },
    { name: "no seating", active: false },
  ]);
  const [eventStyle, seteventStyle] = useState("seating");
  const [templateName, setTemplateName] = useState("New template");
  const [canvas, setCanvas] = useState("multiple");
  const [disableCanvas, setDisableCanvas] = useState(false);
  const [ticket, setTicket] = useState([
    {
      id: 0,
      price: 0,
      type: "",
      count: "",
      entry_gate: "",
      symbol: "",
      section: "",
      from: "",
      to: "",
      msg: { success: [], error: [] },
    },
  ]);
  const [noSeatingText, setNoSeatingText] = useState("New section name...");
  const [noSeatingSymbol, setNoSeatingSymbol] = useState("Symbol");
  const [isNoSeatingSection, setIsNoSeatingSection] = useState(false);
  const [pageTitle, setPageTitle] = useState("");

  const seatings = useSelector((state) => state.seating.data);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    getData("seating_template", setSeating).then(function (res) {
      setShowLoader(false);
    });
  }, []);

  const handleCanvasZoom = (event) => {
    // editor.canvas.setZoom(event.target.value);
    setCanvasZoom(event.target.value);
  };

  const handleEventStyle = (event) => {
    let value = event.target.value;

    setDisableCanvas(false);
    if (value === "no seating") {
      setDisableCanvas(true);
    }

    seteventStyle(value);
  };

  const handleTicket = (event, object, field) => {
    let _ticket = [...ticket];
    let findTicketIndex = _ticket.findIndex((x) => x.id === object.id);

    if (findTicketIndex !== -1) {
      _ticket[findTicketIndex][field] = event.target.value;

      setTicket(_ticket);
    }
  };

  const addTicket = () => {
    setTicket([
      ...ticket,
      {
        id: ticket?.length,
        type: "",
        count: "",
        entry_gate: "",
        symbol: "",
        section: "",
        from: "",
        to: "",
        msg: { success: [], error: [] },
      },
    ]);
  };

  const removeTicket = (inputId) => {
    const updatedTicket = [...ticket];
    let filteredTicket = updatedTicket.filter((x) => x.id !== inputId);

    setTicket(filteredTicket);
  };

  const disabledAllPanels = () => {
    const updatedPanels = panels.map(() => false);
    setPanels(updatedPanels);

    return updatedPanels;
  };

  const init = () => {
    // reset panels
    let _panels = panels.map(() => false);
    _panels[0] = true;
    setPanels(_panels);

    // reset all fields
    seteventStyle("seating");
    setDisableCanvas(false);
    setIsNoSeatingSection(false);
    setPageTitle("");
  };

  const handleCreateTemplate = async () => {
    let templateType = eventStyle;

    const updatedTemplates = [...templates];

    const templateIndex = updatedTemplates.findIndex(
      (template) => template.name === templateType
    );

    if (templateIndex !== -1) {
      for (let i = 0; i < updatedTemplates.length; i++) {
        if (i === templateIndex) {
          updatedTemplates[i].active = true;
        } else {
          updatedTemplates[i].active = false;
        }
      }

      setTemplates(updatedTemplates);
    }

    // wait for 'disabledAllPanels' to complete
    await disabledAllPanels();

    if (eventStyle === "no seating") {
      setPageTitle("No Seating Template");
      setPanels([false, true]);
    } else if (eventStyle === "seating" && canvas === "single") {
      navigate("/seating-template/single", {
        state: {
          templateName: templateName,
        },
      });
    } else if (eventStyle === "seating" && canvas === "multiple") {
      navigate("/seating-template/multiple", {
        state: {
          templateName: templateName,
        },
      });
    }
  };

  const handleSubmit = () => {
    let payload = {
      section: noSeatingText,
      symbol: noSeatingSymbol,
      ticket: ticket,
    };

    if (stateReplica?.seating) {
      putData("seating_template/" + stateReplica.seating.id, undefined, {
        event_style: "no seating",
        canvase: "single",
        name: stateReplica?.templateName,
        template_json: JSON.stringify(payload),
        // seats_json: chairs,
      })
        .then((res) => {
          if (res.status === 200) {
            showSuccess("Seating template updated successfully.");
            getData("seating_template", setSeating);
            navigate("/seating-template");
          } else {
            showError("Something went wrong");
          }
        })
        .catch((err) => {
          showError(err?.response?.data?.error ?? err.message);
        })
        .finally(() => {});
    } else {
      postData("seating_template", undefined, {
        event_style: "no seating",
        canvase: "single",
        name: templateName,
        template_json: JSON.stringify(payload),
        // seats_json: chairs,
      })
        .then((res) => {
          if (res.status === 200) {
            showSuccess("No seatings added successfully.");
            getData("seating_template", setSeating);
            navigate("/seating-template");
          } else showError("Something went wrong");
        })
        .catch((err) => {
          showError(err?.response?.data?.error ?? err.message);
        })
        .finally(() => {});
    }
    init();
    console.log("payload", payload);
  };

  const tabHandler = (tab) => {
    setTab(tab);
    seteventStyle("seating");
    setTemplateName("New template");
    setTicket([
      {
        id: 0,
        price: 0,
        type: "",
        count: "",
        entry_gate: "",
        symbol: "",
        section: "",
        from: "",
        to: "",
        msg: { success: [], error: [] },
      },
    ]);
    setTab(tab);
    setPanels([true, false]);
  };

  const handleEdit = (item) => {
    console.log("item", item);
    setShowLoader(true);
    setStateReplica({
      seating: item,
    });

    let seating;
    getData(`seating_template/${item.id}`).then(async (res) => {
      if (res.status === 200) {
        // showSuccess("Template Deleted Successfully");
        await getData("seating_template", setSeating);
        seating = res.data;

        if (seating.event_style === "no seating") {
          let data = JSON.parse(seating.template_json);

          // loadData(seating.template_json);
          seteventStyle(seating.event_style);

          setTemplateName(seating.name);
          setNoSeatingText(data?.section);
          setNoSeatingSymbol(data?.symbol);
          setTicket(data?.ticket);

          setTab(false);
          setPanels([false, true]);
          setShowLoader(false);
        } else {
          if (seating.canvase === "single") {
            navigate("/seating-template/single", {
              state: {
                templateName: seating.name,
                seating: seating,
              },
            });
          } else if (seating.canvase === "multiple") {
            navigate("/seating-template/multiple", {
              state: {
                templateName: seating.name,
                seating: seating,
              },
            });
          }
          setShowLoader(false);
        }
      }
    });
  };

  const handleDelete = (item) => {
    // Add your delete logic here
    if (window.confirm("Are you sure you want to delete this template?"))
      deleteData(`seating_template/${item.id}`).then(async (res) => {
        if (res.status === 200) {
          showSuccess("Template Deleted Successfully");
          await getData("seating_template", setSeating);
        }
      });
  };

  const handleClone = (item) => {
    postData(`seating_template/clone`, undefined, { id: item.id }).then(
      async (res) => {
        if (res.status === 200) {
          showSuccess("Template Cloned Successfully");
          await getData("seating_template", setSeating);
        }
      }
    );
  };

  useEffect(() => {
    if (!isNoSeatingSection) init();
  }, [isNoSeatingSection]);

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header />

        <div className="page__container_wrapper ticket__page">
          <Filters pageTitle={pageTitle} />

          <div className="seatingtemplate__main_wrap">
            <div className="tab-header">
              <a
                className={"nav-link " + (tab && "active")}
                onClick={() => tabHandler(true)}
              >
                Templates
              </a>
              <a
                className={"nav-link " + (!tab && "active")}
                onClick={() => tabHandler(false)}
              >
                Create New
              </a>
            </div>
            <div className="tab-body">
              <div
                className={`seating-overlay ${
                  showLoader ? "d-flex" : "d-none"
                }`}
              >
                <i class="fa-solid fa-spin fa-spinner"></i>
              </div>
              {tab && (
                <div className="templates">
                  <div className="form__main_template_content">
                    <div className="create__template_form">
                      {seatings?.map((s) => (
                        <Accordion
                          key={"SEATINGACCORDIAN" + s.id}
                          seating={s}
                          onEdit={(e) => {
                            handleEdit(s);
                          }}
                          onClone={(e) => {
                            handleClone(s);
                          }}
                          onDelete={(e) => {
                            handleDelete(s);
                          }}
                        />
                      ))}
                    </div>

                    <div className="canvas__wrapper">
                      <div className="canvas__navigation">
                        <Row>
                          <Col lg={3}>
                            <div className="canvas__container__custom">
                              <div className="canvas__zoom">
                                <button className="fa fa-search-plus"></button>
                                <FormSelect
                                  className="canvas__zoomselect"
                                  onChange={handleCanvasZoom}
                                  value={canvasZoom}
                                >
                                  <option value="100%">100%</option>
                                  <option value="125%">125%</option>
                                  <option value="150%">150%</option>
                                  <option value="175%">175%</option>
                                </FormSelect>
                                <button className="fa fa-search-minus"></button>
                              </div>
                            </div>
                          </Col>
                          <Col lg={9}>
                            <Row className="flex-row-reverse">
                              <Col lg={3}></Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!tab && (
                <div className="create-new">
                  <Row>
                    {panels[0] && (
                      <Col lg={2}>
                        <div className="create-template">
                          <FormGroup className="mb-4">
                            <FormLabel>Event Style</FormLabel>
                            <FormSelect
                              onChange={handleEventStyle}
                              value={eventStyle}
                            >
                              <option value="seating">Seating</option>
                              <option value="no seating">No Seating</option>
                            </FormSelect>
                          </FormGroup>

                          <FormGroup className="mb-4">
                            <FormLabel>Template Name</FormLabel>
                            <input
                              type="text"
                              placeholder=""
                              className="form-control"
                              value={templateName}
                              onChange={(event) =>
                                setTemplateName(event.target.value)
                              }
                            />
                          </FormGroup>

                          <FormGroup>
                            <Button
                              className="btn btn-theme"
                              onClick={handleCreateTemplate}
                            >
                              Create Template
                            </Button>
                          </FormGroup>
                        </div>
                      </Col>
                    )}

                    {panels[1] && (
                      <Col lg={6}>
                        <div className="add-area">
                          <form>
                            {/* <FormGroup className="mb-4">
                              <FormLabel>Section Name</FormLabel>
                              <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                value={noSeatingText}
                                onChange={(event) =>
                                  setNoSeatingText(event.target.value)
                                }
                              />
                            </FormGroup> */}

                            {/* <FormGroup className="mb-4">
                              <FormLabel>Symbol</FormLabel>
                              <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                value={noSeatingSymbol}
                                onChange={(event) =>
                                  setNoSeatingSymbol(event.target.value)
                                }
                              />
                            </FormGroup> */}

                            <div className="tickettype__container">
                              {ticket?.length > 0 &&
                                ticket?.map((object, index) => {
                                  return (
                                    <div
                                      className="tickettype"
                                      key={object.id}
                                      id={object.id}
                                    >
                                      {object.id != 0 && (
                                        <div className="seperator mb-4"></div>
                                      )}

                                      <FormGroup className="mb-4">
                                        <div className="d-flex">
                                          <FormLabel>
                                            Number of Tickets
                                          </FormLabel>
                                          {object.id != 0 && (
                                            <a
                                              href="#"
                                              title=""
                                              className="text-danger ms-auto text-decoration-none"
                                              onClick={() =>
                                                removeTicket(object.id)
                                              }
                                            >
                                              Delete
                                            </a>
                                          )}
                                        </div>

                                        <input
                                          type="number"
                                          placeholder=""
                                          className="form-control"
                                          value={object.count}
                                          onChange={(event) =>
                                            handleTicket(event, object, "count")
                                          }
                                        />
                                      </FormGroup>

                                      <FormGroup className="mb-4">
                                        <FormLabel>Ticket Type</FormLabel>
                                        <input
                                          type="text"
                                          placeholder=""
                                          className="form-control"
                                          value={object.type}
                                          onChange={(event) =>
                                            handleTicket(event, object, "type")
                                          }
                                        />
                                      </FormGroup>

                                      <FormGroup className="mb-4">
                                        <FormLabel>Price</FormLabel>
                                        <input
                                          type="text"
                                          placeholder=""
                                          className="form-control"
                                          value={object.price}
                                          onChange={(event) =>
                                            handleTicket(event, object, "price")
                                          }
                                        />
                                      </FormGroup>
                                      <FormGroup className="mb-4">
                                        <FormLabel>Section</FormLabel>
                                        <input
                                          type="text"
                                          placeholder=""
                                          className="form-control"
                                          value={object.section}
                                          onChange={(event) =>
                                            handleTicket(event, object, "section")
                                          }
                                        />
                                      </FormGroup>
                                      <FormGroup className="mb-4">
                                        <FormLabel>Sybmol</FormLabel>
                                        <input
                                          type="text"
                                          placeholder=""
                                          className="form-control"
                                          value={object.symbol}
                                          onChange={(event) =>
                                            handleTicket(event, object, "symbol")
                                          }
                                        />
                                      </FormGroup>
                                      <FormGroup className="mb-4">
                                        <FormLabel>Entry Gate</FormLabel>
                                        <input
                                          type="text"
                                          placeholder=""
                                          className="form-control"
                                          value={object.entry_gate}
                                          onChange={(event) =>
                                            handleTicket(event, object, "entry_gate")
                                          }
                                        />
                                      </FormGroup>
                                      {/* <Row>
                                        <Col lg={6}>
                                          <FormGroup className="mb-4">
                                            <FormLabel>From</FormLabel>
                                            <input
                                              type="number"
                                              placeholder=""
                                              className="form-control"
                                              value={object.from}
                                              onChange={(event) =>
                                                handleTicket(
                                                  event,
                                                  object,
                                                  "from"
                                                )
                                              }
                                              onBlur={() =>
                                                handleTicketValidation(object)
                                              }
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col lg={6}>
                                          <FormGroup className="mb-4">
                                            <FormLabel>To</FormLabel>
                                            <input
                                              type="number"
                                              placeholder=""
                                              className="form-control"
                                              value={object.to}
                                              onChange={(event) =>
                                                handleTicket(
                                                  event,
                                                  object,
                                                  "to"
                                                )
                                              }
                                              onBlur={() =>
                                                handleTicketValidation(object)
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row> */}
                                      {object?.msg?.error?.length > 0 ? (
                                        object?.msg?.error?.map((o, i) => {
                                          return (
                                            <p key={i} className="text-danger">
                                              {o}
                                            </p>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                      <hr />
                                    </div>
                                  );
                                })}

                              <button
                                type="button"
                                className="addmore mb-4"
                                onClick={addTicket}
                              >
                                + Add more ticket type
                              </button>
                            </div>

                            {/* <button
                              type="button"
                              className="addmore mb-4"
                              onClick={addInputTicketType}
                            >
                              + Add more ticket type
                            </button> */}

                            {/*
                              <FormGroup className="mb-4">
                                <FormLabel>Color</FormLabel>
                                <CirclePicker
                                  colors={[
                                    "#ffc627",
                                    "#00b3e3",
                                    "#9b27ff",
                                    "#4949d6",
                                    "#4dc978",
                                    "#d89e42",
                                  ]}
                                  onChange={handleColorChange}
                                />
                              </FormGroup>
                            */}

                            <FormGroup className="d-flex align-items-center">
                              {/* <Button
                                className="btn btn-theme px-4"
                                onClick={() => setIsNoSeatingSection(true)}
                              >
                                Add Area
                              </Button> */}
                              {isNoSeatingSection && (
                                <a
                                  href="#"
                                  title=""
                                  className="text-danger ms-4 text-decoration-none"
                                  onClick={() => setIsNoSeatingSection(false)}
                                >
                                  Delete
                                </a>
                              )}
                            </FormGroup>

                            <FormGroup className="d-flex align-items-center justify-content-end">
                              <Button
                                className="btn btn-theme px-4"
                                onClick={() => handleSubmit()}
                              >
                                Publish Now
                              </Button>
                            </FormGroup>
                          </form>
                        </div>
                      </Col>
                    )}

                    {/* <Col lg={1}></Col> */}

                    {/* <Col lg={10}>
                      <div
                        className="canvas__wrapper"
                        style={{
                          borderRadius: "25px",
                        }}
                      >
                        <div className="canvas__navigation ms-0 ps-0">
                          <Row>
                            <Col lg={3}>
                              <div className="canvas__container__custom">
                                <div className="canvas__zoom">
                                  <button
                                    className="fa fa-search-plus"
                                    onClick={handleCanvasZoomIn}
                                  ></button>
                                  <FormSelect
                                    className="canvas__zoomselect"
                                    onChange={handleCanvasZoom}
                                    value={canvasZoom}
                                  >
                                    {canvasZoomList.map((event) => (
                                      <option
                                        key={event.value}
                                        value={event.value}
                                      >
                                        {event.name}
                                      </option>
                                    ))}
                                  </FormSelect>
                                  <button
                                    className="fa fa-search-minus"
                                    onClick={handleCanvasZoomOut}
                                  ></button>
                                </div>
                              </div>
                            </Col>
                            <Col lg={9}>
                              <Row className='flex-row-reverse'>
                                <Col lg={3}>
                                  <FormGroup className='mb-4'>
                                    <FormSelect onChange={handleShapeStyle} value={shapeStyle}>
                                      <option value="square">Square</option>
                                      <option value="circle">Circle</option>
                                      <option value="rectangle">Rectangle</option>
                                    </FormSelect>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>

                        <div className='my-4'>
                        <button onClick={createCustomObject}>createCustomObject</button>
                        <button onClick={removeSelectedObject} disabled={!cropImage}>Delete</button>
                        </div>

                        <FabricJSCanvas className="sample-canvas" onReady={onReady} />

                        <div className="canvas__panel">
                          {isNoSeatingSection && (
                            <NoSeatingObject
                              shapeStyle={shapeStyle}
                              selectedColor={selectedColor}
                              canvasZoom={canvasZoom}
                              noSeatingText={noSeatingText}
                              noSeatingSymbol={noSeatingSymbol}
                              numberOfTickets={numberOfTickets}
                              fromToText={fromToText}
                            />
                          )}
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              )}

              {!tab && panels[1] && (
                <>
                  <div>
                    <div className="seperator my-4"></div>
                  </div>

                  {/* <Row>
                    <Col lg={2}></Col>
                    <Col lg={10}>
                      <FormGroup className="d-flex align-items-center justify-content-end">
                        <Button
                          className="btn btn-theme px-4"
                          onClick={() => publishNow()}
                        >
                          Publish Now
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeatingTemplate;
