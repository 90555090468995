import React from "react";
import "./filter.css";
import { useSelector } from "react-redux";

const Filters = ({
  paymentTypeFilter,
  setPaymentTypeFilter,
  eventNameFilter,
  setEventNameFilter,
  dateFilter,
  setDateFilter,
  statusFilter,
  setStatusFilter,
}) => {
  const events = useSelector((state) => state.event.data);
  return (
    <div className="tickets-container">
      <h2 className="filter-title">Orders</h2>
      <div className="filter-options" style={{ gap: 30 }}>
        <div className="filter__option_col">
          <h6>Status</h6>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="form-select"
          >
            <option value="All">All </option>
            <option value="paid">Paid</option>
            <option value="pending">Reserve</option>
          </select>
        </div>
        <div className="filter__option_col">
          <h6>Payment Type</h6>
          <select
            value={paymentTypeFilter}
            onChange={(e) => setPaymentTypeFilter(e.target.value)}
            className="form-select"
          >
            <option value="All">All </option>
            <option value="cash">Cash</option>
            <option value="app">Online</option>
          </select>
        </div>

        <div className="filter__option_col">
          <h6>Event Name</h6>
          <select
            value={eventNameFilter}
            onChange={(e) => setEventNameFilter(e.target.value)}
            className="form-select"
          >
            <option value="All">All </option>
            {events?.map((event) => (
              <option value={event.id}>{event.name}</option>
            ))}
          </select>
        </div>
        <div className="filter__option_col">
          <h6>Date</h6>
          <input
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
            type="date"
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
