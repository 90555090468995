import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import profileImage from './profile.jpg';
import speakerPhotCamera from "./speakerPhotCamera.png";
import './profilesetting.css';
import Sidebar from '../leftsidebar/sidebar';
import Header from './headerInner';
import { getData, postData, putData } from '../../../services/data';
import { useSelector } from 'react-redux';
import { showSuccess } from '../../../helpers/toast';

const ProfileSetting = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [userRoleDetails, setUserRoleDetails] = useState({});
  const userDetails = useSelector(state => state.user.data);
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [changePass, setchangePass] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedImage(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [formData, setFormData] = useState({
    Fname: userDetails.first_name,
    Lname: userDetails.last_name,
    email: userDetails.email,
    phoneNumber: userDetails.phone,
    checkboxes: {
      admin: false,
      create_events: false,
      cash_vendor: false,
      scan_tickets: false,
      live_feeds: false,
      reserve_tickets: false,
      finance: false,
      organizer: false,
      viewer: false
    },
  });

  useEffect(() => {
    getData("users/get_user/" + userDetails.id).then((res) => {
      // debugger
      // console.log(res.data.roles.map(role => ({ [role.name]: true })));
      // setUserRoleDetails(res.data.roles);
      setFormData({
        ...formData,
        checkboxes: {
          ...formData.checkboxes,
          admin: !!res.data.roles.map(role => role.name).includes("admin"),
          create_events: !!res.data.roles.map(role => role.name).includes("create_events"),
          cash_vendor: !!res.data.roles.map(role => role.name).includes("cash_vendor"),
          scan_tickets: !!res.data.roles.map(role => role.name).includes("scan_tickets"),
          live_feeds: !!res.data.roles.map(role => role.name).includes("live_feeds"),
          reserve_tickets: !!res.data.roles.map(role => role.name).includes("reserve_tickets"),
          finance: !!res.data.roles.map(role => role.name).includes("finance"),
          organizer: !!res.data.roles.map(role => role.name).includes("organizer"),
          viewer: !!res.data.roles.map(role => role.name).includes("viewer"),
        }
      });
    });
  }, []);

  const handleInputChange = (e) => {
    // debugger
    const { name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: e.target.value,
    }));
  };

  const handleFormSubmit = () => {
    putData('users/update_user_profile', undefined, {
      profilePicture: uploadedImage,
      first_name: formData.Fname,
      last_name: formData.Lname,
      // email: formData.email,
      // phone: formData.phone,
      // roles: Object.keys(formData.checkboxes).filter(k => formData.checkboxes[k]).join(',')
    }).then((res) => {
      if (res.status === 201) {
        showSuccess(res.data.message ?? "User edited successfully");
        // onClose();
        // setProcessingAJAX(false);
      }
    }).catch(error => {
      // setProcessingAJAX(false);
    });
    // setFormData({
    //   Fname: userDetails.first_name,
    //   Lname: userDetails.last_name,
    //   email: userDetails.email,
    //   phoneNumber: userDetails.phone,
    //   checkboxes: {
    //     admin: false,
    //     create_events: false,
    //     cash_vendor: false,
    //     scan_tickets: false,
    //     live_feeds: false,
    //     reserve_tickets: false,
    //     finance: false,
    //     organizer: false,
    //     viewer: false
    //   },
    // });
  };

  const handlechangePassSubmit = () => {
    postData('users/change_password', undefined, {
      oldPassword: oldPassword,
      password: newPassword,
    }).then((res) => {
      if (res.status === 200) {
        showSuccess(res.data.message ?? "Password changed successfully");
        setchangePass(!changePass);
      }
    }).catch(error => {
    });
  };

  const hanldeChangePass = () => {
    setchangePass(!changePass);
  }


  return (
    <div>
      <Sidebar />
      {/* <Header /> */}




      <div className="page__container_wrapper profile__main_wrap_settings mt-5">
        <div className='profile__header_main'>
          <h2>Your Profile</h2>
        </div>



        <div className='profile__content_wrap_main'>
          <div className="profile__left_col">

            <div {...getRootProps()} className="profile__dropzone">
              <input {...getInputProps()} />
              {uploadedImage ? (
                <img
                  className="profile__picture"
                  src={uploadedImage}
                  alt="Your Profile Picture"
                />
              ) : (
                <img
                  className="profile__picture"
                  src={profileImage}
                  alt="Your Profile Picture"
                />
              )}
              <div className="profile__form_UploadprofileImage">
                <img src={speakerPhotCamera} alt="" />
              </div>
            </div>
            <h5>
              Register from <span>{userDetails.phone}</span>
            </h5>

            <h5 style={{ cursor: 'pointer', color: 'red' }} onClick={hanldeChangePass}>
              Change password
            </h5>
          </div>
          <div className='profile__right_col'>
            {
              changePass
                ? <div>
                  <h3 className='profile__change_pass_heading'>Change Password</h3>
                  <div className="profile__input-row">
                    <div className='profile__form_form_field'>
                      <label>Old Password</label>
                      <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                    </div>

                    <div className='profile__form_form_field'>
                      <label>New Password</label>
                      <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>
                  </div>
                  <button onClick={handlechangePassSubmit} className='profile__form_form_submit'>Save Changes</button>
                </div>
                : <div>
                  <div className="profile__input_row">
                    {/* <div className='profile__form_form_field'>
              <label>First Name</label>
              <input type="text" placeholder="First Name" value={userDetails.first_name} />
            </div>

            <div className='profile__form_form_field'>
              <label>Last Name</label>
              <input type="text" placeholder="Last Name" value={userDetails.last_name} />
            </div> */}


                    <div className="add__user_form_field">
                      <label>First Name</label>
                      <input
                        type="text" placeholder="First Name"
                        value={formData.Fname}
                        name='Fname'
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="add__user_form_field">
                      <label>Last Name</label>
                      <input
                        type="text" placeholder="Last Name"
                        value={formData.Lname}
                        name='Lname'
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="add__user_form_field">
                      <label>Email</label>
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        // onChange={handleInputChange}
                        readOnly
                      />
                    </div>
                    <div className="add__user_form_field">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        // onChange={handleInputChange}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="add__user_title_heading">Role Assign</div>
                  <div className="add__user_form_field_checkbox">
                    {Object.keys(formData.checkboxes).map((checkbox) => (
                      <label key={checkbox} className="checkbox-label" style={{ pointerEvents: 'none' }}>
                        <input
                          type="checkbox"
                          className={`checkbox-${checkbox}`}
                          name="checkboxes"
                          value={checkbox}
                          checked={formData.checkboxes[checkbox]}
                        // onChange={handleInputChange}
                        />
                        {checkbox.replace("_", " ")}
                      </label>
                    ))}
                  </div>
                  <button onClick={handleFormSubmit} className='profile__form_form_submit'>Save Changes</button>
                </div>
            }



          </div>

        </div>
      </div>
    </div >

  );

}
export default ProfileSetting
