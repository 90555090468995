import { AxiosInstance } from "../../config/axios";
import { store } from "../../store";

export const getData = (route, action, params) => {
  return AxiosInstance.get(route, { params }).then((res) => {
    if (action) {
      store.dispatch(action(res.data));
    }
    return res;
  });
};

export const postData = (route, action, params, config) => {
  return AxiosInstance.post(route, params, config).then((res) => {
    if (action) {
      store.dispatch(action(res.data));
    }
    return res;
  });
};

export const putData = (route, action, params, config) => {
  return AxiosInstance.put(route, params, config).then((res) => {
    if (action) {
      store.dispatch(action(res.data));
    }
    return res;
  });
};

export const deleteData = (route) => {
  return AxiosInstance.delete(route);
}