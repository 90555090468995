import { createSlice } from '@reduxjs/toolkit';
import { removeUser, setUser } from '../../../helpers/local-storage';

const initialState = {
    auth: false,
    data: {},
    access_token: '',
    loading: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        login: (state, action) => {
            // state.auth = true;
            // setUser({});
            if (action.payload?.access_token) {
                setUser(action.payload);
                state.data = action.payload?.user;
                state.access_token = action.payload?.access_token;
                state.auth = true;
            }
        },
        loginFromLocalStorage: (state, action) => {
            state.data = action.payload?.user;
            state.access_token = action.payload?.access_token;
            state.auth = true;
        },
        logout: (state) => {
            removeUser();
            state.data = {};
            state.access_token = '';
            state.auth = false;
        }
    }
})

export const { setLoading, login, loginFromLocalStorage, logout } = userSlice.actions;

export default userSlice.reducer;