import React, { useEffect, useState } from "react";
import Header from "../header/headerInner";
import "./Speakers.css";
import Sidebar from "../leftsidebar/sidebar";
import AddNewImg from "./images/add.svg";
import profileImage from "./images/profile.jpg";
import fbIcon from "./images/fb.png";
import inIcon from "./images/instagram.png";
import twtIcon from "./images/twitter.png";
import youtubeIcon from "./images/youtube.png";
import edIcon from "./images/edIcon.png";
import CreateUpdateSpeaker from "./component/createUpdateSpeaker/createUpdateSpeaker";
import { useSelector } from "react-redux";
import { deleteData, getData } from "../../../services/data";
import { setSpeakers } from "../../../store/slices/speaker";
import { showSuccess } from "../../../helpers/toast";

const Speakers = () => {
  const speakers = useSelector((state) => state.speaker.data);
  const [search, setSearch] = useState("");
  const [addNewsUpdatesBox, setAddNewsUpdatesBox] = useState(false);
  const [speakerToEdit, setSpeakerToEdit] = useState();

  const getSpeakers = () => {
    if (search && search !== "") {
      getData(`speakers?search=${search}`, setSpeakers);
      return;
    }
    getData("speakers", setSpeakers);
  };

  useEffect(() => {
    getSpeakers();
  }, [search]);

  const addNewsUpdates = () => {
    setSpeakerToEdit(null);
    setAddNewsUpdatesBox(!addNewsUpdatesBox);
  };

  const editSpeaker = (speaker) => {
    setSpeakerToEdit(speaker);
    setAddNewsUpdatesBox(true);
  };

  const [editMenus, setEditMenus] = useState({});

  const toggleEditMenu = (speakerId) => {
    setEditMenus((prevEditMenus) => ({
      ...prevEditMenus,
      [speakerId]: !prevEditMenus[speakerId] || false,
    }));
  };

  return (
    <>
      <div>
        <Sidebar />
        <div className="">
          <Header saerch={search} setSearch={setSearch} />
          <div className="page__container_wrapper speakersPage">
            {addNewsUpdatesBox ? (
              <div className="speaker__page_create_update_wrap">
                <CreateUpdateSpeaker
                  speakerToEdit={speakerToEdit}
                  togglePage={addNewsUpdates}
                />
              </div>
            ) : (
              <div className="speaker__page_main_wrap">
                <div className="speaker__page_header">
                  <h2>Speakers</h2>{" "}
                  <span
                    className="addnew__newsUpdate_btn"
                    onClick={addNewsUpdates}
                  >
                    <img src={AddNewImg} alt="-" /> Add New
                  </span>{" "}
                </div>
                <div className="speaker__page_body">
                  {speakers?.map((speaker) => (
                    <div key={speaker.id} className="speaker-box">
                      <div className="speaker_box_edit_delete">
                        <img
                          src={edIcon}
                          onClick={() => toggleEditMenu(speaker.id)}
                          alt=""
                        />
                        {editMenus[speaker.id] && (
                          <>
                            <div className="speaker_box_edit_delete_menu">
                              <div
                                className="speaker_box_edit_delete_menu_edit"
                                onClick={() => editSpeaker(speaker)}
                              >
                                Edit
                              </div>
                              <div
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this speaker?"
                                    )
                                  )
                                    deleteData(`speakers/${speaker.id}`).then(
                                      (res) => {
                                        if (res.status === 200) {
                                          showSuccess(res.data.message);
                                          getSpeakers();
                                        }
                                      }
                                    );
                                }}
                                className="speaker_box_edit_delete_menu_delete"
                              >
                                Delete
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <img
                        src={
                          process.env.REACT_APP_API_URL +
                          "/" +
                          speaker.profilePicture
                        }
                        alt={speaker.speakerName}
                        className="speaker-image"
                      />
                      <h3 className="speaker_box_speakerRole">
                        {speaker.category}
                      </h3>
                      <p className="speaker_box_speakerName">{speaker.name}</p>
                      <p className="speaker_box_speakerDesignation">
                        {speaker.designation}
                      </p>
                      <p
                        dangerouslySetInnerHTML={{ __html: speaker.about }}
                        className="speaker_box_speakerDescripttion"
                      ></p>
                      <div className="speaker_box_social_icon_box">
                        {speaker.facebook && (
                          <a
                            href={speaker.facebook}
                            target="_blank"
                            className="social-media-button"
                            rel="noreferrer"
                          >
                            <img src={fbIcon} alt="" />
                          </a>
                        )}
                        {speaker.twitter && (
                          <a
                            href={speaker.twitter}
                            target="_blank"
                            className="social-media-button"
                            rel="noreferrer"
                          >
                            <img src={twtIcon} alt="" />
                          </a>
                        )}
                        {speaker.instagram && (
                          <a
                            href={speaker.instagram}
                            target="_blank"
                            className="social-media-button"
                            rel="noreferrer"
                          >
                            <img src={inIcon} alt="" />
                          </a>
                        )}
                        {speaker.youtube && (
                          <a
                            href={speaker.youtube}
                            target="_blank"
                            className="social-media-button"
                            rel="noreferrer"
                          >
                            <img src={youtubeIcon} alt="" />
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Speakers;
