import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
};

export const invoice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setInvoices: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setLoading, setInvoices } = invoice.actions;

export default invoice.reducer;
