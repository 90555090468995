import React, { useEffect, useState } from 'react';
import Filters from './filter';
import Header from './headerInner';
import Sidebar from '../leftsidebar/sidebar';
import { Row, Col, FormLabel, FormGroup, Button, FormSelect, Container, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CirclePicker } from 'react-color'
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { getData, postData, putData } from "../../../services/data";
import { showSuccess, showError } from "../../../helpers/toast";
import { setSeating } from "../../../store/slices/seating";
import { v4 as uuidv4 } from 'uuid';

const SingleSeating = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const { editor, onReady, fabric } = useFabricJSEditor();

    const [canvasZoom, setCanvasZoom] = useState('1');
    const [canvasZoomList, setCanvasZoomList] = useState([
        { name: '10%', value: '0.1' },
        { name: '20%', value: '0.2' },
        { name: '30%', value: '0.3' },
        { name: '40%', value: '0.4' },
        { name: '50%', value: '0.5' },
        { name: '60%', value: '0.6' },
        { name: '70%', value: '0.7' },
        { name: '80%', value: '0.8' },
        { name: '90%', value: '0.9' },
        { name: '100%', value: '1' },
        { name: '110%', value: '1.1' },
        { name: '120%', value: '1.2' },
        { name: '130%', value: '1.3' },
        { name: '140%', value: '1.4' },
        { name: '150%', value: '1.5' },
        { name: '160%', value: '1.6' },
        { name: '170%', value: '1.7' },
        { name: '180%', value: '1.8' },
        { name: '190%', value: '1.9' },
        { name: '200%', value: '2' },
    ]);
    const [title, setTitle] = useState(state.templateName ?? 'Title here...');
    const [symbol, setSymbol] = useState('');
    const [entrygate, setEntrygate] = useState('');
    const [tickets, setTickets] = useState(0);
    const [tickettype, setTickettype] = useState(0);
    const [price, setPrice] = useState(0);
    const [ticketRange, setTicketRange] = useState('');
    const [selectedObject, setSelectedObject] = useState({});
    const [rowname, setRowname] = useState('');
    const [columnname, setColumnname] = useState('');

    const [inputTicketType, setInputTicketType] = useState([{ id: 0, numberOfTickets: '100', from: '', to: '' }]);
    const [cropImage, setCropImage] = useState(true);
    const [selectedColor, setSelectedColor] = useState('#000');
    const [constantWord, setConstantWord] = useState('');
    const [startFrom, setStartFrom] = useState(1);
    const [endOn, setEndOn] = useState(1);
    const [columnStartFrom, setColumnStartFrom] = useState(1);
    const [columnEndOn, setColumnEndOn] = useState(1);
    const [elementQty, setElementQty] = useState(1);
    const [elementPrice, setElementPrice] = useState(0);
    const [elementTicketType, setElementTicketType] = useState('Normal');
    const [elementEntrygate, setElementEntrygate] = useState('');
    const [elementRowname, setElementRowname] = useState('');
    const [elementColumnname, setElementColumnname] = useState('');


    const [canvasJson, setCanvasJson] = useState({});
    const [json, setJson] = useState(state.seating.template_json ?? []);
    const [chairs, setChairs] = useState(state.seating.chairs ?? []);

    const [shape, setShape] = useState('');
    const [activeItem, setActiveItem] = useState('')
    const [canvasItem, setCanvasItem] = useState(0)
    const [parentElementId, setParentElementId] = useState(null)

    const init = () => {
        setTitle('');
        setSymbol('');
        setEntrygate('');
        setTickets(0);
        setPrice(0);
        setTickettype('');
        setSelectedColor('#000');
        setStartFrom(1);
        setEndOn(1);
        setRowname('');
        setColumnname('');

        setColumnStartFrom(1);
        setColumnEndOn(1);
        setElementQty(1);
        setElementPrice(0);
        setElementTicketType('');
        setElementRowname('');
        setElementColumnname('');
    }

    // Sub screen states
    const [screenTitle, setScreenTitle] = useState('Multiple Seating Chart');
    const [screenDescription, setScreenDescription] = useState('You can move and rotate seating position as per your requirements');
    const [bookingSeats, setBookingSeats] = useState(0);

    const handleDropdownClick = (event) => {
        event.stopPropagation();
        // Your code to handle the dropdown click event goes here
    };

    const handleColorChange = (color) => {
        setSelectedColor(color.hex); // Store the selected color in state
    };

    const handleActiveItem = (isParent, type, name = null) => {
        if (type === 'image') {
            setActiveItem(name);
        }
        else {
            setActiveItem(type);
        }

        // init element settings
        init();

        // set shape type i.e image Or rect type
        setShape(type);
    };

    const generateRandomId = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomId = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomId += characters.charAt(randomIndex);
        }

        return randomId;
    }

    // const generateRandomId = () => {
    //     return uuidv4();
    // };

    const createCustomObject = () => {
        let _elementQty = elementQty;
        let _rowStartFrom = parseInt(startFrom);
        let _rowEndOn = parseInt(endOn);
        let _columnStartFrom = parseInt(columnStartFrom);
        let _columnEndOn = parseInt(columnEndOn);
        let _index = 1;
        let loop = true;
        let columnQty = 0;

        if (_rowStartFrom > 0 && _rowEndOn > 0 && _columnStartFrom > 0 && _columnEndOn > 0) {
            _index = _rowStartFrom;

            // calculation for row
            if (_rowStartFrom === _rowEndOn) {
                _elementQty = 1;
            }
            if (_rowEndOn > _rowStartFrom) {
                _elementQty = (_rowEndOn + 1) - _rowStartFrom;
            }
            if (_rowStartFrom > _rowEndOn) {
                _elementQty = (_rowStartFrom + 1) - _rowEndOn;
                loop = false;
            }

            // calculation for column
            if (_columnStartFrom === _columnEndOn) {
                columnQty = 1;
            }
            if (_columnEndOn > _columnStartFrom) {
                columnQty = ((_columnEndOn + 1) - _columnStartFrom);
                // _elementQty = _elementQty * columnQty;
            }
            if (_columnStartFrom > _columnEndOn) {
                columnQty = ((_columnStartFrom + 1) - _columnEndOn);
                // _elementQty = _elementQty * columnQty;
            }

            if (_rowStartFrom > _rowEndOn) {
                // _index = _elementQty * columnQty;
            }
        }

        if (editor && activeItem?.length > 0 && _elementQty > 0 && columnQty > 0) {

            for (let index2 = 1; index2 < parseInt(columnQty) + 1; index2++) {
                _index = _elementQty;

                for (let index = 1; index < parseInt(_elementQty) + 1; index++) {

                    let tickets = 0;
                    let ticketRange = '';
                    let data = {};
                    let object = [];
                    let objWidth = 0;
                    let objHeight = 0;
                    let objType = '';
                    let randomId = generateRandomId(8);

                    if (_rowEndOn > _rowStartFrom) {
                        _index = index;
                    }

                    if (
                        shape === 'rectangle' ||
                        shape === 'circle' ||
                        shape === 'chair' ||
                        shape === 'text' ||
                        shape === 'table'
                    ) {
                        if (shape === 'rectangle' || shape === 'table') {
                            const content = '';

                            inputTicketType.forEach(function (value, index) {
                                if (value.numberOfTickets.length > 0)
                                    tickets += parseInt(value.numberOfTickets);
                                ticketRange += 'From ' + value.from + '-' + ' To ' + value.to + '\n'
                            })

                            // Create a custom object (a simple rectangle)
                            const rectangle = new window.fabric.Rect({  // Use window.fabric.Rect
                                left: 0,
                                top: 0,
                                width: 500,
                                height: 250,
                                borderRadius: 250,
                                rx: 30,
                                ry: 30,
                                fill: selectedColor,
                                initFill: selectedColor
                            });

                            // Create a Text object for your text
                            const text = new window.fabric.Text(content, {
                                left: rectangle.width / 2, // Adjust the position as needed
                                top: rectangle.height / 2, // Adjust the position as needed
                                fill: 'white', // Text color
                                fontSize: 34, // Text font size
                                originX: 'center', // Center the text horizontally
                                originY: 'center' // Center the text vertically
                            });

                            data = {
                                title: ['', 'setTitle'],
                                symbol: ['', 'setSymbol'],
                                entrygate: [elementEntrygate, 'setEntrygate'],
                                tickets: [0, 'setTickets'],
                                price: [elementPrice, 'setPrice'],
                                tickettype: [elementTicketType, 'setTickettype']
                            };

                            object = [rectangle, text];

                            objWidth = 500;
                            objType = 'section';
                        }

                        if (shape === 'circle') {
                            const content = '';

                            inputTicketType.forEach(function (value, index) {
                                if (value.numberOfTickets.length > 0)
                                    tickets += parseInt(value.numberOfTickets);
                                ticketRange += 'From ' + value.from + '-' + ' To ' + value.to + '\n'
                            })

                            // Create a custom object (a simple rectangle)
                            const circle = new window.fabric.Rect({  // Use window.fabric.Rect
                                left: 0,
                                top: 0,
                                width: 250,
                                height: 250,
                                borderRadius: 250,
                                rx: 250,
                                ry: 250,
                                fill: selectedColor,
                                initFill: selectedColor
                            });

                            // Create a Text object for your text
                            const text = new window.fabric.Text(content, {
                                left: circle.width / 2, // Adjust the position as needed
                                top: circle.height / 2, // Adjust the position as needed
                                fill: 'white', // Text color
                                fontSize: 34, // Text font size
                                originX: 'center', // Center the text horizontally
                                originY: 'center' // Center the text vertically
                            });

                            data = {
                                title: ['', 'setTitle'],
                                symbol: ['', 'setSymbol'],
                                entrygate: [elementEntrygate, 'setEntrygate'],
                                tickets: [0, 'setTickets'],
                                price: [elementPrice, 'setPrice'],
                                tickettype: [elementTicketType, 'setTickettype']
                            };

                            object = [circle, text];
                        }

                        if (shape === 'chair') {
                            let content = constantWord + _index;

                            // Create a custom object (a simple rectangle)
                            let chair = new window.fabric.Rect({  // Use window.fabric.Rect
                                left: 0,
                                top: 0,
                                width: 40,
                                height: 40,
                                rx: 5,
                                ry: 5,
                                fill: selectedColor,
                                initFill: selectedColor
                            });

                            // Create a Text object for your text
                            let text = new window.fabric.Text(content, {
                                left: chair.width / 2, // Adjust the position as needed
                                top: chair.height / 2, // Adjust the position as needed
                                fill: 'white', // Text color
                                fontSize: 18, // Text font size
                                originX: 'center', // Center the text horizontally
                                originY: 'center' // Center the text vertically
                            });

                            data = {
                                title: [content, 'setTitle'],
                                price: [elementPrice, 'setPrice'],
                                tickettype: [elementTicketType, 'setTickettype'],
                                symbol: ['', 'setSymbol'],
                                entrygate: [elementEntrygate, 'setEntrygate'],
                                // tickets: ['', setTickets]
                                rowname: [elementRowname, 'setRowname'],
                                columnname: [elementColumnname, 'setColumnname'],
                            };

                            object = [chair, text];
                            objWidth = chair.width;
                            objHeight = chair.height;
                            objType = 'chair';
                        }

                        // Group the Rect and Text together
                        let group = new window.fabric.Group(object, {
                            left: (index > 1) ? ((objWidth + 15) * (index - 1)) : 0,
                            top: (index2 > 1) ? ((objHeight + 15) * (index2 - 1)) : 0,
                            id: randomId,
                            data: data,
                            objType: objType
                        });

                        group.toObject = (function (toObject) {
                            return function (propertiesToInclude) {
                                return new window.fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                                    id: randomId,
                                    data: data,
                                    objType: objType
                                });
                            };
                        })(group.toObject);

                        // Add the group to the canvas
                        editor.canvas.add(group);
                    }

                    if (shape === 'image') {
                        const objWidth = 250;
                        const objHeight = 250;
                        const svgIndex = (index > 0) ? (index - 1) : 0;

                        const svgOptions = async (objects, options) => {
                            const svg = await new window.fabric.util.groupSVGElements(objects, options);
                            svg.scaleToWidth(objWidth);
                            svg.scaleToHeight(objHeight);
                            svg.data = {
                                title: ['', 'setTitle'],
                                price: [elementPrice, 'setPrice'],
                                tickettype: [elementTicketType, 'setTickettype'],
                                symbol: ['', 'setSymbol'],
                                entrygate: [elementEntrygate, 'setEntrygate'],
                            }
                            svg.id = randomId;
                            svg.left = (svgIndex > 0) ? ((objWidth + 15) * (svgIndex)) : 0;

                            if (typeof svg.forEachObject === 'function') {
                                // Traverse all objects in the group and set their fill color
                                svg.forEachObject(function (subObject) {
                                    subObject.set({
                                        fill: selectedColor,
                                        initFill: selectedColor,
                                        stroke: '#fff'
                                    });
                                });
                            }

                            editor.canvas.add(svg);
                        }

                        const image = new window.fabric.loadSVGFromURL(window.location.origin + `/${activeItem}.svg`, svgOptions);
                    }

                    if (shape === 'text') {
                        const text = new window.fabric.Textbox(
                            'Text Here', {
                            width: 150,
                            fontSize: 18,
                            editable: true
                        });

                        editor.canvas.add(text);
                    }

                    if (loop) _index++;
                    else _index--;



                    console.log('index', index)
                    console.log('index2', index2)
                    console.log('_index', _index)
                    // console.log('index', index, 'index2', index2, '_elementQty * columnQty', columnQty)
                    // console.log('result', ((index*columnQty) + index2)/index2)
                }
            }

            // Make sure to render the canvas
            editor.canvas.requestRenderAll();
            if (canvasItem === 0) setCanvasItem(1)
        }
    }

    const saveCanvasJson = async () => {
        if (editor) {
            const _canvasJson = await editor.canvas.toJSON();
            const objects = await editor.canvas.getObjects();

            await _canvasJson?.objects?.forEach((o, i) => {
                o.data = objects[i]?.data;
                o.id = objects[i]?.id;
                o.objType = objects[i]?.objType;
                o.isBooked = objects[i]?.isBooked;
                o.isReserved = objects[i]?.isReserved;
                o.isDisabled = objects[i]?.isDisabled;

                if (objects[i]._objects?.length) {
                    objects[i]._objects?.forEach((_o, _i) => {
                        if (_o?.type === 'rect' && o?.objects[_i]?.type === 'rect' && _o?.initFill !== undefined) {
                            o.objects[_i].initFill = _o?.initFill;
                        }
                    })
                }
            });

            setCanvasJson(_canvasJson);
            editor.canvas.clear();
        }
    }

    const loadCanvasJson = async () => {
        if (editor?.canvas) {
            await setScreenTitle('Multiple Seating Chart')
            await editor.canvas.clear(); // Clear the existing canvas
            await editor.canvas.loadFromJSON(canvasJson, () => {
                editor.canvas.renderAll();
            });
        }

        // const objects = editor.canvas.getObjects();

        // objects.forEach((object, index) => {
        //     console.log('object', object)
        // });
    }

    const removeSelectedObject = () => {
        const activeObjects = editor.canvas.getActiveObjects();
        if (activeObjects?.length > 0) {
            activeObjects?.forEach((object, index) => {
                editor.canvas.remove(object);
            });
        }
    };

    const disableSelectedObject = async () => {
        const activeObjects = editor.canvas.getActiveObjects();
        if (activeObjects?.length > 0) {
            await activeObjects?.forEach(async (object, index) => {
                // Update the individual object
                object.isDisabled = true;
                await object?._objects?.forEach(async (_o) => {
                    console.log('_o.type', _o.type)
                    if (_o.type === 'rect') {
                        _o.set({ fill: 'lightgray' });
                    }
                });
                // Update the canvas JSON
                await editor.canvas.renderAll();
            });
        }
    };


    const handleCanvasZoom = (event) => {
        editor.canvas.setZoom(event.target.value);
        setCanvasZoom(event.target.value);
    };

    const handleCanvasZoomIn = () => {
        let findIndex = canvasZoomList.findIndex(x => x.value === canvasZoom);
        let zoomInObj = canvasZoomList[findIndex + 1];

        if (zoomInObj != undefined) {
            editor.canvas.setZoom(zoomInObj?.value);
            setCanvasZoom(zoomInObj?.value);
        }
    };

    const handleCanvasZoomOut = () => {
        let findIndex = canvasZoomList.findIndex(x => x.value === canvasZoom);
        let zoomOutObj = canvasZoomList[findIndex - 1];

        if (zoomOutObj != undefined) {
            editor.canvas.setZoom(zoomOutObj?.value);
            setCanvasZoom(zoomOutObj?.value);
        }
    };

    const updateTheJsonObjAndLoadOnCanvas = async (id, isDoubleClick = false) => {
        const jsonObj = [...json];
        const jsonFindIndex = jsonObj.findIndex(x => x.id === id);

        // if double click
        if (isDoubleClick) {
            if (jsonFindIndex !== -1) {

                if (editor) {
                    setTimeout(() => {
                        //load the child element objects
                        // setScreenTitle('')
                        editor.canvas.clear(); // Clear the existing canvas
                        editor.canvas.loadFromJSON(jsonObj[jsonFindIndex].json, () => {
                            editor.canvas.renderAll();
                        }, (error) => {
                            console.error('Error loading JSON:', error);
                        });
                    }, 1000)
                }

            } else {
                // Copy the existing array into a new variable
                const newArray = [...jsonObj];

                // Push the object 'obj' into the new array
                newArray.push({
                    id: id,
                    json: {}
                });

                // Use the setJson function to update the state or value
                setJson(newArray);
            }

        }

        // if backward click
        else {
            if (editor) {
                const _canvasJson = await editor.canvas.toJSON();
                const objects = await editor.canvas.getObjects();

                await _canvasJson?.objects?.forEach((o, i) => {
                    o.data = objects[i]?.data;
                    o.id = objects[i]?.id;
                    o.objType = objects[i]?.objType;
                    o.isBooked = objects[i]?.isBooked;
                    o.isReserved = objects[i]?.isReserved;
                    o.isDisabled = objects[i]?.isDisabled;

                    if (objects[i]._objects?.length) {
                        objects[i]._objects?.forEach((_o, _i) => {
                            if (_o?.type === 'rect' && o?.objects[_i]?.type === 'rect' && _o?.initFill !== undefined) {
                                o.objects[_i].initFill = _o?.initFill;
                            }
                        })
                    }
                })

                jsonObj[jsonFindIndex].json = _canvasJson;
                setJson(jsonObj);
            }
        }


    };

    const doubleClickEvent = (event) => {
        if (editor && editor.canvas && event?.target && event?.target?.objType === 'section') {
            const data = { ...event.target.data };
            if (Object.keys(data).length > 0) setScreenTitle(data?.title[0])

            // update the json object in the json array
            setParentElementId(event?.target?.id);
            updateTheJsonObjAndLoadOnCanvas(event?.target?.id, true);

            // if parent screen
            if (canvasItem === 1) saveCanvasJson();

            // move to child screen
            setCanvasItem(2);
        }
    };

    const handleDoubleClick = () => {
        console.log('outer')
        if (editor && editor.canvas) {
            console.log('inner')
            editor.canvas.on('mouse:dblclick', doubleClickEvent);
        }
    };

    const handleDisableDoubleClick = () => {
        if (editor && editor.canvas) {
            // Remove the event listener to prevent memory leaks
            editor.canvas.off('mouse:dblclick', doubleClickEvent);
        }
    };

    const handleBackward = async (initScreen = false) => {
        console.log(initScreen)
        if (initScreen === true) {
            navigate('/seating-template');
        }
        else {
            await updateTheJsonObjAndLoadOnCanvas(parentElementId);

            await loadCanvasJson();

            // move to parent screen
            setCanvasItem(1);
        }
    };

    const publishNow = async () => {
        let chairs = [];

        // // intially save the parent element data
        const _canvasJson = await editor.canvas.toJSON();
        const objects = await editor.canvas.getObjects();

        await _canvasJson?.objects?.forEach((o, i) => {

            o.data = objects[i]?.data;
            o.id = objects[i]?.id;
            o.objType = objects[i]?.objType;
            o.isBooked = objects[i]?.isBooked;
            o.isReserved = objects[i]?.isReserved;
            o.isDisabled = objects[i]?.isDisabled;

            if (objects[i]._objects?.length) {
                objects[i]._objects?.forEach((_o, _i) => {
                    if (_o?.type === 'rect' && o?.objects[_i]?.type === 'rect' && _o?.initFill !== undefined) {
                        o.objects[_i].initFill = _o?.initFill;
                    }
                })
            }
        })


        // if (canvasJson?.objects?.length > 0) {
        //     canvasJson?.objects?.forEach((o ,i) => {
        //         if(o?.objType === 'chair') {
        //             chairs.push({
        //                 parentId: null,
        //                 id: o?.id,
        //                 title: o?.data?.title[0],
        //                 isBooked: o?.isBooked ?? false
        //             })
        //         }
        //     });
        // }

        if (canvasItem === 1) {
            let Arr = await JSON.parse(JSON.stringify(json));
            let i = await Arr.findIndex(x => x.id === null);

            if (i === -1) {
                await Arr.push({
                    "id": null,
                    "json": _canvasJson
                });
            }
            else {
                if (Arr[i].id === null) {
                    Arr[i].json = await _canvasJson;
                }
            }

            if (Arr?.length > 0) {
                for (let index = 0; index < Arr?.length; index++) {
                    const parentId = Arr[index]?.id;
                    const element = Arr[index]?.json?.objects;

                    if (element?.length > 0) {
                        element?.forEach((o, i) => {

                            if (o?.objType === 'chair') {
                                chairs.push({
                                    parentId: parentId,
                                    id: o?.id,
                                    title: o?.data?.title[0],
                                    price: o?.data?.price[0],
                                    tickettype: o?.data?.tickettype[0],
                                    symbol: o?.data?.symbol[0],
                                    entrygate: o?.data?.entrygate[0],
                                    rowname: o?.data?.rowname[0],
                                    columnname: o?.data?.columnname[0],
                                    isBooked: o?.isBooked ?? false,
                                    isReserved: o?.isReserved ?? false,
                                    isDisabled: o?.isDisabled ?? false
                                });
                            }
                        });
                    }
                }
            }

            await setJson(Arr);
            await setChairs(chairs);
            await editor.canvas.renderAll();

            console.log('setJson(Arr)', Arr)
            console.log('setChairs(chairs)', chairs)

            if (state.seating) {
                putData("seating_template/" + state.seating.id, undefined, {
                    event_style: "seating",
                    canvase: "single",
                    name: state.templateName,
                    template_json: Arr,
                    seats_json: chairs,
                }).then((res) => {
                    if (res.status === 201) {
                        showSuccess("Seating template updated successfully.");
                        getData("seating_template", setSeating);
                        navigate("/seating-template");
                    } else showError("Something went wrong");
                }).catch((err) => {
                    showError(err?.response?.data?.error ?? err.message);
                });
            } else {
                postData("seating_template", undefined, {
                    event_style: "seating",
                    canvase: "single",
                    name: state.templateName,
                    template_json: Arr,
                    seats_json: chairs,
                }).then((res) => {
                    console.log("res", res);
                    if (res.status === 201) {
                        showSuccess("Seating template added successfully.");
                        getData("seating_template", setSeating);
                        navigate("/seating-template");
                    } else showError("Something went wrong");
                }).catch((err) => {
                    showError(err?.response?.data?.error ?? err.message);
                });
            }
        }

        // if(canvasItem === 2) {
        //     // setJson();
        // }

        console.log('canvasZoom', canvasZoom)
    };

    const applySavingInfo = async (fields, Arr) => {
        await Arr.forEach(async (obj) => {
            await obj?.json?.objects?.forEach((o, i) => {
                let findFieldInObj = fields.find(x => x.id === o.id);
                if (findFieldInObj && o.objType === 'chair') {

                    if (findFieldInObj?.isBooked) o.isBooked = findFieldInObj?.isBooked;
                    if (findFieldInObj?.isReserved) o.isReserved = findFieldInObj?.isReserved;
                    if (findFieldInObj?.isDisabled) o.isDisabled = findFieldInObj?.isDisabled;

                    if (o?.objects?.length > 0) {
                        o?.objects?.forEach((_o, _i) => {
                            if (_o?.type === 'rect') {
                                if (o.isBooked) _o.fill = 'grey';
                                else if (o?.isReserved) _o.fill = '#606060';
                                else if (o?.isDisabled) _o.fill = 'lightgray';
                            }
                        });
                    }
                }
            })

            if (obj?.id === null) {
                if (editor) {
                    await setScreenTitle('Multiple Seating Chart')
                    await editor.canvas.clear(); // Clear the existing canvas
                    await editor.canvas.loadFromJSON(obj?.json, () => {
                        editor.canvas.renderAll();
                    });

                    await setCanvasJson(obj?.json);
                }
            }
        });

        await setJson(Arr);
    };

    const countOfBookingSeats = (_fields) => {
        let result = [];
        if (_fields?.length > 0) {
            _fields?.forEach((o, i) => {
                if (o?.isBooked === true) {
                    result.push(o);
                }
            });
        }

        setBookingSeats(result?.length);
    };

    const loadData = async () => {
        const _fields = chairs ? JSON.parse(JSON.stringify(chairs)) : ''; // 
        const _json = json ? JSON.parse(JSON.stringify(json)) : '';

        await applySavingInfo(_fields, _json);
        await countOfBookingSeats(_fields);
        await setCanvasItem(1);
    };

    // used for initialization of canvas
    useEffect(() => {
        loadData();

    }, [editor?.canvas]);

    let myFunctions = {
        setTitle: function (data) {
            setTitle(data)
        },
        setSymbol: function (data) {
            setSymbol(data)
        },
        setEntrygate: function (data) {
            setEntrygate(data)
        },
        setTickets: function (data) {
            setTickets(data)
        },
        setPrice: function (data) {
            setPrice(data)
        },
        setTickettype: function (data) {
            setTickettype(data)
        },
        setRowname: function (data) {
            setRowname(data)
        },
        setColumnname: function (data) {
            setColumnname(data)
        },
    };

    useEffect(() => {
        let activeObject = editor?.canvas.getActiveObject();
        let object = activeObject?.data;

        setSelectedObject(object)

        if (!activeObject) {

        }
        else {
            for (const key in object) {
                if (Object.hasOwnProperty.call(object, key)) {
                    let value = object[key][0];
                    let funcName = object[key][1];
                    let func = myFunctions[funcName];

                    if (typeof func === 'function') {
                        func(value); // Call the function from your object
                    } else {
                        console.log(`Function ${funcName} not found.`);
                    }
                }
            }
        }
        console.log('canvas item', canvasItem)
    }, [editor?.canvas.getActiveObject()])

    useEffect(() => {
        const activeObject = editor?.canvas.getActiveObject();
        const groupToUpdate = editor?.canvas.getObjects().find(obj => obj.id === activeObject?.id);

        if (groupToUpdate && groupToUpdate.type === 'group') {
            if (groupToUpdate?.data) {
                if (groupToUpdate.data?.title) groupToUpdate.data.title[0] = title;
                if (groupToUpdate.data?.symbol) groupToUpdate.data.symbol[0] = symbol;
                if (groupToUpdate.data?.entrygate) groupToUpdate.data.entrygate[0] = entrygate;
                if (groupToUpdate.data?.tickets) groupToUpdate.data.tickets[0] = tickets;
                if (groupToUpdate.data?.price) groupToUpdate.data.price[0] = price;
                if (groupToUpdate.data?.tickettype) groupToUpdate.data.tickettype[0] = tickettype;
                if (groupToUpdate.data?.rowname) groupToUpdate.data.rowname[0] = rowname;
                if (groupToUpdate.data?.columnname) groupToUpdate.data.columnname[0] = columnname;
            }

            // Identify the child object within the group (for example, by its type)
            const childObjectToUpdate = groupToUpdate.getObjects().find(obj => obj.type === 'text');

            if (childObjectToUpdate) {
                let content = title;

                if (activeObject?.data?.symbol && activeObject?.data?.tickets && activeObject?.data?.price) {
                    content += ' ' + symbol + '\n' + tickets + '\n' + 'Price: ' + price + '\n' + tickettype;
                }

                // Modify the child object's properties
                childObjectToUpdate.set({
                    text: content
                });

                // Call renderAll() to apply the changes and update the canvas
                editor?.canvas.renderAll();
            }
        }

    }, [title, symbol, tickets, price, tickettype, entrygate, rowname, columnname]);

    // useEffect(() => {
    //     if(canvasItem === 1) handleDoubleClick();

    //     return () => {
    //         handleDisableDoubleClick();
    //     };
    // },[canvasItem, editor?.canvas])

    useEffect(() => {
        console.log('canvasJson', canvasJson)
    }, [canvasJson])

    useEffect(() => {
        console.log('json', json)
    }, [json])

    useEffect(() => {
        console.log('chairs', chairs)
    }, [chairs])

    return (
        <>
            <div>

                <Sidebar />
                <div className=''>
                    <Header />

                    <div className='page__container_wrapper ticket__page'>
                        <Filters />
                        <div className='seperator'></div>

                        <div className='seatingtemplate__main_wrap'>
                            <div className='tab-header'>
                                <Row>
                                    <Col lg={2}>
                                        <Link to={'/seating-template'} className='nav-link'>Templates</Link>
                                        <a href='#' className={'nav-link active'}>Create New</a>
                                    </Col>
                                    <Col lg={10}>
                                        {/* {(canvasItem === 2) ?
                                            <> */}
                                        <div className='screentitle__wrapper'>
                                            <div className='d-flex'>
                                                <div className='d-flex me-auto'>
                                                    <button
                                                        onClick={() => ((canvasItem === 2) ? handleBackward() : handleBackward(true))}
                                                        className='fa fa-arrow-left me-2 bg-transparent border-0'>
                                                    </button>
                                                    <h2>{(screenTitle.length > 0) ? screenTitle : 'Title Here'}</h2>
                                                </div>
                                                <p>Booked Seats: <b>{bookingSeats}</b></p>
                                            </div>
                                            <p>{screenDescription}</p>
                                        </div>
                                        {/* </>
                                                :
                                            <></>
                                        } */}
                                    </Col>
                                </Row>
                            </div>
                            <div className='tab-body'>
                                <div className='create-new'>
                                    <Row>
                                        <Col lg={2}>
                                            <div className='add-area'>
                                                {
                                                    selectedObject?.title &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Title</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setTitle(event.target.value)}
                                                            value={title}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.symbol &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Symbol</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setSymbol(event.target.value)}
                                                            value={symbol}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.entrygate &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Entry gate</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setEntrygate(event.target.value)}
                                                            value={entrygate}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.tickets &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Tickets</FormLabel>
                                                        <input
                                                            type="number"
                                                            className='form-control'
                                                            onChange={(event) => setTickets(event.target.value)}
                                                            value={tickets}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.tickettype &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Ticket Type</FormLabel>
                                                        {/* <select
                                                            value={tickettype}
                                                            onChange={(e) => setTickettype(e.target.value)}
                                                            className='form-select'
                                                        >
                                                            <option value="Normal">Normal </option>
                                                            <option value="Special">Special </option>
                                                        </select> */}
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setTickettype(event.target.value)}
                                                            value={tickettype}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.price &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Price</FormLabel>
                                                        <input
                                                            type="number"
                                                            className='form-control'
                                                            onChange={(event) => setPrice(event.target.value)}
                                                            value={price}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.rowname &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Row Name</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setRowname(event.target.value)}
                                                            value={rowname}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    selectedObject?.columnname &&
                                                    <FormGroup className='mb-4'>
                                                        <FormLabel>Column Name</FormLabel>
                                                        <input
                                                            type="text"
                                                            className='form-control'
                                                            onChange={(event) => setColumnname(event.target.value)}
                                                            value={columnname}
                                                        />
                                                    </FormGroup>
                                                }

                                                {
                                                    editor?.canvas?.getActiveObject() &&
                                                    <FormGroup className='d-flex align-items-center'>
                                                        <Button className='btn btn-theme px-4'>Add Section</Button>
                                                        <a onClick={disableSelectedObject} disabled={!cropImage} href='#' title='' className='text-danger ms-4 text-decoration-none'>Disable</a>
                                                        <a onClick={removeSelectedObject} disabled={!cropImage} href='#' title='' className='text-danger ms-4 text-decoration-none'>Delete</a>
                                                    </FormGroup>
                                                }
                                            </div>
                                        </Col>

                                        <Col lg={10}>
                                            <div className='canvas__container'>
                                                <div className='canvas__navigation'>
                                                    <Row>
                                                        <Col lg={3}>
                                                            <div className='canvas__zoom'>
                                                                <button className='fa fa-search-plus' onClick={handleCanvasZoomIn}></button>
                                                                <FormSelect className='canvas__zoomselect' onChange={handleCanvasZoom} value={canvasZoom}>
                                                                    {canvasZoomList.map((event) => (
                                                                        <option key={event.value} value={event.value}>
                                                                            {event.name}
                                                                        </option>
                                                                    ))}
                                                                </FormSelect>
                                                                <button className='fa fa-search-minus' onClick={handleCanvasZoomOut}></button>
                                                            </div>
                                                        </Col>
                                                        <Col lg={5}>
                                                            <Row className='flex-row-reverse'>
                                                                <Col lg={2}>
                                                                    {/* <button onClick={removeSelectedObject} disabled={!cropImage}>Delete</button> */}
                                                                    {/* <button onClick={handleDisableDoubleClick}>Double Click Disable</button> */}
                                                                </Col>
                                                                <Col lg={3}>

                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={4} className='d-flex justify-content-end'>
                                                            <Dropdown onClick={() => handleActiveItem(false, 'chair')}>
                                                                <Dropdown.Toggle
                                                                    variant=" primary"
                                                                    id="dropdown-basic"
                                                                    className='element-dropdown-toggle'
                                                                >
                                                                    <img className='element-icon' src={window.location.origin + '/chair.svg'} alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu
                                                                    onClick={handleDropdownClick}
                                                                >
                                                                    <div className='mb-3'>
                                                                        <label className='element-title'>Color</label>
                                                                        <CirclePicker
                                                                            className='element-title'
                                                                            colors={['#000', '#ffc627', '#00b3e3', '#9b27ff', '#4949d6', '#4dc978', '#d89e42']}
                                                                            circleSpacing={5}
                                                                            onChange={handleColorChange}
                                                                        />
                                                                    </div>

                                                                    <div className='mb-3'>
                                                                        <Row className=''>
                                                                            <Col lg={12} className='mb-3'>
                                                                                <input
                                                                                    type='text'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setElementEntrygate(event.target.value)}
                                                                                    value={elementEntrygate}
                                                                                    placeholder='Entry Gate'
                                                                                />
                                                                            </Col>

                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type='text'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setConstantWord(event.target.value)}
                                                                                    value={constantWord}
                                                                                    placeholder='Symbol'
                                                                                />
                                                                            </Col>

                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type="number"
                                                                                    className='form-control'
                                                                                    onChange={(event) => setElementPrice(event.target.value)}
                                                                                    value={elementPrice}
                                                                                    placeholder='Price'
                                                                                />
                                                                            </Col>

                                                                            <Col lg={12} className='mb-3'>
                                                                                <input
                                                                                    type="number"
                                                                                    className='form-control'
                                                                                    value={elementTicketType}
                                                                                    onChange={(e) => setElementTicketType(e.target.value)}
                                                                                    placeholder='Ticket Type'
                                                                                />
                                                                                {/* <select
                                                                                    value={elementTicketType}
                                                                                    onChange={(e) => setElementTicketType(e.target.value)}
                                                                                    className='form-select'
                                                                                >
                                                                                    <option value="Normal">Normal </option>
                                                                                    <option value="Special">Special </option>
                                                                                </select>       */}
                                                                            </Col>

                                                                            <Col lg={12}>
                                                                                <label>Row</label>
                                                                            </Col>
                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setStartFrom(event.target.value)}
                                                                                    value={startFrom}
                                                                                    placeholder='Start'
                                                                                />
                                                                            </Col>
                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setEndOn(event.target.value)}
                                                                                    value={endOn}
                                                                                    placeholder='End'
                                                                                />
                                                                            </Col>

                                                                            <Col lg={12}>
                                                                                <label>Column</label>
                                                                            </Col>
                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setColumnStartFrom(event.target.value)}
                                                                                    value={columnStartFrom}
                                                                                    placeholder='Start'
                                                                                />
                                                                            </Col>
                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setColumnEndOn(event.target.value)}
                                                                                    value={columnEndOn}
                                                                                    placeholder='End'
                                                                                />
                                                                            </Col>

                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type='text'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setElementRowname(event.target.value)}
                                                                                    value={elementRowname}
                                                                                    placeholder='Row Name'
                                                                                />
                                                                            </Col>
                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type='text'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setElementColumnname(event.target.value)}
                                                                                    value={elementColumnname}
                                                                                    placeholder='Column Name'
                                                                                />
                                                                            </Col>

                                                                        </Row>
                                                                    </div>

                                                                    <div className='py-3 d-flex justify-content-center'>
                                                                        <Button onClick={createCustomObject} className='btn btn-theme'>Add Chair</Button>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <Dropdown onClick={() => handleActiveItem(false, 'table')}>
                                                                <Dropdown.Toggle
                                                                    variant=" primary"
                                                                    id="dropdown-basic"
                                                                    className='element-dropdown-toggle'>

                                                                    <img className='element-icon' src={window.location.origin + '/table.svg'} alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu
                                                                    onClick={handleDropdownClick}
                                                                >
                                                                    <div className='mb-3'>
                                                                        <label className='element-title'>Color</label>
                                                                        <CirclePicker
                                                                            className='element-title'
                                                                            colors={['#000', '#ffc627', '#00b3e3', '#9b27ff', '#4949d6', '#4dc978', '#d89e42']}
                                                                            circleSpacing={5}
                                                                            onChange={handleColorChange}
                                                                        />
                                                                    </div>

                                                                    <div className='py-3 d-flex justify-content-center'>
                                                                        <Button onClick={createCustomObject} className='btn btn-theme'>Add Section</Button>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                            <Dropdown onClick={() => handleActiveItem(false, '')}>
                                                                <label className='me-2'>Elements:</label>
                                                                <Dropdown.Toggle
                                                                    variant=" primary"
                                                                    id="dropdown-basic"
                                                                    className='p-0 border-0'>

                                                                    <img className='element-icon' src={window.location.origin + '/dropdownbutton.svg'} alt="" />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu
                                                                    onClick={handleDropdownClick}
                                                                >
                                                                    <div className='mb-3'>
                                                                        <label className='element-title'>Select Element</label>
                                                                        <Row className='element-container m-0'>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'image', 'door')} className={'dropdown-item ' + (activeItem === 'door' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/door.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'image', 'gate')} className={'dropdown-item ' + (activeItem === 'gate' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/gate.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'image', 'glasses')} className={'dropdown-item ' + (activeItem === 'glasses' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/glasses.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'image', 'stage')} className={'dropdown-item ' + (activeItem === 'stage' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/stage.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'image', 'crockery')} className={'dropdown-item ' + (activeItem === 'crockery' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/crockery.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>

                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'text')} className={'dropdown-item ' + (activeItem === 'text' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/text.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(true, 'image', 'circle')} className={'dropdown-item ' + (activeItem === 'circle' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/circle.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(true, 'image', 'oval')} className={'dropdown-item ' + (activeItem === 'oval' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/oval.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(true, 'image', 'square')} className={'dropdown-item ' + (activeItem === 'square' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/square.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(true, 'image', 'rectangle')} className={'dropdown-item ' + (activeItem === 'rectangle' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/rectangle.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>

                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(true, 'image', 'triangle')} className={'dropdown-item ' + (activeItem === 'triangle' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/triangle.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(true, 'image', 'pentagon')} className={'dropdown-item ' + (activeItem === 'pentagon' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/pentagon.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'image', 'leftarrow')} className={'dropdown-item ' + (activeItem === 'leftarrow' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/leftarrow.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            {/* <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'table')} className={'dropdown-item ' + (activeItem === 'table' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/table.svg'} alt="" />
                                                                                </Button>
                                                                            </Col>
                                                                            <Col className='p-0'>
                                                                                <Button onClick={() => handleActiveItem(false, 'chair')} className={'dropdown-item ' + (activeItem === 'chair' ? 'activeItem' : '')}>
                                                                                    <img className='element-icon' src={window.location.origin + '/chair.svg'} alt="" />
                                                                                </Button>
                                                                            </Col> */}
                                                                        </Row>
                                                                    </div>
                                                                    <div className='mb-3'>
                                                                        <label className='element-title'>Color</label>
                                                                        <CirclePicker
                                                                            className='element-title'
                                                                            colors={['#000', '#ffc627', '#00b3e3', '#9b27ff', '#4949d6', '#4dc978', '#d89e42']}
                                                                            circleSpacing={5}
                                                                            onChange={handleColorChange}
                                                                        />
                                                                    </div>

                                                                    {/* <div className='mb-3'>
                                                                        <Row className=''>
                                                                            <Col lg={6} className='mb-3'>
                                                                                <input 
                                                                                    type='text'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setConstantWord(event.target.value)}
                                                                                    value={constantWord}
                                                                                    placeholder='Symbol'
                                                                                />         
                                                                            </Col>

                                                                            <Col lg={6} className='mb-3'>
                                                                                <input
                                                                                    type="number"
                                                                                    className='form-control'
                                                                                    onChange={(event) => setElementPrice(event.target.value)}
                                                                                    value={elementPrice}
                                                                                    placeholder='Price'
                                                                                />
                                                                            </Col>

                                                                            <Col lg={12} className='mb-3'>
                                                                                <select
                                                                                    value={elementTicketType}
                                                                                    onChange={(e) => setElementTicketType(e.target.value)}
                                                                                    className='form-select'
                                                                                >
                                                                                    <option value="Normal">Normal </option>
                                                                                    <option value="Special">Special </option>
                                                                                </select>      
                                                                            </Col>

                                                                            <Col lg={6} className='mb-3'>
                                                                                <input 
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setStartFrom(event.target.value)}
                                                                                    value={startFrom}
                                                                                    placeholder='Start'
                                                                                />         
                                                                            </Col>
                                                                            <Col lg={6} className='mb-3'>
                                                                                <input 
                                                                                    type='number'
                                                                                    className='form-control'
                                                                                    onChange={(event) => setEndOn(event.target.value)}
                                                                                    value={endOn}
                                                                                    placeholder='End'
                                                                                />         
                                                                            </Col>
                                                                            
                                                                        </Row>
                                                                    </div> */}

                                                                    <div className='py-3 d-flex justify-content-center'>
                                                                        <Button onClick={createCustomObject} className='btn btn-theme'>Add Element</Button>
                                                                    </div>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>

                                                </div>
                                                <div className='canvas__wrapper'>

                                                    <div className='my-4'>
                                                        {/* <button onClick={saveCanvasJson}>Save Json</button> */}
                                                        {/* <button onClick={loadCanvasJson}>Load Json</button> */}
                                                    </div>

                                                    <FabricJSCanvas className="sample-canvas" onReady={onReady} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {
                                    (canvasItem === 1) ?
                                        <>
                                            <div>
                                                <div className='seperator my-4'></div>
                                            </div>

                                            <Row>
                                                <Col lg={2}></Col>
                                                <Col lg={10}>
                                                    <FormGroup className='d-flex align-items-center justify-content-end'>
                                                        <Button className='btn btn-theme px-4' onClick={publishNow}>Publish Now</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleSeating;