import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import Filters from './filter';
import Header from '../header/headerInner';
import Sidebar from '../leftsidebar/sidebar';
import AddNewImg from './add.svg';
import './filter.css';
import './eventcreation.css';
import EventEditScreen from './EventEditScreen';
import { useSelector } from 'react-redux';
import { deleteData, getData } from '../../../services/data';
import { setEvents } from '../../../store/slices/event';
import { getAKeyFromObjArr } from '../../../helpers/utils';
import { showSuccess } from '../../../helpers/toast';

const EditEvent = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const [search, setSearch] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [statusFilter, setStatusFilter] = useState('All');
  const [eventTypeFilter, setEventTypeFilter] = useState('All');
  const [locationFilter, setLocationFilter] = useState('All');
  const [isEditing, setIsEditing] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);

  const events = useSelector((state) => state.event.data);
  const toggleDropdown = (index) => {
    setIsDropdownOpen(!isDropdownOpen);
    setSelectedRowIndex(index);
  };

  useEffect(() => {
    getData('events/admin', setEvents);
  }, []);

  const openEditScreen = (event) => {
    setEditingEvent(event);
    setIsEditing(true);
  };

  return (
    <div>
      <Sidebar />
      <div className=''>
        <Header search={search} setSearch={setSearch} />
        <div className='page__container_wrapper ticket__page'>
          <EventEditScreen event={state?.event} />
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
