import React, { useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
// import { login } from '../../../store/slices/user';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../assets/media/logo.svg';
import { login } from '../../../services/auth';
import OtpInput from 'react-otp-input';

const OTP = (props) => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (values) => {
        const emailPhone = localStorage.getItem("emailOrPhone");
        const postDataObj = {
            "emailOrPhone": emailPhone,
            "otp": otp,
        }

        await login(postDataObj);
        
        const roles = JSON.parse(localStorage.getItem('user'));
        
        if(roles?.roles[0].name === "admin" || roles?.roles[0].name === "viewer"|| roles?.roles[0].name === "live_feeds" || roles?.roles[0].name === "organizer" || roles?.roles[0].name === "create_events"){
            navigate('/home');
        }
        if(roles?.roles[0].name === "cash_vendor"){
            navigate('/cash');
        }
        if(roles?.roles[0].name === "reserve_tickets"){
            navigate('/reserved');
        }
        if(roles?.roles[0].name === "finance"){
            navigate('/finance-track');
        }
    };

    return (
        <div className='App bodyLogin'>
            {/* <div className="container"> */}
                <div className='landingWrapper'>
                    <div className='landingRow'>
                        <div className='landingCol'>
                            <img className='logo' src={Logo} alt="" />
                            <h1>OTP Verification</h1>
                            <p className='d-none'>{props.user ? 'logged In' : 'logged out'}</p>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                inputStyle='form-class form-class-2'
                                numInputs={4}
                                renderInput={(props) => <input {...props} />}
                                />
                                <div className='fieldWrap fieldOnFooter'>
                                    <button className='submitButton' onClick={handleSubmit} type="submit">Verify</button>
                                </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </div>
    );
};

export default OTP;
