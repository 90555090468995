import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
};

export const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDiscounts: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setLoading, setDiscounts } = discountSlice.actions;

export default discountSlice.reducer;
