import React, { useState } from "react";
import UnreadFeedbackList from "./unreadfeedbacklist";
import ReadedFeedbackList from "./readfeedbacklist";
import "./feedbackeditor.css";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";

function FeedbackEditor() {
  const [selectedTab, setSelectedTab] = useState("unread");

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header />
        <div className="page__container_wrapper feedback__page">
          <div className="feedback_page_header">
            <h2>Feedbacks</h2>
          </div>
          {/* <div className="feedback__tab_wrapper">
            <div className="feedback__tab_tab_buttons">
              <button
                onClick={() => handleTabChange("unread")}
                className={selectedTab === "unread" ? "active" : ""}
              >
                Unread
              </button>
              <button
                onClick={() => handleTabChange("readed")}
                className={selectedTab === "readed" ? "active" : ""}
              >
                Read
              </button>
            </div>
            <div className="feedback__tab_datePicker">
              <input type="date" />
            </div>
          </div> */}
          {selectedTab === "unread" ? (
            <UnreadFeedbackList />
          ) : (
            <ReadedFeedbackList />
          )}
        </div>
      </div>
    </div>
  );
}

export default FeedbackEditor;
