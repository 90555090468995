import React from "react";
import "./headerInner.css";
import profileImage from "./profile.jpg"; // Import the image

const headerInner = () => {
  return (
    <div className="headerInner_main_container">
      <div className="headerInner__main_wrap">
        <div className="left">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            className="search-input"
            type="text"
            placeholder="Search Now..."
          />
        </div>
        <div className="right">
          {/* <i className="fa-regular fa-bell notification"></i> */}
          <img className="profile-picture" src={profileImage} alt="pic" />
        </div>
      </div>
    </div>
  );
};

export default headerInner;
