import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false
}

export const ticketSlice = createSlice({
    name: 'ticket',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setTickets: (state, action) => {
            state.data = action.payload;
        }
    }
})

export const { setLoading, setTickets } = ticketSlice.actions;

export default ticketSlice.reducer;