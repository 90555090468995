import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./faqeditor.css";
import Sidebar from "../leftsidebar/sidebar";
import Header from "../header/headerInner";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { deleteData, getData, postData, putData } from "../../../services/data";
import { setFaqs } from "../../../store/slices/faq";
import { showError, showSuccess } from "../../../helpers/toast";
import { is } from "immutable";
import { set } from "date-fns";

const FaqEditor = () => {
  const faqs = useSelector((state) => state.faq.data);
  const [search, setSearch] = useState("");
  const [editorState, setEditorState] = useState("");
  const [question, setQuestion] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedFaqIndex, setSelectedFaqIndex] = useState(-1);
  const [selectedIId, setselectedIId] = useState(null);

  const getFaqs = () => {
    if (search && search !== "") {
      getData(`faqs?search=${search}`, setFaqs);
      return;
    }
    getData("faqs", setFaqs);
  };

  useEffect(() => {
    getFaqs();
  }, [search]);

  const handleEditorChange = (value) => {
    setEditorState(value);
  };

  const toggleDropdown = (index) => {
    setSelectedFaqIndex(index === selectedFaqIndex ? -1 : index);
  };
  const createFaq = () => {
    postData("faqs", undefined, {
      question: question,
      answer: editorState,
    }).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setQuestion("");
        setEditorState("");
        setselectedIId(null);
        showSuccess("FAQ added successfully");
      }
      setLoading(false);
      getFaqs();
    });
  };
  const updateFaq = () => {
    putData(`faqs/${selectedIId}`, undefined, {
      question: question,
      answer: editorState,
      is_active: true,
    }).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setQuestion("");
        setEditorState("");
        setselectedIId(null);
        showSuccess("FAQ updated successfully");
      }
      setLoading(false);
      getFaqs();
    });
  };
  const handleSubmit = () => {
    if (!question || !editorState) {
      showError("Please fill all fields");
      return;
    }
    if (isLoading) return;
    setLoading(true);
    if (selectedIId) {
      updateFaq();
    } else {
      createFaq();
    }
  };
  const updatePreview = (data) => {
    setselectedIId(data.id);
    setQuestion(data.question);
    setEditorState(data.answer);
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header saerch={search} setSearch={setSearch} />
        <div className="page__container_wrapper faqPage">
          <div className="faq__page_main_wrap">
            <div className="faq__page_main_header">
              <h2>FAQs</h2>
            </div>

            <div className="faq__page_body">
              <div className="faq__page_left_col">
                <div className="qs-container">
                  <input
                    type="text"
                    placeholder="Question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </div>

                <div className="editor-container-main">
                  <div>
                    <ReactQuill
                      value={editorState}
                      onChange={handleEditorChange}
                    />
                  </div>
                </div>

                <div>
                  <button
                    className="add-to-list-button"
                    onClick={handleSubmit}
                    style={{
                      cursor: isLoading ? "default" : "pointer",
                      opacity: isLoading ? 0.5 : 1,
                    }}
                  >
                    {selectedIId ? "Update Faq" : "Add to List"}
                  </button>
                </div>
              </div>

              <div className="faq__page_right_col">
                <div className="faq__page_faq_list">
                  <h4>Published List:</h4>
                  {faqs.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => toggleDropdown(index)}
                      className="faq-item"
                    >
                      <div className="faq-rectangle">Q:</div>
                      <div className="faq-text">
                        <div className="faq-header">
                          <strong></strong>
                          {item.question}
                          <div className="faq-dropdown">
                            <button
                              className="faq-dropdown-btn"
                              onClick={() => toggleDropdown(index)}
                            >
                              &#8942;
                            </button>
                            {selectedFaqIndex === index ? (
                              <div className="faq-dropdown-content">
                                <button onClick={() => updatePreview(item)}>
                                  Edit
                                </button>
                                <button
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure you want to delete this event?"
                                      )
                                    )
                                      deleteData(`faqs/${item.id}`).then(
                                        (res) => {
                                          if (res.status === 200) {
                                            showSuccess(res.data.message);
                                            getFaqs();
                                          }
                                        }
                                      );
                                  }}
                                >
                                  Delete
                                </button>
                                {/* <button>Draft</button>
                                <button>Inactive</button>
                                <button>Close for Public</button> */}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {selectedFaqIndex === index ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                            className="faq-item-ans"
                          ></div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="save-publish-button-container">
                <button className="save-publish-button">
                  Save and Publish
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqEditor;
