import { EVENT_SECTIONS } from "../../../constants";
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Sections = ({
  event,
  data,
  selectedSection,
  setSectionHandler,
}) => {
  // CUSTOM COMPONENTS
  const nonJunkanoSec = () => (
    <div className="non-junkano-sections">
      <ul className="sections-list">
        {data?.map((item, index) => {
          const isActive = selectedSection === item.id;
          const border = item.color;
          const bacground = isActive ? border : "transparent";
          const color = isActive ? "white" : border;
          return (
            <li
              key={index}
              style={{
                backgroundColor: bacground,
                color: color,
                borderColor: border,
              }}
              className="enabled"
              onClick={() => setSectionHandler(item?.id)}
            >
              {item.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
  const eventSectionSec = (classname, array) => (
    <ul className={`nth-${classname}`}>
      {array.map((item, index) => {
        const isEnabled = data.find((i) => i.title === item?.name);
        const isActive = selectedSection === isEnabled?.id;
        const border = isEnabled?.color ?? item.background;
        const bacground = isActive ? border : "transparent";
        const color = isActive ? "white" : border;
        return (
          <li
            key={index}
            style={{
              backgroundColor: bacground,
              color: color,
              borderColor: border,
            }}
            className={isEnabled ? "enabled" : ""}
            onClick={() => {
              isEnabled && setSectionHandler(isEnabled?.id);
            }}
          >
            {item.name}
          </li>
        );
      })}
    </ul>
  );
  const bayStreetSec = () => (
    <div className="bay-street">
      <FontAwesomeIcon icon={faLongArrowAltLeft} />
      <span>Bay Street</span>
      <FontAwesomeIcon icon={faLongArrowAltRight} />
    </div>
  );
  return (
    <div className="sections-wrapper">
      <h4>{event?.name}</h4>
      <p className="mb-3">Select section to book tickets</p>
      {event?.is_junkano ? (
        <>
          <div className="section-list">
            {eventSectionSec("first", EVENT_SECTIONS.row1)}
            {eventSectionSec("second", EVENT_SECTIONS.row2)}
            {eventSectionSec("third", EVENT_SECTIONS.row3)}
          </div>
          {bayStreetSec()}
          <div className="section-list">
            {eventSectionSec("forth", EVENT_SECTIONS.row4)}
            {eventSectionSec("fifth", EVENT_SECTIONS.row5)}
            {eventSectionSec("sixth", EVENT_SECTIONS.row6)}
          </div>
        </>
      ) : (
        nonJunkanoSec()
      )}
    </div>
  );
};

export default Sections;
