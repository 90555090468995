import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: false,
};

export const finance = createSlice({
    name: "finance",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setFinanceTracks: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setLoading, setFinanceTracks } = finance.actions;

export default finance.reducer;
