import React, { useState, useEffect } from 'react';
import Filters from './filter';
import Header from '../header/headerInner';
import Sidebar from '../leftsidebar/sidebar';
import AddNewImg from './add.svg';
import './filter.css';
import './eventcreation.css';
import EventEditScreen from './EventEditScreen';
import { useSelector } from 'react-redux';
import { deleteData, getData } from '../../../services/data';
import { setEvents } from '../../../store/slices/event';
import { getAKeyFromObjArr } from '../../../helpers/utils';
import { showSuccess } from '../../../helpers/toast';

const EventHandlerPage = () => {
  const [search, setSearch] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [statusFilter, setStatusFilter] = useState('All');
  const [eventTypeFilter, setEventTypeFilter] = useState('All');
  const [locationFilter, setLocationFilter] = useState('All');
  const [isEditing, setIsEditing] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);

  const events = useSelector((state) => state.event.data);
  const toggleDropdown = (index) => {
    setIsDropdownOpen(!isDropdownOpen);
    setSelectedRowIndex(index);
  };

  useEffect(() => {
    getData('events/admin', setEvents);
  }, []);

  const openEditScreen = (event) => {
    setEditingEvent(event);
    setIsEditing(true);
  };

  const closeEditScreen = () => {
    setEditingEvent(null);
    setIsEditing(false);
    console.log("CLOSE EDIT SCREEN EVENT CREATION");
  };

  return (
    <div>
      <Sidebar />
      <div className=''>
        <Header search={search} setSearch={setSearch} />
        <div className='page__container_wrapper ticket__page'>
          {isEditing ? (
            <EventEditScreen event={editingEvent} onClose={closeEditScreen} />
          ) : (<>
            <div className="tickets-container">
              <div className="filter-options member_filter">
                <h2>Events ({events?.filter(e => (!search || !search.length > 0 || e.name.toLowerCase().includes(search.toLowerCase())) && (e.type === eventTypeFilter || eventTypeFilter === 'All') && (e.venue === locationFilter || locationFilter === 'All') && (e.is_active.toString() === statusFilter || statusFilter === 'All') && (!dateFilter || new Date(e.date_from).setHours(0, 0, 0, 0) === new Date(dateFilter).setHours(0, 0, 0, 0))).length}) <span className='addnew__newsUser_btn' onClick={() => openEditScreen(1)}><img src={AddNewImg} alt='-' />Create Event</span></h2>
                <div className='filter__option_col'>
                  <h6>Status</h6>
                  <select
                    className='form-select'
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="All">All</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                    {/* Add more status options as needed */}
                  </select></div>
                <div className='filter__option_col'>
                  <h6>Event Type</h6>
                  <select
                    value={eventTypeFilter}
                    onChange={(e) => setEventTypeFilter(e.target.value)}
                    className='form-select'
                  >
                    <option value="All">All</option>
                    {getAKeyFromObjArr(events, 'type').map((item) => <option value={item}>{item}</option>)}
                  </select>
                </div>
                <div className='filter__option_col'>
                  <h6>Location</h6>
                  <select
                    value={locationFilter}
                    onChange={(e) => setLocationFilter(e.target.value)}
                    className='form-select'
                  >
                    <option value="All">All</option>
                    {getAKeyFromObjArr(events, 'venue').map((item) => <option value={item}>{item}</option>)}
                  </select>
                </div>

                <div className='filter__option_col'>

                  <h6>Date</h6>
                  <input value={dateFilter} onChange={(e) => setDateFilter(e.target.value)} type="date" />
                </div>
              </div>
            </div>

            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Event Name</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Footfall</th>
                    <th>Tickets Sold</th>
                    <th>Total Sales</th>
                    <th>&nbsp;</th>

                  </tr>
                </thead>
                <tbody>
                  {events?.filter(e => (!search || !search.length > 0 || e.name.toLowerCase().includes(search.toLowerCase())) && (e.type === eventTypeFilter || eventTypeFilter === 'All') && (e.venue === locationFilter || locationFilter === 'All') && (e.is_active.toString() === statusFilter || statusFilter === 'All') && (!dateFilter || new Date(e.date_from).setHours(0, 0, 0, 0) === new Date(dateFilter).setHours(0, 0, 0, 0)))?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.type}</td>
                      <td>{item.date_from ? new Date(item.date_from).toLocaleString() : ''}</td>
                      <td>{item.venue}</td>
                      <td>{item.is_active ? 'Active' : 'Inactive'}</td>
                      <td>{item.Footfall ?? '-'}</td>
                      <td>{item.TicketsSold ?? '-'}</td>
                      <td>{item.TotalSales ?? '-'}</td>
                      <td>
                        <div className="userCreation__main_table_options">
                          <div className="userCreation__main_table_options" onClick={() => toggleDropdown(index)}>
                            <i className="fas fa-ellipsis-v"></i>
                          </div>
                          {isDropdownOpen && selectedRowIndex === index && (
                            <div className="userCreation__main_table_menu_dropdown">
                              <ul className="userCreation__main_table_menu_options">
                                <li onClick={() => openEditScreen(item)}>View</li>
                                <li onClick={() => openEditScreen(item)}>Edit</li>
                                <li onClick={() => {
                                  if (window.confirm("Are you sure you want to delete this event?"))
                                    deleteData(`events/${item.id}`).then((res) => {
                                      if (res.status === 200) {
                                        showSuccess(res.data.message);
                                        getData('events/admin', setEvents);
                                      }
                                    })
                                }}>Delete</li>
                              </ul>
                            </div>
                          )}
                        </div>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </>

          )}
        </div>

      </div>

    </div>

  );
};

export default EventHandlerPage;
