// OrderDetails.js
import React, { useEffect, useState } from "react";
import profileImage from "../header/profile.jpg";
import backImg from "./backto.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getData, postData } from "../../../services/data";
import { showError, showSuccess } from "../../../helpers/toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const OrderDetails = ({ order, onClose }) => {
  const [isAdmin, setAdmin] = useState(false);
  const userDetails = useSelector((state) => state.user.data);

  const truncatedOrderID = order.invoice_no.substring(0, 6);
  const tooltip = <Tooltip>{order.invoice_no}</Tooltip>;

  useEffect(() => {
    getData("users/get_user/" + userDetails?.id).then((res) => {
      res.data?.roles?.forEach((role) => {
        if (role.name === "admin") setAdmin(true);
      });
    });
  }, [userDetails]);
  const handleCancelOrder = () => {
    postData("invoices/cancel", undefined, {
      invoice_id: order.invoice_no,
    })
      .then((res) => {
        if (res.status === 401) {
          showSuccess(res.data.msg ?? res?.msg);
        } else {
          showSuccess(res.data?.msg);
          window.location.reload();
        }
      })
      .catch((err) => {
        showError(err.response?.data?.error ?? err?.message);
      });
  };

  return (
    <div className="order__detaials_main_wrap">
      <div className="order__detaials_header">
        <h2>
          <span onClick={onClose}>
            <img src={backImg} alt="" />
          </span>
          Order Details
        </h2>
      </div>
      <div className="order-details">
        <div className="user__orderDetails_wrap">
          <div className="user__detail_col_box">
            <div className="user_img_wrap">
              <img className="profile-picture" src={profileImage} alt="pic" />
            </div>

            <ul className="user__orderDetails_list">
              <li>
                Order ID:
                <OverlayTrigger placement="top" overlay={tooltip}>
                  <strong>{truncatedOrderID}...</strong>
                </OverlayTrigger>
              </li>
              <li>
                Order Date: <strong>{order.created_at.substring(0, 10)}</strong>
              </li>
              <li>
                Customer Name: <strong>{order?.customerName}</strong>
              </li>
              <li>
                Phone Number: <strong>{order.customerPhone}</strong>
              </li>
              <li>
                Gateway: <strong>{order.payment_type}</strong>
              </li>
              <li>
                Event Name: <strong>{order?.eventName}</strong>
              </li>
              <li>
                Cash Vendor: <strong>{order.cash_vendor ?? "-"}</strong>
              </li>
            </ul>
          </div>
          {/* <div className='printInvoice__btn'>Print Invoice</div> */}
        </div>
        <div className="tickets__details_wrap">
          <div className="header__main_box">
            <h2>Ticket Details</h2>{" "}
            {isAdmin && (
              <>
                {order?.is_cancelled ? (
                  <div className="cancelBtn_ticket cancelled">Order cancelled</div>
                ) : (
                  <div className="cancelBtn_ticket" onClick={handleCancelOrder}>
                    Cancel Order
                  </div>
                )}
              </>
            )}
          </div>

          <table>
            <thead>
              <th>
                <strong>Ticket No:</strong>
              </th>
              <th>
                <strong>Type:</strong>
              </th>
              <th>
                <strong>Section:</strong>
              </th>
              <th>
                <strong>Row:</strong>
              </th>
              <th>
                <strong>Seat:</strong>
              </th>
              <th>
                <strong>Price:</strong>
              </th>
            </thead>
            <tbody>
              {order.tickets.map((ticket, index) => (
                <tr key={index}>
                  <td>{ticket.number}</td>
                  <td>{ticket.payment_type}</td>
                  <td>{ticket.symbol}</td>
                  <td>{ticket.row}</td>
                  <td>{ticket.seat}</td>
                  <td>{ticket.ticket_price}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="ticket__detail_footer">
            <div className="btn_box_col">
              {/* <div className='btn_box_btn_print_ticket'>Print Tickets</div>
                            <div className='btn_box_btn_resend_ticket'>Resend Ticket</div> */}
            </div>
            <div className="total__cal_col">
              <ul>
                <li>
                  Subtotal <strong>${order.sub_total}</strong>
                </li>
                <li>
                  Discount <strong>${order.discount_amount}</strong>
                </li>
                <li>
                  VAT <strong>${order.tax}</strong>
                </li>
                <li>
                  Total Amount <strong>${order.total}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
