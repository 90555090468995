import { AxiosInstance } from "../../config/axios";
import { LOGIN, SENDRESETOTP, OTP } from "../../constants/api-routes";
import { store } from "../../store";
import { login as loginAction } from "../../store/slices/user";

export const login = async (params) => {
    await AxiosInstance.post(LOGIN, params).then((res) => {
        if(res.status === 200){
            store.dispatch(loginAction(res.data));
            const result = res.data;
            return result;
        }
    });
}

export const sendOtp = async (params) => {
    // await AxiosInstance.post(OTP, params).then((res) => {
    //     if(res.status === 200){
    //         // store.dispatch(loginAction(res.data));
    //         return true;
    //     }
    //     else{
    //         return false;
    //     }
    // });

    const response = await AxiosInstance.post(OTP, params);
    if (response.status === 200) {
        // store.dispatch(loginAction(response.data));
        return true;
    } else {
        return false;
    }
}

export const sendResetOtp = (params) => {
    AxiosInstance.post(SENDRESETOTP, params);
}

export const verifyResetOtp = (params) => {
    // AxiosInstance.post(RESETOTP, params);
}

export const changePassword = (params) => {
    // AxiosInstance.post(RESETOTP, params);
}