import { useEffect, useState } from 'react';
import './chart.css';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Horizontalchart = ({ data }) => {
  const options = {
    animationEnabled: true,
    // title:{
    //   text: "Sales"
    // },
    axisX: {
      valueFormatString: "MMM"
    },
    data: [{
      yValueFormatString: "$#,###",
      xValueFormatString: "MMMM",
      type: "spline",
      dataPoints: data?.map((d, i) => ({ x: new Date(new Date(Date.now()).getFullYear(), i), y: d }))
    }]
  }

  return (
    console.log("")
    // <CanvasJSChart options={options}
    // /* onRef={ref => this.chart = ref} */
    // />
  )
}
export default Horizontalchart;