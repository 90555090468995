import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import iconHidden from "../../../assets/media/icon-hidden.svg";
import iconVisible from "../../../assets/media/icon-visible.svg";
import { postData } from "../../../services/data";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../../constants/app-routes";
import { showSuccess } from "../../../helpers/toast";

const ForgotReset = ({ inputData }) => {
  const [passVisible, setPassVisible] = useState(false);
  const [conPassVisible, setConPassVisible] = useState(false);
  const passVisibleHandler = () => {
    setPassVisible(!passVisible);
  };

  const conPassVisibleHandler = () => {
    setConPassVisible(!conPassVisible);
  };

  const navigate = useNavigate();
  const initialValues = {
    password: "",
    password_confirmation: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Your password should be at least 8 characters long")
      .max(20, "password be 20 characters or less")
      .required("Required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("hey",values)
    postData("users/reset_password", undefined, {
      new_password: values.password,
      phone: inputData.phone,
    }).then((response) => {
      if (response.status === 200) {
        showSuccess("Password reset successfully");
        navigate(LOGIN);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {(props) => {
        return (
          <Form>
            <div className="fieldWrap">
              <label>Password</label>
              <div className="fieldWidIcon">
                <Field
                  className="form-class"
                  type={passVisible ? "text" : "password"}
                  name="password"
                />
                <span className="fieldIcon" onClick={passVisibleHandler}>
                  <img src={passVisible ? iconVisible : iconHidden} alt="" />
                </span>
              </div>
              <ErrorMessage
                className="errorMessage"
                name="password"
                component="div"
              />
            </div>

            <div className="fieldWrap">
              <label>Confirm Password</label>
              <div className="fieldWidIcon">
                <Field
                  className="form-class"
                  type={conPassVisible ? "text" : "password"}
                  name="password_confirmation"
                />
                <span className="fieldIcon" onClick={conPassVisibleHandler}>
                  <img src={conPassVisible ? iconVisible : iconHidden} alt="" />
                </span>
              </div>
              <ErrorMessage
                className="errorMessage"
                name="password_confirmation"
                component="div"
              />
            </div>
            <div className="fieldWrap fieldOnFooter">
              <button className="submitButton" type="submit">
                Submit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotReset;
