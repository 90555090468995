import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getData, postData } from "../../../services/data";
import { appendImagesToFormData, jsonToFormData } from "../../../helpers/utils";
import { setAdjustments } from "../../../store/slices/adjustment";
import { showSuccess } from "../../../helpers/toast";

const AdjustmentPopup = ({ selectedOrder, onClose }) => {
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [processingAJAX, setProcessingAJAX] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      // Append the new image to the existing array of uploaded images
      setUploadedImages([...uploadedImages, ...acceptedFiles]);
    },
  });

  const [newBalance, setNewBalance] = useState(null);

  if (!selectedOrder) {
    return null;
  }

  const removeImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setProcessingAJAX(true);
    if (
      reference === "" ||
      reference === undefined ||
      reference === null ||
      amount === "" ||
      amount === undefined ||
      amount === null ||
      note === "" ||
      note === undefined ||
      note === null
    ) {
      setProcessingAJAX(false);
      setError("Please fill All fields");
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }
    const payload = {
      reference: reference,
      amount: amount,
      note: note,
      cash_vendor: selectedOrder.id,
    };
    let formData = jsonToFormData(payload);
    formData = appendImagesToFormData(formData, "images", uploadedImages);
    postData("adjustments", undefined, formData).then((res) => {
      showSuccess(res.data?.message);
      // getData("adjustments", setAdjustments);
      getData("users/finance_cash_adjustment", setAdjustments);
      onClose();
      setProcessingAJAX(false);
    });
    onClose();
  };

  const checkAndSetValue = (e) => {
    var value = e.target.value;
    if (!isNaN(value)) {
      if (parseInt(value) > selectedOrder.balance) {
        // setNewBalance(selectedOrder.balance);
        return false;
      } else {
        var abc = selectedOrder;
        setNewBalance(selectedOrder.balance - value);
        setAmount(value);
      }
    } else {
      setNewBalance(selectedOrder.balance);
      setAmount(0);
    }
  };

  const handleInputValuePopup = (e) => {
    var value = e.target.value;
    const isAlphabet = /^[A-Za-z0-9]+$/.test(value);
    if (isAlphabet || value === "") {
      setReference(value);
    } else {
      return false;
    }
  };

  return (
    <div className="adjustment-popup">
      {/* <div className="adjustment-popup-overlay" onClick={onClose}></div> */}
      <div className="adjustment-popup-content">
        <div className="adjustment-popup-header">Adjustment Payment</div>

        <div className="adjustment-popup-body">
          <div className="adjustment-popup-field-wrap referNumber">
            <label>
              <span>Ref Number</span>
              <input
                type="text"
                value={reference}
                onChange={(e) => {
                  handleInputValuePopup(e);
                }}
              />
            </label>
          </div>

          <div className="adjustment-popup-field-wrap amount">
            <label>
              <span>
                Amount{" "}
                <em>
                  Balance:{" "}
                  {newBalance !== null ? newBalance : selectedOrder.balance}
                </em>
              </span>
              <input
                type="number"
                value={amount}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
                min={0}
                max={selectedOrder.balance}
                onChange={(e) => {
                  checkAndSetValue(e);
                }}
              />
            </label>
          </div>

          <div className="adjustment-popup-field-wrap description__field">
            <label>
              <span>
                Note <em>Max 200 limit</em>
              </span>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}></textarea>
            </label>
          </div>

          <div className="adjustment-popup-field-upload">
            <div className="dropzone" {...getRootProps()}>
              {uploadedImages.length > 0 && (
                <div className="uploaded-images">
                  {uploadedImages.map((image, index) => (
                    <div key={index} className="uploaded-image">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Uploaded ${index + 1}`}
                      />
                      <button onClick={() => removeImage(index)}>+</button>
                    </div>
                  ))}
                </div>
              )}
              <div className="event__creation__eventphoto_upload_file">
                <input {...getInputProps()} />
              </div>
            </div>
          </div>
          {error && (
            <div style={{ color: "red", fontSize: "12px" }}>{error}</div>
          )}

          <div className="adjustment-popup-footer">
            <button onClick={onClose} className="adjustment-popup-cancel">
              Cancel
            </button>
            <button
              onClick={onSubmit}
              className={`adjustment-popup-submit ${
                processingAJAX ? "diabledButton" : ""
              }`}>
              {processingAJAX ? "Submitting" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdjustmentPopup;
