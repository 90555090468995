import React, { useState } from "react";
import "./headerInner.css";
import profileImage from "./profile.jpg";
import cartIcon from "./bag-2.svg";
import "font-awesome/css/font-awesome.css";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTotalAfterAction,
  emptyCart,
  removeFromCart,
  removeFromCartNoSeating,
  setCustomer,
  setInvoice,
  setPaymentPage,
} from "../../../store/slices/cart";
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../../../services/data";
import { showError, showSuccess } from "../../../helpers/toast";
import { setInvoices } from "../../../store/slices/invoice";

const HeaderInner = ({ PhoneNumberAvailable, CashVendor }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const cartItems = useSelector((state) => state.cart.items);
  const subTotal = useSelector((state) => state.cart.subTotal);
  const vat = useSelector((state) => state.cart.vat);
  const discount = useSelector((state) => state.cart.discount_amount);
  const appliedDiscount = useSelector((state) => state.cart.discount);
  const currency = useSelector((state) => state.cart.currency);
  const eventId = useSelector((state) => state.cart.eventId);
  const eventVat = useSelector((state) => state.cart.eventVat);
  const user = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.cart.customer);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSubmitCart = () => {
    navigate("/order-summary");
  };

  const seatingReserve = () => {
    postData("invoices", undefined, {
      event_id: eventId,
      is_paid: false,
      is_reserved: true,
      is_paid_status_code: -1,
      tax_percentage: eventVat,
      sub_total: subTotal,
      tax: vat,
      total: subTotal + vat - discount,
      cash_vendor: user.id,
      payment_type: "cash",
      chairs: cartItems.map((item) => item.ticket),
      payment_gateway_response: "Payment successful",
      discount_id: appliedDiscount.id ?? null,
      discount_amount: discount,
      is_discount: !!appliedDiscount.id,
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess(res.data.message ?? "Tickets Reserved");
          dispatch(
            calculateTotalAfterAction({
              action: emptyCart,
            })
          );
          getData("invoices", setInvoices);
          navigate("/home");
        }
      })
      .catch((err) => {
        showError(err.response?.data?.error ?? err?.message);
        dispatch(setCustomer(""));
      });
  };

  const nonSeatingReserve = () => {
    const tickets = [];
    cartItems.forEach((item) => {
      const isExsist = tickets.findIndex((t) => t.id === item.symbolId);
      if (isExsist >= 0) {
        tickets[isExsist].ticket_count++;
        return;
      }
      tickets.push({
        id: item.symbolId,
        ticket_count: 1,
      });
    });
    postData("invoices/no-seating", undefined, {
      event_id: eventId,
      is_paid: false,
      is_reserved: true,
      is_paid_status_code: -1,
      tax_percentage: eventVat,
      sub_total: subTotal,
      tax: vat,
      total: subTotal + vat - discount,
      cash_vendor: user.id,
      payment_type: "cash",
      tickets: tickets,
      payment_gateway_response: "Payment successful",
      discount_id: appliedDiscount.id ?? null,
      discount_amount: discount,
      is_discount: !!appliedDiscount.id,
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess(res.data.message ?? "Tickets Reserved");
          dispatch(
            calculateTotalAfterAction({
              action: emptyCart,
            })
          );
          getData("invoices", setInvoices);
          navigate("/home");
        }
      })
      .catch((err) => {
        showError(err.response?.data?.error ?? err?.message);
        dispatch(setCustomer(""));
      });
  };

  const handleSubmitReserve = () => {
    if (cartItems[0]?.eventStyle === "no seating") {
      nonSeatingReserve();
      return;
    }
    seatingReserve();
  };

  const handleEmptyCart = () => {
    dispatch(
      calculateTotalAfterAction({
        action: emptyCart,
      })
    );
  };

  const handleCarBoxOverlay = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const removeFromCartHandler = (item) => {
    if (item.eventStyle === "no seating") {
      dispatch(
        calculateTotalAfterAction({
          action: removeFromCartNoSeating,
          data: item.symbolId,
        })
      );
      return;
    }
    dispatch(
      calculateTotalAfterAction({
        action: removeFromCart,
        data: item.ticket,
      })
    );
  };

  return (
    <div className="headerInner_main_container">
      <div className="headerInner__main_wrap">
        <div className="left">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            className="search-input"
            type="text"
            placeholder="Search Now..."
          />
        </div>
        <div className="right">
          <img
            className="cartIcon_btn"
            src={cartIcon}
            onClick={toggleDrawer}
            alt=""
          />
          {/* <i className="fa-regular fa-bell notification"></i> */}
          <img className="profile-picture" src={profileImage} alt="pic" />
        </div>
      </div>
      {isDrawerOpen && (
        <>
          <div
            className={`drawer__cart_box_overlay ${isDrawerOpen && "d-block"}`}
            onClick={handleCarBoxOverlay}
          ></div>
          <div className="drawer__cart_box">
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>Section</strong>
                    </th>
                    <th>
                      <strong>Row</strong>
                    </th>
                    <th>
                      <strong>Seat:</strong>
                    </th>
                    <th>
                      <strong>Price:</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr key={item.ticket}>
                      <td>
                        {/* {item?.eventStyle === "no seating" ? "-" : item.ticket} */}
                        {item.type}
                      </td>
                      <td>{item.symbol}</td>
                      <td>
                        {item?.eventStyle === "no seating" ? "-" : item.row}
                      </td>
                      <td>
                        {item?.eventStyle === "no seating" ? "-" : item.seat}
                      </td>
                      <td>{item.price}</td>
                      <td>
                        <i
                          onClick={() => removeFromCartHandler(item)}
                          className="fa fa-times-circle-o cursor-pointer"
                          aria-hidden="true"
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="cart__drawer_totals_wrap">
              <div className="cart__drawer_subTotal">
                <strong>Subtotal:</strong> {currency}
                {parseFloat(subTotal).toFixed(2)}
              </div>
              <div className="cart__drawer_discount">
                <strong>Discount:</strong> {currency}
                {parseFloat(discount).toFixed(2)}
              </div>
              <div className="cart__drawer_vat">
                <strong>VAT:</strong> {currency}
                {parseFloat(vat).toFixed(2)}
              </div>
              <div className="cart__drawer_totals">
                <strong>Total:</strong> {currency}
                {parseFloat(subTotal + vat - discount).toFixed(2)}
              </div>
            </div>
            <div className="cart__drawer_btn_box">
              <button
                onClick={() => {
                  closeDrawer();
                  handleEmptyCart();
                }}
                className={`cart__drawer_clear`}
              >
                Clear Cart
              </button>
              {CashVendor === "cash_vendor" ? (
                ""
              ) : (
                <button
                  onClick={() => {
                    closeDrawer();
                    handleSubmitReserve();
                  }}
                  className={`cart__drawer_reserve ${
                    cartItems.length < 1 ? "disabledButton" : ""
                  }`}
                >
                  Reserve
                </button>
              )}
              <button
                onClick={() => {
                  handleSubmitCart();
                  closeDrawer();
                }}
                className={`cart__drawer_buyTicket ${
                  cartItems.length < 1 || PhoneNumberAvailable === false
                    ? "disabledButton"
                    : ""
                }`}
              >
                Buy Ticket
              </button>
            </div>
            {!PhoneNumberAvailable && (
              <p className="buyerDetailsEmpty">Please Fill Buyer Details</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderInner;
