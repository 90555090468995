import React from "react";
import "./feedbackeditor.css";
import "./mail";
import { Link, useNavigate } from "react-router-dom";
import Trash from "./images/trash.png";
import profileImage from "./profile.jpg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getData } from "../../../services/data";
import { setFeedbacks } from "../../../store/slices/feedback";

function ReadedFeedbackList() {
  const feedbacks = useSelector((state) => state.feedback.data);
  useEffect(() => {
    getData("feedbacks", setFeedbacks);
  }, []);
  return (
    <div className="feedback_row_wrap">
      {feedbacks.map((feedback, index) => (
        <div key={index} className="feedback_row_main">
          {/* <div className="feedback_row_checkbox_col">
            <input type="checkbox" />
          </div> */}
          <Link to={`/mail/${index}`} className="email_text">
            <div className="feedback_row_image_info">
              <img
                className="feedback_row_image_info_profile_picture"
                src={feedback.image}
                alt="pic"
              />
              <div className="feedback_row_image_info_name_email">
                <div className="feedback_row_image_info_name">
                  {feedback.first_name}
                </div>
                <div className="feedback_row_image_info_email">
                  {feedback.email}
                </div>
              </div>
            </div>
            <div className="feedback_row_content_col">{feedback.subject}</div>
            <div className="date_trash_container_date">{feedback.date}</div>

            <div className="feedback_row_content_dlt">
              <img src={Trash} alt="-" />
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default ReadedFeedbackList;
