import React, { useState, useEffect } from "react";
import Filters from "./filter";
import { Link, useNavigate } from "react-router-dom";
import Header from "../header/headerInner";
import Sidebar from "../leftsidebar/sidebar";
import AddNewImg from "./add.svg";
import "./discount.css";
import { useSelector } from "react-redux";
import { getData, postData, deleteData, putData } from "../../../services/data";
import { setDiscounts } from "../../../store/slices/discount";
import { showError } from "../../../helpers/toast";
import { setEvents } from "../../../store/slices/event";
import { showSuccess } from "../../../helpers/toast";
import { format } from "date-fns";

const Discount = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const events = useSelector((state) => state.event.data);
  const [filterDate, setFilterDate] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [newDiscount, setNewDiscount] = useState({
    discountName: "",
    discountCode: "",
    discountType: "fixed", // New dropdown field
    discountValue: "",
    usageLimit: "", // New dropdown field
    event_id: "",
    validFrom: new Date(Date.now()),
    validTo: new Date(Date.now()),
    minimumValue: "", // New dropdown field
  });

  const [showAddNewRow, setShowAddNewRow] = useState(false);
  const [showEditRow, setShowEditRow] = useState(false);
  const [processingAJAX, setProcessingAJAX] = useState(false);

  const discounts = useSelector((state) => state.discount.data);

  const getDiscountCodeHelper = () => {
    if (search && search !== "") {
      getData(`discount_code?search=${search}`, setDiscounts);
      return;
    }
    getData("discount_code", setDiscounts);
  };

  useEffect(() => {
    getData("events/admin", setEvents);
  }, []);

  useEffect(() => {
    getDiscountCodeHelper();
  }, [search]);

  const handleAddNewRow = () => {
    setShowAddNewRow(true);
  };

  const handleEditRow = (item) => {
    setShowEditRow(true);
    setRowId(item.id);
    setNewDiscount({
      discountName: item.name,
      discountCode: item.code,
      discountType: item.type, // New dropdown field
      discountValue: item.value,
      usageLimit: item.maxUses, // New dropdown field
      event_id: item.event_id,
      validFrom: format(new Date(item.validFrom), "yyyy-MM-dd"),
      validTo: format(new Date(item.validTo), "yyyy-MM-dd"),
      minimumValue: item.minValue, // New dropdown field
    });
  };

  const toggleDropdown = (index) => {
    setIsDropdownOpen(!isDropdownOpen);
    setSelectedRowIndex(index);
  };

  const handleDateFilterChange = (event) => {
    setFilterDate(event.target.value);
  };

  const handleClosePopup = () => {
    setShowAddNewRow(false);
    setNewDiscount({
      discountName: "",
      discountCode: "",
      discountType: "", // Reset dropdown field
      discountValue: "",
      usageLimit: "", // Reset dropdown field
      minimumValue: 0,
      validFrom: new Date(Date.now()),
      validTo: new Date(Date.now()),
      event_id: "",
    });
  };

  const handleEditClosePopup = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setShowEditRow(false);
    setNewDiscount({
      discountName: "",
      discountCode: "",
      discountType: "", // Reset dropdown field
      discountValue: "",
      usageLimit: "", // Reset dropdown field
      minimumValue: 0,
      validFrom: new Date(Date.now()),
      validTo: new Date(Date.now()),
      event_id: "",
    });
  };

  const handleSaveNewRow = (e) => {
    e.preventDefault();
    setProcessingAJAX(true);
    if (
      newDiscount.discountName &&
      newDiscount.discountCode &&
      newDiscount.discountType &&
      newDiscount.discountValue &&
      newDiscount.usageLimit &&
      newDiscount.minimumValue &&
      newDiscount.validFrom &&
      newDiscount.validTo &&
      newDiscount.event_id
    ) {
      postData("discount_code", undefined, {
        code: newDiscount.discountCode,
        name: newDiscount.discountName,
        type: newDiscount.discountType,
        value: newDiscount.discountValue,
        validFrom: newDiscount.validFrom,
        validTo: newDiscount.validTo,
        maxUses: newDiscount.usageLimit,
        minValue: newDiscount.minimumValue,
        isOneTimeUse: newDiscount.usageLimit === 1 ? true : false,
        event_id: newDiscount.event_id,
      }).then(() => {
        getDiscountCodeHelper();
        setNewDiscount({
          discountName: "",
          discountCode: "",
          discountType: "fixed", // Reset dropdown field
          discountValue: "",
          usageLimit: "", // Reset dropdown field
          minimumValue: 0,
          validFrom: new Date(Date.now()),
          validTo: new Date(Date.now()),
          event_id: "",
        });
        setShowAddNewRow(false);
        showSuccess("Added Successfully");
        setProcessingAJAX(false);
      });
    } else {
      showError("Please fill in all fields");
      setProcessingAJAX(false);
    }
  };

  const handleSaveEditRow = (e) => {
    e.preventDefault();
    setProcessingAJAX(true);
    if (
      newDiscount.discountName &&
      newDiscount.discountCode &&
      newDiscount.discountType &&
      newDiscount.discountValue &&
      newDiscount.usageLimit &&
      newDiscount.minimumValue &&
      newDiscount.validFrom &&
      newDiscount.validTo &&
      newDiscount.event_id
    ) {
      putData(`discount_code/${rowId}`, undefined, {
        code: newDiscount.discountCode,
        name: newDiscount.discountName,
        type: newDiscount.discountType,
        value: newDiscount.discountValue,
        validFrom: newDiscount.validFrom,
        validTo: newDiscount.validTo,
        maxUses: newDiscount.usageLimit,
        minValue: newDiscount.minimumValue,
        isOneTimeUse: newDiscount.usageLimit === 1 ? true : false,
        isActive: true,
        event_id: newDiscount.event_id,
      }).then(() => {
        getDiscountCodeHelper();
        setNewDiscount({
          discountName: "",
          discountCode: "",
          discountType: "fixed", // Reset dropdown field
          discountValue: "",
          usageLimit: "", // Reset dropdown field
          minimumValue: 0,
          validFrom: new Date(Date.now()),
          validTo: new Date(Date.now()),
          event_id: "",
        });
        setShowEditRow(false);
        showSuccess("Updated Successfully");
        setProcessingAJAX(false);
      });
    } else {
      showError("Please fill in all fields");
      setProcessingAJAX(false);
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="">
        <Header saerch={search} setSearch={setSearch} />
        <div className="page__container_wrapper discount__page">
          <div className="Discount_code_conainer">
            <div className="heading__and_addbtn">
              <h2>
                Discount Codes
                <span
                  className="addnew__newsUpdate_btn"
                  onClick={handleAddNewRow}
                >
                  <img src={AddNewImg} alt="-" /> Add New
                </span>
              </h2>
            </div>
            <div className="newsfilter__filter_fields">
              <label htmlFor="datePicker">Date :</label>
              <input
                type="date"
                id="datePicker"
                value={filterDate || ""}
                onChange={handleDateFilterChange}
              />
            </div>
          </div>

          <div className="table-container">
            <table className="data-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Discount Name</th>
                  <th>Discount Code</th>
                  <th>Discount Type</th>
                  <th>Discount Value</th>
                  <th>Usage Limit</th>
                  <th>Minimum Value</th>
                  <th>Valid From</th>
                  <th>Valid To</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {discounts?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.code}</td>
                    <td>{item.type}</td>
                    <td>{item.value}</td>
                    <td>{item.maxUses}</td>
                    <td>{item.minValue}</td>
                    <td>
                      {new Date(`${item.validFrom}`).toLocaleDateString(
                        "en-US"
                      )}
                    </td>
                    <td>
                      {new Date(`${item.validTo}`).toLocaleDateString("en-US")}
                    </td>
                    <td>
                      <div className="userCreation__main_table_options">
                        <div
                          className="userCreation__main_table_options"
                          onClick={() => toggleDropdown(index)}
                        >
                          <i className="fas fa-ellipsis-v"></i>
                        </div>
                        {isDropdownOpen && selectedRowIndex === index && (
                          <div className="userCreation__main_table_menu_dropdown">
                            <ul className="userCreation__main_table_menu_options">
                              <li onClick={() => handleEditRow(item)}>Edit</li>
                              <li
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this discount?"
                                    )
                                  )
                                    deleteData(`discount_code/${item.id}`).then(
                                      (res) => {
                                        if (res.status === 200) {
                                          showSuccess(res.data.msg);
                                          getDiscountCodeHelper();
                                        }
                                      }
                                    );
                                }}
                              >
                                Delete
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {showAddNewRow && (
            <div className="discount_create_pop_main_wrap">
              <div className="discount_create_pop_content">
                <span
                  className="close__discount_create_pop"
                  onClick={() => setShowAddNewRow(false)}
                >
                  &times;
                </span>
                <div className="close__discount_create_pop_header">
                  <h2>Add Discount Code</h2>
                  <div className="discount_create_pop_group_select_event">
                    <label htmlFor="selectEvent">Select Event</label>
                    <select
                      className="form-select"
                      id="selectEvent"
                      value={newDiscount.event_id}
                      onChange={(ev) =>
                        setNewDiscount({
                          ...newDiscount,
                          event_id: ev.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        Please Select
                      </option>
                      {events.map((e) => (
                        <option value={e.id}>{e.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <form className="discount__fields_form_wrap">
                  <div className="discount__field_wrap_body">
                    <div className="discount_create_pop_group discount_name">
                      <label htmlFor="discountName">Discount Name</label>
                      <input
                        type="text"
                        id="discountName"
                        placeholder="Junkanoo Discount"
                        value={newDiscount.discountName}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group discount_code">
                      <label htmlFor="discountCode">Discount Code</label>
                      <input
                        type="text"
                        id="discountCode"
                        placeholder="Junkanoo10"
                        value={newDiscount.discountCode}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountCode: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="discountType">Discount Type</label>
                      <select
                        className="form-select"
                        id="discountType"
                        value={newDiscount.discountType}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountType: e.target.value,
                          })
                        }
                      >
                        <option value="fixed">Fixed </option>
                        <option value="percent">Percentage</option>
                      </select>
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="discountValue">Discount Value</label>
                      <input
                        type="text"
                        id="discountValue"
                        placeholder="100"
                        value={newDiscount.discountValue}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountValue: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="usageLimit">Usage</label>
                      <input
                        type="text"
                        id="usageLimit"
                        placeholder="100"
                        value={newDiscount.usageLimit}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            usageLimit: e.target.value,
                          })
                        }
                      />
                      <div className="helping_content">
                        How many times this discount code can be used before it
                        is void e.g. 100
                      </div>
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="minimumValue">
                        Minimum Value for Cart
                      </label>

                      <input
                        type="text"
                        id="usageminimumValueLimit"
                        placeholder="100"
                        value={newDiscount.minimumValue}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            minimumValue: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="validFrom">Valid From</label>
                      <input
                        type="date"
                        id="validFrom"
                        value={newDiscount.validFrom}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            validFrom: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="validTo">Valid To</label>
                      <input
                        type="date"
                        id="validTo"
                        value={newDiscount.validTo}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            validTo: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount__field_wrap_footer">
                      <button
                        onClick={handleClosePopup}
                        className="discount__field_wrap_footer_cancel"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveNewRow}
                        className={`discount__field_wrap_footer_save ${
                          processingAJAX ? "disabledButton" : ""
                        }`}
                      >
                        {processingAJAX ? "Saving" : "Save"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {showEditRow && (
            <div className="discount_create_pop_main_wrap">
              <div className="discount_create_pop_content">
                <span
                  className="close__discount_create_pop"
                  onClick={() => setShowEditRow(false)}
                >
                  &times;
                </span>
                <div className="close__discount_create_pop_header">
                  <h2>Edit Discount Code</h2>
                  <div className="discount_create_pop_group_select_event">
                    <label htmlFor="selectEvent">Select Event</label>
                    <select
                      className="form-select"
                      id="selectEvent"
                      value={newDiscount.event_id}
                      onChange={(ev) =>
                        setNewDiscount({
                          ...newDiscount,
                          event_id: ev.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        Please Select
                      </option>
                      {events.map((e) => (
                        <option value={e.id}>{e.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <form className="discount__fields_form_wrap">
                  <div className="discount__field_wrap_body">
                    <div className="discount_create_pop_group discount_name">
                      <label htmlFor="discountName">Discount Name</label>
                      <input
                        type="text"
                        id="discountName"
                        placeholder="Junkanoo Discount"
                        value={newDiscount.discountName}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountName: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group discount_code">
                      <label htmlFor="discountCode">Discount Code</label>
                      <input
                        type="text"
                        id="discountCode"
                        placeholder="Junkanoo10"
                        value={newDiscount.discountCode}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountCode: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="discountType">Discount Type</label>
                      <select
                        className="form-select"
                        id="discountType"
                        value={newDiscount.discountType}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountType: e.target.value,
                          })
                        }
                      >
                        <option value="fixed">Fixed </option>
                        <option value="percent">Percentage</option>
                      </select>
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="discountValue">Discount Value</label>
                      <input
                        type="text"
                        id="discountValue"
                        placeholder="100"
                        value={newDiscount.discountValue}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            discountValue: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="usageLimit">Usage</label>
                      <input
                        type="text"
                        id="usageLimit"
                        placeholder="100"
                        value={newDiscount.usageLimit}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            usageLimit: e.target.value,
                          })
                        }
                      />
                      <div className="helping_content">
                        How many times this discount code can be used before it
                        is void e.g. 100
                      </div>
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="minimumValue">
                        Minimum Value for Cart
                      </label>

                      <input
                        type="text"
                        id="usageminimumValueLimit"
                        placeholder="100"
                        value={newDiscount.minimumValue}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            minimumValue: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="validFrom">Valid From</label>
                      <input
                        type="date"
                        id="validFrom"
                        value={newDiscount.validFrom}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            validFrom: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount_create_pop_group">
                      <label htmlFor="validTo">Valid To</label>
                      <input
                        type="date"
                        id="validTo"
                        value={newDiscount.validTo}
                        onChange={(e) =>
                          setNewDiscount({
                            ...newDiscount,
                            validTo: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="discount__field_wrap_footer">
                      <button
                        onClick={handleEditClosePopup}
                        className="discount__field_wrap_footer_cancel"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveEditRow}
                        className={`discount__field_wrap_footer_save ${
                          processingAJAX ? "disabledButton" : ""
                        }`}
                      >
                        {processingAJAX ? "Updating" : "Update"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discount;
