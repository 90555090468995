import React from 'react'
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/slices/user';
import { Button } from 'react-bootstrap';

const About = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        // Handle logout logic here
        dispatch(logout());
    };


    return (
        <div>About
            <Button onClick={handleLogout}>Logout</Button>
        </div>
    )
}

export default About