export const EVENT_TICKETS_AVAILABILITY = [
  { title: "Available", isThemeColor: true },
  { title: "Booked", color: "#58677d" },
  { title: "Not for booking", color: "#dfe2e6" },
];
export const EVENT_SECTIONS = {
  row1: [
    {
      name: "A",
      value: "Section A",
      background: "#ef6b3b",
      color: "#fff",
    },
    {
      name: "B",
      value: "Section B",
      background: "#ef6b3b",
      color: "#fff",
    },
  ],
  row2: [
    {
      name: "E",
      value: "Section E",
      background: "#1aaad8",
      color: "#fff",
    },
    {
      name: "F",
      value: "Section F",
      background: "#1aaad8",
      color: "#fff",
    },
  ],
  row3: [
    {
      name: "I",
      value: "Section I",
      background: "#29b3aa",
      color: "#fff",
    },
    {
      name: "J",
      value: "Section J",
      background: "#29b3aa",
      color: "#fff",
    },
    {
      name: "K",
      value: "Section K",
      background: "#29b3aa",
      color: "#fff",
    },
    {
      name: "M",
      value: "Section M",
      background: "#29b3aa",
      color: "#fff",
    },
  ],
  row4: [
    {
      name: "C",
      value: "Section C",
      background: "#ef6b3b",
      color: "#fff",
    },
    {
      name: "D",
      value: "Section D",
      background: "#ef6b3b",
      color: "#fff",
    },
  ],
  row5: [
    {
      name: "G",
      value: "Section G",
      background: "#1aaad8",
      color: "#fff",
    },
    {
      name: "H",
      value: "Section H",
      background: "#1aaad8",
      color: "#fff",
    },
  ],
  row6: [
    {
      name: "L",
      value: "Section L",
      background: "#eb9bb0",
      color: "#fff",
    },
  ],
};

export const EVENT_SECTION_SORT_ORDER = [
  {
    id: "A",
    reversed: false,
  },
  {
    id: "B",
    reversed: false,
  },
  {
    id: "C",
    reversed: true,
  },
  {
    id: "D",
    reversed: true,
  },
  {
    id: "E",
    reversed: false,
  },
  {
    id: "F",
    reversed: false,
  },
  {
    id: "G",
    reversed: true,
  },
  {
    id: "H",
    reversed: true,
  },
  {
    id: "L",
    reversed: true,
  },
  {
    id: "I",
    reversed: false,
  },
  {
    id: "J",
    reversed: false,
  },
  {
    id: "K",
    reversed: false,
  },
  {
    id: "M",
    reversed: false,
  },
];
